import React from "react";
import Layout from "../../components/Layout/Layout";
import { Avatar, Button } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import HeaderText from "../../components/HeaderText";
import { useNavigate } from "react-router-dom";
import StudentDashboard from "../../Layout/StudentDashboard";
import CardWrapper from "../../components/Card/CardWrapper";
import { colors } from "../../constants/colors";
import SwitchItems from "../../components/SwitchItems";
import { useState } from "react";
import BreadCumb from "../../components/BradCumb";
import WorkIcon from "@mui/icons-material/Work";
import { People } from "@mui/icons-material";
import GraphCard from "../../components/Card/GraphCard";
import ModalWrapper from "../../components/ModalWrapper";
const StudentView = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState();
  const [active, setActive] = useState("Short Listed");
  const [branch, setBranch] = useState("Civil");
  const [classDivision, setClassDivision] = useState("Computer");
  const menus = ["Short Listed", "Hired", "Rejected"];
  let Students = [
    {
      image:
        "https://www.corporatephotographylondon.com/wp-content/uploads/2019/11/HKstrategies-1210-1024x683.jpg",
      name: "John Smith",
      age: 18,
      className: "4th Year Computer",
      grade: 85,
    },
    {
      image:
        "https://www.corporatephotographylondon.com/wp-content/uploads/2019/11/HKstrategies-755-1-1024x683.jpg",
      name: "Alice Johnson",
      age: 17,
      grade: 92,
      className: "4th Year Mechnical",
    },
    {
      image:
        "https://media.istockphoto.com/id/1329936184/photo/head-shot-portrait-of-smiling-businesswoman-intern-looking-at-camera.jpg?s=612x612&w=0&k=20&c=cCeWt0GYUtcFsGFi6hTFci-d5KZY6FAd_UchY9V5aSo=",
      name: "Bob Brown",
      age: 19,
      className: "4th Year Computer",
      grade: 78,
    },
    {
      image:
        "https://media.istockphoto.com/id/1303206558/photo/headshot-portrait-of-smiling-businessman-talk-on-video-call.jpg?s=612x612&w=0&k=20&c=hMJhVHKeTIznZgOKhtlPQEdZqb0lJ5Nekz1A9f8sPV8=",
      name: "Emily Davis",
      className: "4th Year Computer",
      age: 20,
      grade: 88,
    },
    {
      image:
        "https://media.istockphoto.com/id/1309328823/photo/headshot-portrait-of-smiling-male-employee-in-office.jpg?s=612x612&w=0&k=20&c=kPvoBm6qCYzQXMAn9JUtqLREXe9-PlZyMl9i-ibaVuY=",
      name: "David Wilson",
      age: 18,
      grade: 76,
    },
    {
      image:
        "https://www.corporatephotographylondon.com/wp-content/uploads/2019/11/HKstrategies-1029-1024x683.jpg",
      name: "Sarah Lee",
      className: "4th Year ENTC",
      age: 19,
      grade: 95,
    },
    {
      image:
        "https://www.corporatephotographylondon.com/wp-content/uploads/2019/11/Option-3-1024x683.jpg",
      name: "Michael Jones",
      age: 17,
      className: "4th Year Computer",
      grade: 89,
    },
    {
      image:
        "https://img.freepik.com/free-photo/portrait-optimistic-businessman-formalwear_1262-3600.jpg?size=626&ext=jpg&ga=GA1.1.1413502914.1696982400&semt=ais",
      name: "Olivia Martinez",
      age: 20,
      className: "4th Year Computer",
      grade: 91,
    },
  ];
  const navigationdata = [
    {
      icon: (
        <People style={{ marginRight: 5, color: "0049FC", fontSize: 18 }} />
      ),
      name: "Students",
      route: "/",
    },
    {
      name: "All Students",
      route: "/my_jobs",
    },
  ];
  // Representing different engineering disciplines as an array
  let engineeringDivisions = [
    "Civil ",
    "Mechanical ",
    "Electrical ",
    "Chemical ",
    "Computer ",
    "Aerospace ",
    "Biomedical ",
    "Environmental ",
    "Industrial ",
    "Materials ",

    "Petroleum ",
  ];

  let years = ["1st year ", "2nd year ", "3rd  year ", "Final Year "];

  return (
    <Layout>
      <ModalWrapper open={open} handleClose={() => setOpen(false)}>
        <HeaderText style={{ fontFamily: "Outfit", fontSize: "16px" }}>
          Add filters to get match
        </HeaderText>
        <div style={{ marginTop: "10px" }}>
          <HeaderText
            style={{
              fontFamily: "Outfit",
              fontSize: "14px",

              fontWeight: "500",
            }}
          >
             Year{" "}
            <EditIcon style={{ color: colors.primary, fontSize: "14px" }} />
          </HeaderText>
          <div style={{ display: "flex", flexWrap: "wrap", marginTop: "5px" }}>
            {[
              "First Year",
              "Second Year",
              "Third Year",
            ].map((item) => (
              <div
                style={{
                  padding: "4px",
                  background: "#dadde3",
                  borderRadius: "100px",
                  margin: "5px",
                  paddingLeft: 10,
                  paddingRight: "10px",
                }}
              >
                {" "}
                <HeaderText
                  style={{
                    fontFamily: "Outfit",
                    fontSize: "14px",
                  }}
                >
                  {item}
                </HeaderText>
              </div>
            ))}
          </div>
        </div>
        <div style={{ marginTop: "10px" }}>
          <HeaderText
            style={{
              fontFamily: "Outfit",
              fontSize: "14px",

              fontWeight: "500",
            }}
          >
            Branch{" "}
            <EditIcon style={{ color: colors.primary, fontSize: "14px" }} />
          </HeaderText>
          <div style={{ display: "flex", flexWrap: "wrap", marginTop: "5px" }}>
            {["Computer", "Electrical", "Mechnical"].map(
              (item) => (
                <div
                  style={{
                    padding: "4px",
                    background: "#dadde3",
                    borderRadius: "100px",
                    margin: "5px",
                    paddingLeft: 10,
                    paddingRight: "10px",
                  }}
                >
                  {" "}
                  <HeaderText
                    style={{
                      fontFamily: "Outfit",
                      fontSize: "14px",
                    }}
                  >
                    {item}
                  </HeaderText>
                </div>
              )
            )}
          </div>
        </div>
      
      </ModalWrapper>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        
        <BreadCumb data={navigationdata} />
        <Button
          variant="contained"
          onClick={() => navigate("/addStudent")}
          sx={{
            margin: "10px",
            marginLeft: "auto",

            background: colors.primary,
            fontWeight: "bold",
            marginBottom: 0,
            borderRadius: "100px",
            fontFamily: "Quicksand",
            textTransform: "none",
          }}
        >
          Add Students
        </Button>
      </div>
      <div style={{ margin: 10, display: "flex", alignItems: "center" }}>
        <SwitchItems items={menus} active={active} setActive={setActive} />
      </div>
      <GraphCard title={active} type={"bar"} />
      <div style={{ margin: 10, display: "flex", alignItems: "center" }}>
        <HeaderText style={{ fontFamily: "Outfit", marginBottom: 10 }}>
          Students
        </HeaderText>
        <HeaderText onClick={()=>setOpen(true)} style={{ fontFamily: "Outfit",cursor:"Pointer", marginBottom: 10,marginLeft:20,color:colors.primary,fontSize:14 }}>
          filters
        </HeaderText>

      </div>
      {active == "Short Listed" && (
        <div>
          {Students.map((item) => (
            <CardWrapper
              style={{
                marginBottom: 10,
                padding: 10,
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Avatar src={item.image}>{item.name.split("")[0]}</Avatar>
              <div>
                <HeaderText
                  style={{
                    marginLeft: 20,
                    fontSize: "16px",
                    display: "flex",
                    fontFamily: "Outfit",
                  }}
                >
                  {item.name}
                </HeaderText>
                <HeaderText style={{ marginLeft: 20, fontSize: 10 }}>
                  {item.className}
                </HeaderText>
              </div>
              <Button
                onClick={() => navigate("/CollegeDashboard/studentProfile")}
                variant="contained"
                sx={{
                  margin: "5px",
                  marginLeft: "auto",
                  marginTop: "10px",
                  background: colors.primary,
                  // fontWeight: "bold",
                  fontFamily: "Outfit",
                  borderRadius: "100px",
                  textTransform: "none",
                }}
              >
                View Profile
              </Button>
            </CardWrapper>
          ))}
        </div>
      )}
      {active == "Hired" &&
        Students.filter((item, index) => index > 2).map((item) => (
          <CardWrapper
            style={{
              marginBottom: 10,
              padding: 10,
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Avatar src={item.image}>{item.name.split("")[0]}</Avatar>
            <div>
              <HeaderText
                style={{
                  marginLeft: 20,
                  fontSize: "16px",
                  display: "flex",
                  fontFamily: "Outfit",
                }}
              >
                {item.name}
              </HeaderText>
              <HeaderText style={{ marginLeft: 20, fontSize: 10 }}>
                Infosys Pvt.Ltd
              </HeaderText>
            </div>
            <Button
              onClick={() => navigate("/CollegeDashboard/studentProfile")}
              variant="contained"
              sx={{
                margin: "5px",
                marginLeft: "auto",
                marginTop: "10px",
                background: colors.primary,
                // fontWeight: "bold",
                fontFamily: "Outfit",
                borderRadius: "100px",
                textTransform: "none",
              }}
            >
              View Profile
            </Button>
          </CardWrapper>
        ))}
      {active == "Rejected" &&
        Students.filter((item, index) => index > 3).map((item) => (
          <CardWrapper
            style={{
              marginBottom: 10,
              padding: 10,
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Avatar src={item.image}>{item.name.split("")[0]}</Avatar>
            <div>
              <HeaderText
                style={{
                  marginLeft: 20,
                  fontSize: "16px",
                  display: "flex",
                  fontFamily: "Outfit",
                }}
              >
                {item.name}
              </HeaderText>
              <HeaderText style={{ marginLeft: 20, fontSize: 10 }}>
                Full Stack Developer
              </HeaderText>
            </div>
            <Button
              onClick={() => navigate("/CollegeDashboard/studentProfile")}
              variant="contained"
              sx={{
                margin: "5px",
                marginLeft: "auto",
                marginTop: "10px",
                background: colors.primary,
                // fontWeight: "bold",
                fontFamily: "Outfit",
                borderRadius: "100px",
                textTransform: "none",
              }}
            >
              View Profile
            </Button>
            <Button
              onClick={() => navigate("/editProfile")}
              variant="contained"
              sx={{
                margin: "5px",
                marginLeft: "10px",
                marginTop: "10px",
                background: "red",
                // fontWeight: "bold",
                fontFamily: "Outfit",
                borderRadius: "100px",
                textTransform: "none",
              }}
            >
              Edit Profile
            </Button>
          </CardWrapper>
        ))}
    </Layout>
  );
};

export default StudentView;
