import React from "react";
import CardWrapper from "../Card/CardWrapper";
import HeaderText from "../HeaderText";
import JobCard from "../Card/JobCard";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Button } from "@mui/material";
import { colors } from "../../constants/colors";
const SampleJobCard = ({onButtonClick, jobListing, title, applied, showAll,owner,button,onClick }) => {
  return (
    <CardWrapper style={{ marginBottom: 30 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
      <HeaderText style={{marginLeft:10,fontFamily:"Outfit"}}>{title}</HeaderText>
      <HeaderText style={{ fontSize: 14, color: "#0049FC",cursor:"pointer",fontFamily:"Outfit",marginRight:10 }}>
        {button}
      </HeaderText></div>
      {jobListing.map((item) => (
        <JobCard onClick={onClick}  owner={owner} item={item} />
      ))}
      {!showAll && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
          onClick={onButtonClick||(()=>{})}
            variant="contained"
            sx={{
              margin: "10px",
              marginLeft: "auto",
              width: 100,
              background: colors.primary,
        
              borderRadius:"100px",
              marginBottom: 0,
              fontFamily: "Outfit",
              textTransform: "none",
            }}
          >
            Show all
          </Button>
        </div>
      )}
    </CardWrapper>
  );
};

export default SampleJobCard;
