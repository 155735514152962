import React from 'react'
import Layout from '../components/Layout/Layout'
import HeaderText from '../components/HeaderText'
import TableComponents from '../components/TableComponents'

const MyApplication = () => {
    return (
        <Layout>
            <HeaderText style={{ textAlign: "center", marginTop: 20,marginBottom:20 }}>
                My Applications</HeaderText>
                <TableComponents/>
        </Layout>
    )
}

export default MyApplication