import React from "react";
import Layout from "../../components/Layout/Layout";
import CardWrapper from "../../components/Card/CardWrapper";
import HeaderText from "../../components/HeaderText";
import { Box, Button, Tab, Tabs } from "@mui/material";
import ApplicantCard from "../../components/Applicantcard";
import { colors } from "../../constants/colors";
import Back from "../../components/Back";

const JobListingInfo = () => {
  const [value, setValue] = React.useState("one");

  const handleChange = (event, newValue) => {
    console.log(newValue);
    setValue(newValue);
  };
  return (
    <Layout>
      <Back title={"Application Details"}/>
      <div style={{ marginTop: 20, display: "flex", flexDirection: "row" }}>
        <div style={{ flex: 1,  }}>
          <CardWrapper
            style={{ flex: 1,padding:20, height: 600, overflow: "scroll" }}
            className="example"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div>
                <HeaderText style={{ fontFamily: "Outfit" }}>
                  Full Stack Devloper
                </HeaderText>{" "}
                <HeaderText style={{ fontSize: 14, color: "grey" }}>
                  Pune
                </HeaderText>
              </div>
              <Button
                variant="contained"
                sx={{
                  margin: "5px",
                  marginLeft: "auto",

                  borderRadius: "100px",
                  background: colors.primary,
                  // fontWeight: "bold",
                  fontFamily: "Outfit",
                  textTransform: "none",
                }}
              >
                View Test Response
              </Button>
            </div>

          
            <ApplicantCard />
          </CardWrapper>
        </div>
      </div>
    </Layout>
  );
};

export default JobListingInfo;
