import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";
import { Avatar } from "@mui/material";
import HeaderText from "../HeaderText";
import { colors } from "../../constants/colors";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "20px",
  border: "none",
  background: "rgb(244, 245, 255)",
  color: "black",
  border: `0.2px solid ${colors.primary}`,
  marginRight: theme.spacing(2),
  marginLeft: 0,
  fontFamily: "Outfit",
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "100%",
    fontFamily: "Outfit",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),

    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    color: colors.primary,
    fontFamily: "Outfit",
    [theme.breakpoints.up("md")]: {
      width: "50ch",
    },
  },
}));

export default function Header({ profile, name }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [user, setUser] = React.useState("student");
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    navigate(
      localStorage.getItem("user") == "student"
        ? "/profile"
        : localStorage.getItem("user") == "company"
        ? "/companyProfile"
        : "/CollegeProfile"
    );
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const navigate = useNavigate();
  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        onClick={() => {
          handleMenuClose();
        }}
      >
        Profile
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={() => navigate("/Chat")}>
        <IconButton
          size="large"
          onClick={() => navigate("/Chat")}
          aria-label="show 4 new mails"
          color="black"
        >
          <Badge badgeContent={4} color="error">
            <MailIcon onClick={() => navigate("/Chat")} />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="black"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="black"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flex: 1 }}>
      <Toolbar>
    
        <Search>
          <SearchIconWrapper>
            <SearchIcon style={{ color: colors.primary }} color="lightgrey" />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search job title"
            inputProps={{ "aria-label": "search" }}
          />
        </Search>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ display: { xs: "none", md: "flex" }, marginLeft: "auto" }}>
          <IconButton
            size="large"
            aria-label="show 17 new notifications"
            color="#0049FC"
          >
            <NotificationsIcon style={{ color: "#0049FC" }} />
          </IconButton>
          <IconButton
            onClick={() => navigate("/Chat")}
            size="large"
            aria-label="show 4 new mails"
            color="black"
          >
            <MailIcon style={{ color: "#0049FC" }} />
          </IconButton>

          <IconButton
            size="large"
            edge="end"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="black"
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  border: "1px solid #0049FC",
                  padding: 3,
                  borderRadius: 100,
                  display: "flex",
                  alignItems: "center",
                  height: 44,
                }}
              >
                <img
                  src={
                    profile ||
                    "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?cs=srgb&dl=pexels-pixabay-220453.jpg&fm=jpg"
                  }
                  style={{
                    height: 36,
                    width: 36,
                    borderRadius: 50,
                    objectFit: "cover",
                  }}
                />
                <HeaderText style={{ fontSize: 14, margin: 5, color: "black" }}>
                  {name || "Anamoul"}
                </HeaderText>
                <KeyboardArrowDownIcon style={{ color: "#0049FC" }} />
              </div>
            </div>
          </IconButton>
        </Box>
        <Box sx={{ display: { xs: "flex", md: "none" } }}>
          <div
            style={{
              border: "1px solid #0049FC",
              padding: 3,
              borderRadius: 100,
              display: "flex",
              alignItems: "center",
              height: 44,
            }}
            onClick={handleMobileMenuOpen}
          >
            <img
              src={
                profile ||
                "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?cs=srgb&dl=pexels-pixabay-220453.jpg&fm=jpg"
              }
              style={{
                height: 36,
                width: 36,
                borderRadius: 50,
                objectFit: "cover",
              }}
            />
          </div>
        </Box>
      </Toolbar>

      {renderMobileMenu}
    </Box>
  );
}
