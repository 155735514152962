import React from 'react'
import Layout from '../components/Layout/Layout'
import { Stepper } from '@mui/material'
import HorizontalLinearStepper from '../components/StepperComponent'
import HeaderText from '../components/HeaderText'

const Register = () => {
    const steps=[
        "Profile Info",
        "Skills Info",
        "Education Info",
        "Job Info",
        "Others"
    ]
    return (
        <Layout>
            <div>
                <HeaderText style={{ textAlign: "center", margin: 20 }}>Register</HeaderText> <HorizontalLinearStepper steps={steps}/></div></Layout>
    )
}

export default Register