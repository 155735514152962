import React, { useEffect, useState } from "react";
import StudentDashboard from "../../Layout/StudentDashboard";
import HeaderText from "../../components/HeaderText";
import ShareIcon from "@mui/icons-material/Share";
import { colors } from "../../constants/colors";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import DescriptionIcon from "@mui/icons-material/Description";
import HelpIcon from "@mui/icons-material/Help";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Button, Grid } from "@mui/material";
import AssessmentIcon from "@mui/icons-material/Assessment";
import MenuCard from "../../components/Card/MenuCard";
import CardWrapper from "../../components/Card/CardWrapper";
import PostCard from "../../components/PostCard";
import Layout from "../../components/Layout/Layout";
import { getCollageProfile } from "../../ApiCalls";
import { formatNumber } from "../../utils/ConvertorFunctions";

const menuItems = [
  {
    icon: <AssessmentIcon sx={{ color: "black" }} />,
    text: "Posts",
  },
  {
    icon: <RecordVoiceOverIcon sx={{ color: "black" }} />,
    text: "Details",
  },
  {
    icon: <DescriptionIcon sx={{ color: "black" }} />,
    text: "Students",
  },
  {
    icon: <HelpIcon sx={{ color: "black" }} />,
    text: "Culture",
  },
];


const CollegeProfile = () => {
  const [user,setUser]=useState();
  useEffect(()=>{
    let result=getCollageProfile();
    setUser(result)
  },[])
  return (
    <Layout>
      {user&&<div>
        <img
          src="https://officesnapshots.com/wp-content/uploads/2012/07/GoogleMoscow701.jpg"
          style={{
            height: "300px",
            width: "100%",
            objectFit: "cover",
            borderRadius: 8,
          }}
        />
        <div
        className="maincontainer"
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <img
            src={user?.image}
            style={{
              height: 150,
              width: "150px",
              borderRadius: "100px",
              objectFit: "cover",
              marginLeft: 20,
              background: "white",
              marginTop: "-60px",
              border: "6px solid white",
            }}
          />
          <div style={{ marginLeft: 10 }}>
            <HeaderText style={{ fontFamily: "Outfit" }}>{user?.name}</HeaderText>
            <HeaderText
              style={{
                fontFamily: "Outfit",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              {user.collage_type} .{user.location} .{" "}
                {formatNumber(user.followers)} followers . {formatNumber(user.students)} students
            </HeaderText>
          </div>
          <div style={{ marginLeft: "auto" }}>
            <ShareIcon style={{ color: colors.primary }} />
            <NotificationsIcon
              style={{ color: colors.primary, marginLeft: 20 }}
            />
          </div>
        </div>
        <Grid container>
          <Grid item xs={12} sm={4}>
            <CardWrapper className={"displayContainer"} style={{marginTop:"10px", marginRight:"20px",}}>
         
              <MenuCard menu={menuItems} style={{ marginRight: "10px" }} />
            </CardWrapper>
          </Grid>

          <Grid item xs={12} sm={8}>
            {" "}
            {/* <CardWrapper style={{ marginLeft: "20px" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={
                    "https://img.freepik.com/premium-vector/post-office-vector-icon-illustration-building-landmark-icon-concept-white-isolated_138676-435.jpg"
                  }
                  style={{ height: 56, width: 56 }}
                />
                <div style={{ marginLeft: 10 }}>
                  <HeaderText style={{ fontFamily: "Outfit" }}>
                    News Letter
                  </HeaderText>
                  <HeaderText
                    style={{
                      fontFamily: "Outfit",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    College news details
                  </HeaderText>
                </div>
                <Button
                  variant="outlined"
                  style={{
                    marginLeft: "auto",
                    border: `1px solid ${colors.primary}`,
                    color: colors.primary,
                    textTransform: "none",
                    fontFamily: "Outfit",
                  }}
                >
                  More
                </Button>
              </div>
              <div
                className="example"
                style={{
                  display: "flex",
                  paddingTop: 20,
                  paddingLeft: 20,
                  overflowX: "scroll",
                }}
              >
                <div
                  style={{
                    width: "250px",
                    flexShrink: "0",
                    marginRight: "10px",
                  }}
                >
                  <PostCard
                    company={"Alard College"}
                    logo={
                      "https://alardcollegeofengineering.com/my-img/banner1.jpg"
                    }
                    companyType={"Engineering College"}
                    desc={"College with good culture based in Pune. "}
                    photo={
                      "https://img.freepik.com/free-photo/smiling-businessman-eyeglasses-sitting-by-table-cafe-with-laptop-computer-while-using-smartphone-writing-something_171337-5589.jpg?w=2000"
                    }
                  />
                </div>
                <div
                  style={{
                    width: "250px",
                    flexShrink: "0",
                    marginRight: "10px",
                  }}
                >
                  <PostCard
                    company={"Alard College"}
                    logo={
                      "https://alardcollegeofengineering.com/my-img/banner1.jpg"
                    }
                    companyType={"Engineering College"}
                    desc={"College with good culture based in Pune. "}
                    photo={
                      "https://i.insider.com/564ca0612491f9c12e8b5f47?width=1000&format=jpeg&auto=webp"
                    }
                  />
                </div>
                <div
                  style={{
                    width: "250px",
                    flexShrink: "0",
                    marginRight: "10px",
                  }}
                >
                  <PostCard
                    company={"Alard College"}
                    logo={
                      "https://alardcollegeofengineering.com/my-img/banner1.jpg"
                    }
                    companyType={"Engineering College"}
                    desc={"College with good culture based in Pune. "}
                    photo={
                      "https://wp-assets.futurism.com/2022/08/google-employees-alarmed-company-expects-them-to-work.jpg"
                    }
                  />
                </div>
                <div
                  style={{
                    width: "250px",
                    flexShrink: "0",
                    marginRight: "10px",
                  }}
                >
                  <PostCard
                    company={"Alard College"}
                    logo={
                      "https://alardcollegeofengineering.com/my-img/banner1.jpg"
                    }
                    companyType={"Engineering College"}
                    desc={"College with good culture based in Pune. "}
                    photo={
                      "https://img.freepik.com/free-photo/smiling-businessman-eyeglasses-sitting-by-table-cafe-with-laptop-computer-while-using-smartphone-writing-something_171337-5589.jpg?w=2000"
                    }
                  />
                </div>
                <div
                  style={{
                    width: "250px",
                    flexShrink: "0",
                    marginRight: "10px",
                  }}
                >
                  <PostCard
                    company={"Alard College"}
                    logo={
                      "https://alardcollegeofengineering.com/my-img/banner1.jpg"
                    }
                    companyType={"Engineering College"}
                    desc={"College with good culture based in Pune. "}
                    photo={
                      "https://wp-assets.futurism.com/2022/08/google-employees-alarmed-company-expects-them-to-work.jpg"
                    }
                  />
                </div>
              </div>
            </CardWrapper> */}
            <CardWrapper style={{marginTop:"10px" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={
                   "https://img.freepik.com/premium-vector/company-flat-vector-icon_9206-336.jpg"     }
                  style={{ height: 56, width: 56 }}
                />
                <div style={{ marginLeft: 10 }}>
                  <HeaderText style={{ fontFamily: "Outfit" }}>
                    About
                  </HeaderText>
                  <HeaderText
                    style={{
                      fontFamily: "Outfit",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                  About Collage
                  </HeaderText>
                </div>
                <Button
                  variant="outlined"
                  style={{
                    marginLeft: "auto",
                    border: `1px solid ${colors.primary}`,
                    color: colors.primary,
                    textTransform: "none",
                    fontFamily: "Outfit",
                  }}
                >
                  More
                </Button>
              </div>
              <HeaderText
                    style={{
                      fontFamily: "Outfit",
                      fontSize: "14px",
                      fontWeight: "400",
                      marginTop:"10px"
                    }}
                  >
                 {user.collageDescription}
                  </HeaderText></CardWrapper>
                
          </Grid>
        </Grid>
      </div>}
    </Layout>
  );
};

export default CollegeProfile;
