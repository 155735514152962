import React from "react";

const CardWrapper = ({ children, style,className,onClick }) => {
  return (
    <div
    onClick={onClick||(()=>{})}
      style={{
        padding: 10,
        background:"white",
        borderRadius: 8,
        ...style,
      }}
      className={className}
    >
      {children}
    </div>
  );
};

export default CardWrapper;
