import React from 'react'
import HeaderText from '../components/HeaderText'
import Layout from '../components/Layout/Layout'
import { Button, Divider, FormControl, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material'
import StudentDashboard from '../Layout/StudentDashboard'
import { colors } from '../constants/colors'
import CardWrapper from '../components/Card/CardWrapper'

const Application = () => {
    return (
        <Layout>

<CardWrapper style={{padding:"20px"}}>

            <HeaderText style={{ textAlign: "center", marginTop: 20, marginBottom: 20,fontFamily:"Outfit" }}>
                Virtual Volunteering internship at InAmigos Foundation</HeaderText>

            <Divider
                sx={{
                    borderBottom: "2px solid black",
                    marginTop: "10px",
                    marginBottom: "10px",
                }}
            />  <HeaderText style={{ marginTop: 20, marginBottom: 20 ,fontFamily:"Outfit" }}>
                Cover letter</HeaderText>
            <HeaderText style={{ fontSize: 16, color: "grey" }}>
                Why should you be hired for this role?</HeaderText>
            <HeaderText style={{ fontSize: 13, color: "skyblue", cursor: "Pointer", marginTop: 10 }}>
                Copy from your last application & edit</HeaderText>
            <TextField
                fullWidth
                id="outlined-multiline-static"
                label="Answer"
                multiline
                sx={{ marginTop: "10px" }}
                rows={4}

            />
            <HeaderText style={{ fontSize: 16, marginTop: 20 }}>
                Your availability</HeaderText>
            <FormControl>

                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                >
                    <FormControlLabel value="female" control={<Radio />}  label={   <HeaderText style={{ fontSize: 14, color: "grey"}}>
                    Yes, I am available for 3 weeks starting immediately for a full-time work from home internship</HeaderText>} ></FormControlLabel>
                    <FormControlLabel value="male" control={<Radio />} label={ <HeaderText style={{ fontSize: 14, color: "grey"}}>
                    No (Please specify your availability)</HeaderText>} />
                  
                </RadioGroup>
              
            </FormControl>
            <TextField
                fullWidth
                id="outlined-multiline-static"
                label="Answer"
                multiline
                sx={{ marginTop: "10px" }}
                rows={4}

            />
            <div style={{ display: "flex", flexDirection: "row",justifyContent:"center",alignItems:"center",marginTop:"20px" }}>
        <Button
          variant="contained"
          sx={{
            margin: "5px",
          
            width: 80,
            background: colors.primary,
            fontFamily: "Outfit",
            textTransform: "none",
          }}
        >
          Apply
        </Button></div></CardWrapper>
        </Layout>
    )
}

export default Application