import React from "react";
import CardWrapper from "../CardWrapper";
import { List, ListItem, ListItemIcon, ListItemText, ListSubheader } from "@mui/material";
import HeaderText from "../../HeaderText";

const MenuCard = ({ menu,style }) => {
  return (
    <CardWrapper style={style}>
        <List
      
      sx={{ width: '100%', maxWidth: 360 }}
    >
      
      {menu.map((item, index) => (
        <ListItem button key={index}>
          <ListItemIcon >{item.icon}</ListItemIcon>
          <ListItemText  ><p style={{fontFamily: "Quicksand",fontWeight:"bold",margin:0}}>{item.text} </p></ListItemText>
        </ListItem>
      ))}</List>
    </CardWrapper>
  );
};

export default MenuCard;
