import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";
import { Button, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import HeaderText from "../../components/HeaderText";
import { colors } from "../../constants/colors";
import CardWrapper from "../../components/Card/CardWrapper";
import { useNavigate } from "react-router-dom";

const AddProfile = () => {
  const [topics, setTopics] = useState(1);
  const navigate = useNavigate();
  const [age, setAge] = useState();
  let engineeringDivisions = [
    "Civil ",
    "Mechanical ",
    "Electrical ",
    "Chemical ",
    "Computer ",
    "Aerospace ",
    "Biomedical ",
    "Environmental ",
    "Industrial ",
    "Materials ",

    "Petroleum ",
  ];
  return (
    <Layout>
      <div style={{ display: "flex", width: "100%" }}>
        <CardWrapper
          style={{
            marginTop: "20px",
            padding: "20px",
            maxWidth: "500px",
            margin: "auto",
          }}
        >
          <HeaderText
            style={{
              textAlign: "center",
              marginBottom: "20px",
              fontFamily: "Outfit",
            }}
          >
         Add Profile
          </HeaderText>
          <div>
            <InputLabel htmlFor="file-input" style={{ marginTop: 20 }}>
              Profile Image:
            </InputLabel>
            <TextField
              type="file"
              fullWidth
              id="file-input"
              variant="outlined"
            />
            <TextField
              fullWidth
              id="outlined-basic"
              label="Name"
              variant="outlined"
              sx={{ marginTop: "20px" }}
            />
            <TextField
              fullWidth
              id="outlined-basic"
              label="Profile"
              sx={{ marginTop: "20px" }}
              variant="outlined"
            />
            <TextField
              fullWidth
              id="outlined-basic"
              sx={{ marginTop: "20px" }}
              label="Email Address"
              variant="outlined"
            />
           
            <TextField
              style={{ marginTop: "20px" }}
              fullWidth
              id="outlined-basic"
              label="Location"
              variant="outlined"
            />
          </div>
         
          <Button
            fullWidth
            variant="contained"
            sx={{
              margin: "5px",
              marginLeft: "auto",
              marginTop: "10px",
              background: colors.primary,
              borderRadius: "100px",
              fontFamily: "Outfit",
              textTransform: "none",
            }}
          >
            Add
          </Button>
        </CardWrapper>
      </div>
    </Layout>
  );
};

export default AddProfile;
