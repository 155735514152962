import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import PostCard from "../components/PostCard";
import { Grid } from "@mui/material";
import SampleJobCard from "../components/SampleJobCard";
import MenuCard from "../components/Card/MenuCard";
import AssessmentIcon from "@mui/icons-material/Assessment";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import DescriptionIcon from "@mui/icons-material/Description";
import HelpIcon from "@mui/icons-material/Help";
import { useNavigate } from "react-router-dom";
import { getPosts } from "../ApiCalls";

const Timelines = () => {
  const navigate = useNavigate();
  const [post, setPost] = useState();
  const menuItems = [
    {
      icon: <AssessmentIcon sx={{ color: "black" }} />,
      text: "Skill Assessment",
    },
    {
      icon: <RecordVoiceOverIcon sx={{ color: "black" }} />,
      text: "Interview Prep",
    },
    {
      icon: <DescriptionIcon sx={{ color: "black" }} />,
      text: "Resume Builder",
    },
    {
      icon: <HelpIcon sx={{ color: "black" }} />,
      text: "Job Seeker Guidance",
    },
  ];
  const jobListing = [
    {
      image:
        "https://images.tech.co/wp-content/uploads/2019/12/13052618/Tech-co-Logo-.png",
      jobTitle: "Software Engineer",
      location: "Bangalore, India",
      companyName: "TechCo Inc.",
      ctc: "₹ 12 LPA to ₹ 15 LPA",
    },
    {
      image:
        "https://yt3.googleusercontent.com/AAnXC4o1n8BKDsO5l6Uc71rf7WOJjm2-aUHzkvyp9vGYB5F4UtXWTecVzvPOBCFK0bNYsZlD7Hk=s900-c-k-c0x00ffffff-no-rj",
      jobTitle: "Data Scientist",
      location: "Mumbai, India",
      companyName: "Meta",
      ctc: "₹ 10 LPA to ₹ 13 LPA",
    },
    {
      image:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/9/95/Instagram_logo_2022.svg/1200px-Instagram_logo_2022.svg.png",
      jobTitle: "UX/UI Designer",
      location: "Delhi, India",
      companyName: "Instagram",
      ctc: "₹ 8 LPA to ₹ 10 LPA",
    },
  ];
  useEffect(() => {
    const result = getPosts();
    setPost(result);
  }, []);
  return (
    <Layout>
      <div style={{ position: "relative" }}>
        <Grid container spacing={[3, 3]} style={{ marginTop: "10px" }}>
          <Grid item xs={12} sm={3}>
            <div className="displayContainer" style={{ position: "sticky" }}>
              <MenuCard menu={menuItems} />
            </div>
          </Grid>{" "}
          <Grid item xs={12} sm={6}>
            <div style={{display:"flex",alignItems:"center",flexDirection:"column"}}>
            {post?.map((item) => (
              <PostCard
                link={item.link}
                company={item.user.name}
                logo={item.user.logo}
                companyType={item.user.occupation}
                desc={item.description}
                photo={item.photo}
              />
            ))}
</div>
         
           
          </Grid>{" "}
          <Grid item xs={12} sm={3}>
            <div className="displayContainer">
              {" "}
              <SampleJobCard
                jobListing={jobListing}
                title={"Jobs for you"}
                button={"All Jobs"}
                onButtonClick={() => {
                  navigate("/jobs/all_jobs");
                }}
                onClick={(id) => {
                  navigate("/jobDetails/" + (id || 1));
                }}
                showAll
              />{" "}
            </div>
          </Grid>
        </Grid>
      </div>
    </Layout>
  );
};

export default Timelines;
