import { Collage } from "./raw_data/collage";
import { Company } from "./raw_data/company";
import { Student } from "./raw_data/student";

export const getStudentProfile = () => {
  return Student.profile;
};
export const getStudentResume = () => {
  return Student.resumeDetails;
};
export const getStudentDashboard = () => {
  return Student.dashboard;
};
export const getStudentAllJobs = () => {
  return Student.jobs.all_jobs;
};
export const getJobDetails = () => {
  return Student.jobs.jobDetails;
};
export const getMyJobs = () => {
  return Student.jobs.my_jobs;
};

export const getCourses = () => {
  return Student.training;
};
export const getAssement = () => {
  return Student.assessment;
};
export const getPosts= () => {
  return Student.posts;
};

//company Api Call
export const getCompanyProfile = () => {
  return Company.profile;
};
export const getCompanyDashboard = () => {
  return Company.dashboard;
};
 export const getJobTemplates=()=>{
  return Company.jobsTemplates;
 }
 export const getJobLisiting=()=>{
  return Company.jobListing;
 }
 export const getCompanyCourses = () => {
  return Company.courses;
};


export const getCompanyPost=()=>{
  return Company.companyPost
}

export const getCollageProfile=()=>{
return Collage.profile
}