import React from "react";
import CardWrapper from "../Card/CardWrapper";
import HeaderText from "../HeaderText";
import { Button, Grid } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useState } from "react";
import { colors } from "../../constants/colors";
const QuestionCard = ({ question, setquestion, data, index }) => {
  const [active, setActive] = useState();

  const submit = (item) => {
    setActive(item);
    let answer = data;
    answer[index].answer = item;
    setquestion(answer);
  };
  return (
    <div>
      {question?.image && (
        <img src={question?.image} style={{ height: "200px" }} />
      )}
      <HeaderText
        style={{ fontSize: "18px", fontFamily: "Outfit", marginTop: 20 }}
      >
        {index + 1}. {question?.title}
      </HeaderText>
      <Grid sx={{ marginTop: "10px" }} container>
        {question.options.map((item) => (
          <Grid item xs={6}>
            <div
              style={{
                alignItems: "center",
                display: "flex",
                marginBottom: 10,
              }}
              onClick={() => submit(item)}
            >
              {active != item ? (
                <CheckBoxOutlineBlankIcon style={{}} />
              ) : (
                <CheckBoxIcon style={{ color: colors.primary }} />
              )}
              <HeaderText style={{ fontSize: "15px", cursor: "pointer" }}>
                {item}
              </HeaderText>
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default QuestionCard;
