import React, { useEffect } from "react";
import Layout from "../../../components/Layout/Layout";
import { Book, BookOnline } from "@mui/icons-material";
import BreadCumb from "../../../components/BradCumb";
import { Avatar, Button, Divider, Grid, LinearProgress } from "@mui/material";
import CardWrapper from "../../../components/Card/CardWrapper";
import HeaderText from "../../../components/HeaderText";
import CourseContentCard from "./CourseContentCard";
import { colors } from "../../../constants/colors";
import { useLocation } from "react-router-dom";
import GraphCard from "../../../components/Card/GraphCard";

const CompanyCourseDetails = () => {
  function convertToEmbedLink(youtubeLink) {
    return youtubeLink.replace(/watch\?v=/, "embed/");
  }
  const location = useLocation();
  let Students = [
    {
      image:
        "https://www.corporatephotographylondon.com/wp-content/uploads/2019/11/HKstrategies-1210-1024x683.jpg",
      name: "John Smith",
      age: 18,
      grade: 85,
      course: "Full stack web developing",
    },
    {
      image:
        "https://www.corporatephotographylondon.com/wp-content/uploads/2019/11/HKstrategies-755-1-1024x683.jpg",
      name: "Alice Johnson",
      age: 17,
      grade: 92,
      course: "Full stack Java developing",
    },
    {
      image:
        "https://media.istockphoto.com/id/1329936184/photo/head-shot-portrait-of-smiling-businesswoman-intern-looking-at-camera.jpg?s=612x612&w=0&k=20&c=cCeWt0GYUtcFsGFi6hTFci-d5KZY6FAd_UchY9V5aSo=",
      name: "Bob Brown",
      age: 19,
      grade: 78,
      course: "Full stack python developing",
    },
    {
      image:
        "https://media.istockphoto.com/id/1303206558/photo/headshot-portrait-of-smiling-businessman-talk-on-video-call.jpg?s=612x612&w=0&k=20&c=hMJhVHKeTIznZgOKhtlPQEdZqb0lJ5Nekz1A9f8sPV8=",
      name: "Emily Davis",
      age: 20,
      grade: 88,
      course: "Full stack android developing",
    },
    {
      image:
        "https://media.istockphoto.com/id/1309328823/photo/headshot-portrait-of-smiling-male-employee-in-office.jpg?s=612x612&w=0&k=20&c=kPvoBm6qCYzQXMAn9JUtqLREXe9-PlZyMl9i-ibaVuY=",
      name: "David Wilson",
      age: 18,
      grade: 76,
      course: "Full stack angular developing",
    },
    {
      image:
        "https://www.corporatephotographylondon.com/wp-content/uploads/2019/11/HKstrategies-1029-1024x683.jpg",
      name: "Sarah Lee",
      age: 19,
      grade: 95,
      course: "backend java developing",
    },
    {
      image:
        "https://www.corporatephotographylondon.com/wp-content/uploads/2019/11/Option-3-1024x683.jpg",
      name: "Michael Jones",
      age: 17,
      grade: 89,
      course: "git",
    },
    {
      image:
        "https://img.freepik.com/free-photo/portrait-optimistic-businessman-formalwear_1262-3600.jpg?size=626&ext=jpg&ga=GA1.1.1413502914.1696982400&semt=ais",
      name: "Olivia Martinez",
      age: 20,
      grade: 91,
      course: "linux development",
    },
  ];
  let course = {
    link: "https://www.youtube.com/watch?v=kqtD5dpn9C8",
    content: [
      {
        title: "Python Introduction",
        time: "2:30",
        status: "completed",
        resource: [
          {
            title: "Github Project",
            link: "https://www.github.com",
          },
          {
            title: "Pycharam Project",
            link: "https://www.github.com",
          },
        ],
      },
      {
        title: "Python Variable",
        time: "10:30",
        status: "pending",
        resource: [
          {
            title: "Geeks for geeks",
            link: "https://www.github.com",
          },
        ],
      },
      {
        title: "Python functions",
        time: "10:30",
        status: "pending",
      },
    ],
  };
  const navigationdata = [
    {
      icon: (
        <BookOnline style={{ marginRight: 5, color: "0049FC", fontSize: 18 }} />
      ),
      name: "Courses",
      route: "/",
    },
    {
      name: "The Complete Python Bootcamp From Zero to Hero",
      route: "/all_jobs",
    },
  ];
  useEffect(() => {
    console.log(new URLSearchParams(location.search));
  }, []);
  return (
    <Layout>
      <BreadCumb data={navigationdata} />
      <Grid container spacing={[5, 5]}>
        <Grid item xs={8}>
          <iframe
            allowFullScreen
            style={{
              width: "100%",
              borderRadius: 10,
              marginTop: "10px",
              overflow: "hidden",
              border: "0px solid black",
            }}
            height="500"
            src={convertToEmbedLink(
              "https://www.youtube.com/watch?v=kqtD5dpn9C8"
            )}
          ></iframe>
        
          <Grid container spacing={[0, 1]}>
            <Grid item xs={6}>
              <div style={{ marginTop: "20px" }}>
                <HeaderText style={{ fontFamily: "Outfit" }}>
                  Top Students
                </HeaderText>
                <HeaderText
                  style={{
                    fontFamily: "Outfit",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  Top Students after assignments
                </HeaderText>
                <CardWrapper style={{ marginTop: "10px" }}>
                  {Students.map((item) => (
                    <CardWrapper
                      style={{
                        marginBottom: 10,
                        padding: 10,
                        flexDirection: "row",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Avatar src={item.image}>{item.name.split("")[0]}</Avatar>
                      <div>
                        <HeaderText
                          style={{
                            marginLeft: 20,
                            fontSize: "16px",
                            display: "flex",
                            fontFamily: "Outfit",
                          }}
                        >
                          {item.name}
                        </HeaderText>
                        <HeaderText style={{ marginLeft: 20, fontSize: 10 }}>
                          {item.course}
                        </HeaderText>
                      </div>
                      <HeaderText
                        style={{
                          fontFamily: "Outfit",
                          fontSize: "12px",
                          maxWidth: "80%",
                          marginLeft: "auto",
                          background: colors.primary,
                          marginTop: "10px",
                          textAlign: "center",
                          color: "white",
                          borderRadius: "100px",
                          fontWeight: "500",
                          width: "40px",
                        }}
                      >
                        {item.grade}%
                      </HeaderText>
                    </CardWrapper>
                  ))}
                </CardWrapper>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div style={{ marginTop: "20px" }}>
                <HeaderText style={{ fontFamily: "Outfit" }}>
                  Top Certification
                </HeaderText>
                <HeaderText
                  style={{
                    fontFamily: "Outfit",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  Certified software developer
                </HeaderText>
                <CardWrapper style={{ marginTop: "10px" }}>
                  {Students.map((item) => (
                    <CardWrapper
                      style={{
                        marginBottom: 10,
                        padding: 10,
                        flexDirection: "row",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Avatar src={item.image}>{item.name.split("")[0]}</Avatar>
                      <div>
                        <HeaderText
                          style={{
                            marginLeft: 20,
                            fontSize: "16px",
                            display: "flex",
                            fontFamily: "Outfit",
                          }}
                        >
                          {item.name}
                        </HeaderText>
                        <HeaderText style={{ marginLeft: 20, fontSize: 10 }}>
                          {item.course}
                        </HeaderText>
                      </div>
                      <HeaderText
                        style={{
                          fontFamily: "Outfit",
                          fontSize: "12px",
                          maxWidth: "80%",
                          marginLeft: "auto",
                          background: colors.primary,
                          marginTop: "10px",
                          textAlign: "center",
                          color: "white",
                          borderRadius: "100px",
                          fontWeight: "500",
                          width: "40px",
                        }}
                      >
                        {item.grade}%
                      </HeaderText>
                    </CardWrapper>
                  ))}
                </CardWrapper>
              </div>
            </Grid>{" "}
          </Grid>{" "}
        </Grid>
        <Grid item xs={4}>
          <CardWrapper style={{ marginTop: "10px",marginBottom:"20px" }}>
       <Button variant="contained" style={{marginLeft:"auto",float:"right"}}><HeaderText style={{ fontFamily: "Outfit",fontWeight:"100", fontSize: "13px" }}>Edit Course</HeaderText></Button>
            <HeaderText style={{ fontFamily: "Outfit", fontSize: "16px" }}>
              Course Content
            </HeaderText>
            {course.content.map((item) => (
              <CourseContentCard item={item} />
            ))}
          </CardWrapper>
          <GraphCard title={"Certification"}  value={100}/>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default CompanyCourseDetails;
