import React, { useEffect } from "react";
import StudentDashboard from "../../Layout/StudentDashboard";
import HeaderText from "../../components/HeaderText";
import { useState } from "react";
import { Button, Grid } from "@mui/material";
import CardWrapper from "../../components/Card/CardWrapper";
import EmployeeCard from "../../components/Card/EmployeeCard";
import { colors } from "../../constants/colors";
import QuestionCard from "../../components/Assesment/QuestionCard";
import moment from "moment";
import Layout from "../../components/Layout/Layout";
import { getAssement } from "../../ApiCalls";

const Assesment = () => {
  const [questions, setQuestions] = useState();
  const [submitted, setSubmitted] = useState(false);
  const [active, setActive] = useState(0);
  const [title,setTitle]=useState();
  useEffect(() => {
    const result = getAssement();
    setQuestions(result.questions);
    setTitle(result.title)
  }, []);

  const next = () => {
    if (active + 1 < questions.length) setActive((item) => item + 1);
    else {
      setSubmitted(true);
    }
  };
  const prev = () => {
    if (active - 1 > 0) setActive((item) => item - 1);
  };
  return (
    <Layout>
      <div
        style={{
          marginTop: 20,
          padding: 10,
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <HeaderText style={{ marginBottom: 20, textAlign: "center" }}>
         {title}
        </HeaderText>
        {questions &&
          (!submitted ? (
            <Grid container>
              <Grid item xs={6}>
                {" "}
                <CardWrapper
                  style={{
                    padding: 20,
                    background: "white",
                    marginRight: "10px",
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "500px",
                  }}
                >
                  <QuestionCard
                    setquestion={setQuestions}
                    index={active}
                    data={questions}
                    question={questions[active]}
                  />
                  <div
                    style={{
                      marginTop: 20,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: "auto",
                    }}
                  >
                    <Button variant="contained" onClick={prev}>
                      Back
                    </Button>
                    <Button variant="contained" onClick={next}>
                      Next
                    </Button>
                  </div>
                </CardWrapper>
              </Grid>
              <Grid item xs={6}>
                <CardWrapper
                  style={{
                    padding: 20,
                    background: "white",
                    minHeight: "500px",
                  }}
                >
                  <div style={{ display:"flex",flexDirection:"row", alignItems: "center" }}>
                    <div style={{ display:"flex", flexDirection: "row", alignItems: "center" }}>
                      <div
                        style={{
                          height: 15,
                          width: 15,
                          borderRadius: 10,
                          marginRight:5,
                          background: "green",
                        }}
                      ></div>
                      <HeaderText
                        style={{ fontSize: "14px", fontFamily: "Outfit" }}
                      >
                        Completed 
                      </HeaderText>
                    </div>
                    <div style={{ display:"flex", flexDirection: "row", alignItems: "center" }}>
                      <div
                        style={{
                          height: 15,
                          marginLeft:20,
                          width: 15,
                          borderRadius: 10,
                          marginRight:5,
                          background: "blue",
                        }}
                      ></div>
                      <HeaderText
                        style={{ fontSize: "14px", fontFamily: "Outfit" }}
                      >
                        Active 
                      </HeaderText>
                    </div>
                    <div style={{ display:"flex", flexDirection: "row", alignItems: "center" }}>
                      <div
                        style={{
                          height: 15,
                          marginLeft:20,
                          width: 15,
                          borderRadius: 10,
                          marginRight:5,
                          background: "red",
                        }}
                      ></div>
                      <HeaderText
                        style={{ fontSize: "14px", fontFamily: "Outfit" }}
                      >
                        Uncompleted 
                      </HeaderText>
                    </div>
                  </div>
                  <HeaderText
                    style={{ fontSize: "20px", fontFamily: "Outfit" }}
                  >
                    Questions
                  </HeaderText>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: 10,
                      //   justifyContent: "center",
                    }}
                  >
                    {questions.map((item, index) => (
                      <HeaderText
                        style={{
                          width: 40,
                          height: 40,
                          borderRadius: 8,
                          justify: "center",
                          alignItems: "center",
                          textAlign: "center",
                          padding: 10,

                          color: "white",
                          backgroundColor:
                            active == index
                              ? colors.primary
                              : item.answer
                              ? "green"
                              : "red",
                          margin: 5,
                          fontSize: 15,
                        }}
                      >
                        {index + 1}
                      </HeaderText>
                    ))}
                  </div>
                </CardWrapper>
              </Grid>
            </Grid>
          ) : (
            <CardWrapper>
              <div>
                <div
                  style={{
                    marginTop: 20,
                    backgroundColor: "white",
                    borderRadius: 10,
                    padding: 10,
                  }}
                >
                  <HeaderText style={{ marginBottom: 20, textAlign: "center" }}>
                    Report
                  </HeaderText>
                  <HeaderText
                    style={{
                      marginBottom: 20,
                      fontSize: 14,
                      fontFamily: "Outfit",
                    }}
                  >
                    Total Questions: {questions.length}
                  </HeaderText>
                  <HeaderText
                    style={{
                      marginBottom: 20,
                      fontSize: 14,
                      fontFamily: "Outfit",
                    }}
                  >
                    Answered Questions:{" "}
                    {questions.filter((item) => item.answer).length}
                  </HeaderText>
                  <HeaderText
                    style={{
                      marginBottom: 20,
                      fontSize: 14,
                      fontFamily: "Outfit",
                    }}
                  >
                    Unanswered Questions:{" "}
                    {questions.filter((item) => !item.answer).length}
                  </HeaderText>
                  <HeaderText
                    style={{
                      marginBottom: 20,
                      fontSize: 14,
                      fontFamily: "Outfit",
                    }}
                  >
                    Submission Time: {moment().format("MMMM DD, YYYY hh:mm a")}
                  </HeaderText>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button variant="contained">Submit</Button>
                  </div>
                </div>
              </div>
            </CardWrapper>
          ))}
      </div>
    </Layout>
  );
};

export default Assesment;
