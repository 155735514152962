const profile = {
  image:
    "https://economics.sas.upenn.edu/sites/default/files/2023-06/profile_photo.jpg",
  poster:
    "https://p325k7wa.twic.pics/high/jujutsu-kaisen/jujutsu-kaisen-cursed-clash/00-page-setup/JJK-header-mobile2.jpg?twic=v1/resize=760/step=10/quality=80",
  name: "John Doe",
  age: 30,
  gender: "Male",
  occupation: "Software Engineer",
  oneLiner:
    "Passionate software engineer, avid reader, and outdoor enthusiast.",
  location: "Cityville",
  hobbies: ["Reading", "Coding", "Hiking"],
  contact: {
    email: "john.doe@example.com",
    phone: "555-1234",
    website: "https://johndoe.blog",
  },
  socialMedia: {
    twitter: "@john_doe",
    github: "johndoe",
    linkedin: "john-doe",
  },

  isStudent: false,
};
const resumeDetails = {
  education: [
    {
      img: "https://www.alardinstitutes.com/myimg/logo.png",
      degreee: "Bachelor of Engineering (B.E), COMPUTER",
      univercity: "Alard College Of Engineering",
      year: "2020 - 2023",
      percetage: "75%",
      desc: "This hands-on course examines how content is organized and structured to create an experience for a user, and what role the designer plays in creating and shaping user experience. You will be led through a condensed",
    },
    {
      img: "https://upload.wikimedia.org/wikipedia/commons/6/64/Gcoeara_logo.jpg",
      degreee: "Diploma, COMPUTER",
      univercity: "Government Polytechnic Awasari Kh",
      year: "2020 - 2023",
      percetage: "75%",
      desc: "This hands-on course examines how content is organized and structured to create an experience for a user, and what role the designer plays in creating and shaping user experience. You will be led through a condensed",
    },
  ],
  jobs: [
    {
      img: "https://images.crunchbase.com/image/upload/c_lpad,h_256,w_256,f_auto,q_auto:eco,dpr_1/s0qw2fsxwveso5gudzd6",
      role: "Full Stack devloper",
      company: "Global Market Insights, Pune",
      year: "Sep 2022 - Present",
      skills: ["c", "c++"],
      desc: "Working with next.js Mongdb, aws and express.js",
    },
    {
      img: "https://avatars.githubusercontent.com/u/20360440?s=200&v=4",
      role: "Full Stack devloper",
      company: "Red Carpet Up, Gurgaon",
      year: "Feb 2022 - Sep 2022",
      skills: ["c", "c++"],
      desc: "Working with next.js Mongdb, aws and express.js",
    },
  ],
  courses: [
    {
      title: "Aws Certification",
      autorized: "Amazon",
    },
  ],
  project: [
    {
      name: "Holo Net",
      time: "Sep 2021 - Oct 2021       ",
      ref: ["https://devpost.com/software/holonet"],
      desc: "Air quality and temperature sensors are used to detect wildfire ReactJS is used to design the website that allows users to enter their information for disaster-alert subscription Cassandra and AstraDB are used to store the user's data",
    },
  ],
  skills: [
    {
      title: "UI & UX Design",
      level: "Beginner",
      experince: "2",
    },
    {
      title: "Java",
      level: "Beginner",
      experince: "3",
    },
    {
      title: "React Js",
      level: "Beginner",
      experince: "5",
    },
    {
      title: "Node js",
      level: "Beginner",
      experince: "1",
    },
    {
      title: "SQL",
      level: "Beginner",
      experince: "0",
    },
    {
      title: "Heroku",
      level: "Beginner",
      experince: "0",
    },
  ],
  portfolio: [
    {
      title: "Blog link",
      link: "https://shrikrushanapotfolio.blogspot.com/",
    },
    {
      title: "GitHub profile",
      link: "https://github.com/shrikrushana9960/",
    },
  ],
  additionalInfo: ["MongoDB test passed on Linkedin among 10% of users"],
};
const dashboard = {
  profileView: {
    total: 23,
    graph: {
      value: 99,
      yaxis: [
        {
          name: "Visits",
          data: [91, 125, 35, 50, 10, 60],
        },
      ],
      xaxis: {
        type: "date",
        categories: ["04/01", "04/05", "04/15", "04/20", "04/25", "04/30"],
      },
    },
  },
  jobCardData: {
    potential: 12,
    matched: 120,
    searchApperance: {
      count: 220,
      percent: 12,
    },
    applied: 20,
  },
  profile_viewers: [
    {
      id: 1,
      image:
        "https://img.freepik.com/premium-photo/brunette-business-woman-with-wavy-long-hair-black-suit_521733-9872.jpg?size=338&ext=jpg&ga=GA1.1.386372595.1698019200&semt=ais",
      name: "John Doe",
      companyName: "ABC Inc.",
      position: "Developer",
    },
    {
      id: 2,
      image:
        "https://img.freepik.com/premium-photo/portrait-young-businesswoman-working-modern-office-created-with-generative-ai_762026-23096.jpg",
      name: "Jane Smith",
      companyName: "XYZ Corp.",
      position: "Designer",
    },
    {
      id: 3,
      image:
        "https://img.freepik.com/premium-photo/portrait-young-businesswoman-working-modern-office-created-with-generative-ai_762026-23100.jpg",
      name: "Bob Johnson",
      companyName: "123 Industries",
      position: "Manager",
    },
  ],
  jobs: {
    applied: [
      {
        id: 1,
        image:
          "https://images.tech.co/wp-content/uploads/2019/12/13052618/Tech-co-Logo-.png",
        jobTitle: "Software Engineer",
        location: "Bangalore, India",
        companyName: "TechCo Inc.",
        ctc: "₹ 12 LPA to ₹ 15 LPA",
        status: "Applied",
        statusUpadeted: "10-03-2022",
      },

      {
        id: 4,
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/9/95/Instagram_logo_2022.svg/1200px-Instagram_logo_2022.svg.png",
        jobTitle: "UX/UI Designer",
        location: "Delhi, India",
        companyName: "Instagram",
        ctc: "₹ 8 LPA to ₹ 10 LPA",
        status: "Applied",
        statusUpadeted: "01-01-2022",
      },
      {
        id: 5,
        image:
          "https://yt3.googleusercontent.com/AAnXC4o1n8BKDsO5l6Uc71rf7WOJjm2-aUHzkvyp9vGYB5F4UtXWTecVzvPOBCFK0bNYsZlD7Hk=s900-c-k-c0x00ffffff-no-rj",
        jobTitle: "Data Scientist",
        location: "Mumbai, India",
        companyName: "Meta",
        ctc: "₹ 10 LPA to ₹ 13 LPA",
        status: "Applied",
        statusUpadeted: "03-01-2022",
      },
      {
        id: 6,
        image:
          "https://yt3.googleusercontent.com/AAnXC4o1n8BKDsO5l6Uc71rf7WOJjm2-aUHzkvyp9vGYB5F4UtXWTecVzvPOBCFK0bNYsZlD7Hk=s900-c-k-c0x00ffffff-no-rj",
        jobTitle: "Data Scientist",
        location: "Mumbai, India",
        companyName: "Meta",
        ctc: "₹ 10 LPA to ₹ 13 LPA",
        status: "Applied",
        statusUpadeted: "04-01-2022",
      },
    ],
    suggestion: [
      {
        id: 1,
        image:
          "https://images.tech.co/wp-content/uploads/2019/12/13052618/Tech-co-Logo-.png",
        jobTitle: "Software Engineer",
        location: "Bangalore, India",
        companyName: "TechCo Inc.",
        ctc: "₹ 12 LPA to ₹ 15 LPA",
      },
      {
        id: 2,
        image:
          "https://yt3.googleusercontent.com/AAnXC4o1n8BKDsO5l6Uc71rf7WOJjm2-aUHzkvyp9vGYB5F4UtXWTecVzvPOBCFK0bNYsZlD7Hk=s900-c-k-c0x00ffffff-no-rj",
        jobTitle: "Data Scientist",
        location: "Mumbai, India",
        companyName: "Meta",
        ctc: "₹ 10 LPA to ₹ 13 LPA",
      },
      {
        id: 3,
        image:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSCveFxCPxYQCHrpjBfEOipwpiIOUS7tvcVgYHHKOMr_-zo4nq1TCziMg5lV1-1LaNjPF4&usqp=CAU",
        jobTitle: "UX/UI Designer",
        location: "Delhi, India",
        companyName: "Instagram",
        ctc: "₹ 8 LPA to ₹ 10 LPA",
      },
      {
        id: 4,
        image:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSCveFxCPxYQCHrpjBfEOipwpiIOUS7tvcVgYHHKOMr_-zo4nq1TCziMg5lV1-1LaNjPF4&usqp=CAU",
        jobTitle: "UX/UI Designer",
        location: "Delhi, India",
        companyName: "Instagram",
        ctc: "₹ 8 LPA to ₹ 10 LPA",
      },
      {
        id: 5,
        image:
          "https://yt3.googleusercontent.com/AAnXC4o1n8BKDsO5l6Uc71rf7WOJjm2-aUHzkvyp9vGYB5F4UtXWTecVzvPOBCFK0bNYsZlD7Hk=s900-c-k-c0x00ffffff-no-rj",
        jobTitle: "Data Scientist",
        location: "Mumbai, India",
        companyName: "Meta",
        ctc: "₹ 10 LPA to ₹ 13 LPA",
      },
      {
        id: 6,
        image:
          "https://yt3.googleusercontent.com/AAnXC4o1n8BKDsO5l6Uc71rf7WOJjm2-aUHzkvyp9vGYB5F4UtXWTecVzvPOBCFK0bNYsZlD7Hk=s900-c-k-c0x00ffffff-no-rj",
        jobTitle: "Data Scientist",
        location: "Mumbai, India",
        companyName: "Meta",
        ctc: "₹ 10 LPA to ₹ 13 LPA",
      },
    ],
  },
};
const jobs = {
  all_jobs: [
    {
      id: 1,
      image:
        "https://images.tech.co/wp-content/uploads/2019/12/13052618/Tech-co-Logo-.png",
      jobTitle: "Software Engineer",
      location: "Bangalore, India",
      companyName: "TechCo Inc.",
      ctc: "₹ 12 LPA to ₹ 15 LPA",
    },
    {
      id: 2,
      image:
        "https://yt3.googleusercontent.com/AAnXC4o1n8BKDsO5l6Uc71rf7WOJjm2-aUHzkvyp9vGYB5F4UtXWTecVzvPOBCFK0bNYsZlD7Hk=s900-c-k-c0x00ffffff-no-rj",
      jobTitle: "Data Scientist",
      location: "Mumbai, India",
      companyName: "Meta",
      ctc: "₹ 10 LPA to ₹ 13 LPA",
    },
    {
      id: 3,
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSCveFxCPxYQCHrpjBfEOipwpiIOUS7tvcVgYHHKOMr_-zo4nq1TCziMg5lV1-1LaNjPF4&usqp=CAU",
      jobTitle: "UX/UI Designer",
      location: "Delhi, India",
      companyName: "Instagram",
      ctc: "₹ 8 LPA to ₹ 10 LPA",
    },
    {
      id: 4,
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSCveFxCPxYQCHrpjBfEOipwpiIOUS7tvcVgYHHKOMr_-zo4nq1TCziMg5lV1-1LaNjPF4&usqp=CAU",
      jobTitle: "UX/UI Designer",
      location: "Delhi, India",
      companyName: "Instagram",
      ctc: "₹ 8 LPA to ₹ 10 LPA",
    },
    {
      id: 5,
      image:
        "https://yt3.googleusercontent.com/AAnXC4o1n8BKDsO5l6Uc71rf7WOJjm2-aUHzkvyp9vGYB5F4UtXWTecVzvPOBCFK0bNYsZlD7Hk=s900-c-k-c0x00ffffff-no-rj",
      jobTitle: "Data Scientist",
      location: "Mumbai, India",
      companyName: "Meta",
      ctc: "₹ 10 LPA to ₹ 13 LPA",
    },
    {
      id: 6,
      image:
        "https://yt3.googleusercontent.com/AAnXC4o1n8BKDsO5l6Uc71rf7WOJjm2-aUHzkvyp9vGYB5F4UtXWTecVzvPOBCFK0bNYsZlD7Hk=s900-c-k-c0x00ffffff-no-rj",
      jobTitle: "Data Scientist",
      location: "Mumbai, India",
      companyName: "Meta",
      ctc: "₹ 10 LPA to ₹ 13 LPA",
    },
  ],
  jobDetails: {
    title: "Wizklub Educator",
    responsibilities: [
      "Facilitate learning for 6-14-year-old children using company programs",
      "Teach high-order thinking skills",
      "Conduct engaging and interactive sessions",
      "Track student performance and discuss progress with parents",
      "Conduct weekend classes between 3 pm to 8 pm",
    ],
    qualifications: [
      "Proficiency in English (knowledge of Hindi is an added advantage)",
      "Available from 4:00 pm to 8:00 pm, minimum 40 hours/month",
      "Mandatory availability on weekends",
      "Willingness to receive feedback for improvement",
      "Enthusiastic about new-age learning skills",
      "Minimum 1 year of teaching experience (preferred for grades 3 and above)",
      "Minimum 70% in UG/PG, graduate/post-graduate preferred",
      "Good internet connection",
      "Own a laptop/desktop with webcam",
      "Strong comprehension, critical thinking, reasoning, and problem-solving skills",
      "Good communication skills",
      "Candidates from arts, B.Ed, M.A, journalism, mass communication, and MA English can apply",
      "Female candidates required",
    ],
    additionalInfo: [
      "Interview process: HR interview",
      " post-assessment test (score > 6)",
      " role fitment call/screening round",
      " demonstration/final round (laptop mandatory)",
    ],
    skill: [
      "English Proficiency (Spoken)",
      " English Proficiency (Written)",
      "Online Teaching",
      " Teaching",
    ],
    certificate: [
      "English Proficiency (Spoken)",
      " English Proficiency (Written)",
    ],
    ctc: [
      "Fixed component: 80%",
      "Variable component: 10%",
      "Other incentives: 10%",
    ],
  },
  my_jobs: [
    {
      id: 1,
      image:
        "https://images.tech.co/wp-content/uploads/2019/12/13052618/Tech-co-Logo-.png",
      jobTitle: "Software Engineer",
      location: "Bangalore, India",
      companyName: "TechCo Inc.",
      ctc: "₹ 12 LPA to ₹ 15 LPA",
      status: "Applied",
      statusUpadeted: "01-01-2022",
    },

    {
      id: 2,
      image:
        "https://yt3.googleusercontent.com/AAnXC4o1n8BKDsO5l6Uc71rf7WOJjm2-aUHzkvyp9vGYB5F4UtXWTecVzvPOBCFK0bNYsZlD7Hk=s900-c-k-c0x00ffffff-no-rj",
      jobTitle: "Data Scientist",
      location: "Mumbai, India",
      companyName: "Meta",
      ctc: "₹ 10 LPA to ₹ 13 LPA",
      status: "Hired",
      statusUpadeted: "04-01-2022",
    },
    {
      id: 3,
      image:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/9/95/Instagram_logo_2022.svg/1200px-Instagram_logo_2022.svg.png",
      jobTitle: "UX/UI Designer",
      location: "Delhi, India",
      companyName: "Instagram",
      ctc: "₹ 8 LPA to ₹ 10 LPA",
      status: "Short Listed",
      statusUpadeted: "03-01-2022",
    },
    {
      id: 4,
      image:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/9/95/Instagram_logo_2022.svg/1200px-Instagram_logo_2022.svg.png",
      jobTitle: "UX/UI Designer",
      location: "Delhi, India",
      companyName: "Instagram",
      ctc: "₹ 8 LPA to ₹ 10 LPA",
      status: "Interview",
      statusUpadeted: "01-01-2022",
    },
    {
      id: 5,
      image:
        "https://yt3.googleusercontent.com/AAnXC4o1n8BKDsO5l6Uc71rf7WOJjm2-aUHzkvyp9vGYB5F4UtXWTecVzvPOBCFK0bNYsZlD7Hk=s900-c-k-c0x00ffffff-no-rj",
      jobTitle: "Data Scientist",
      location: "Mumbai, India",
      companyName: "Meta",
      ctc: "₹ 10 LPA to ₹ 13 LPA",
      status: "Rejected",
      statusUpadeted: "04-01-2022",
    },
    {
      id: 6,
      image:
        "https://yt3.googleusercontent.com/AAnXC4o1n8BKDsO5l6Uc71rf7WOJjm2-aUHzkvyp9vGYB5F4UtXWTecVzvPOBCFK0bNYsZlD7Hk=s900-c-k-c0x00ffffff-no-rj",
      jobTitle: "Data Scientist",
      location: "Mumbai, India",
      companyName: "Meta",
      ctc: "₹ 10 LPA to ₹ 13 LPA",
      status: "Interview",
      statusUpadeted: "10-01-2022",
    },
  ],
};
const training = {
  courses: [
    {
      name: "The Complete Python Bootcamp From Zero to Hero",
      author: "Jose Portila",
      star: 4.5,
      review: 484705,
      price: 499,
      poster:
        "https://codeop.tech/wp-content/uploads/2021/11/artturi-jalli-g5_rxRjvKmg-unsplash-scaled.jpg",
    },

    {
      name: "JavaScript: The Good Parts",
      author: "Douglas Crockford",
      star: 4.7,
      review: 20563,
      price: 599,
      poster:
        "https://miro.medium.com/v2/resize:fit:1400/1*LyZcwuLWv2FArOumCxobpA.png",
    },
    {
      name: "Machine Learning A-Z™: Hands-On Python & R In Data Science",
      author: "Kirill Eremenko",
      star: 4.8,
      review: 125489,
      price: 799,
      poster:
        "https://miro.medium.com/v2/resize:fit:1358/1*cG6U1qstYDijh9bPL42e-Q.jpeg",
    },
    {
      name: "React - The Complete Guide (incl Hooks, React Router, Redux)",
      author: "Maximilian Schwarzmüller",
      star: 4.6,
      review: 84562,
      price: 699,
      poster:
        "https://bs-uploads.toptal.io/blackfish-uploads/components/blog_post_page/content/cover_image_file/cover_image/1279225/regular_1708x683_0521-react-redux-and-immutablejs-Waldek_Newsletter-993b50f4ae56e9ee6e024a309c23a6c4.png",
    },
    {
      name: "Data Structures and Algorithms: Deep Dive Using Java",
      author: "Raghavendra Dixit",
      star: 4.9,
      review: 35672,
      price: 899,
      poster:
        "https://miro.medium.com/v2/resize:fit:2000/1*2rKGJ6h1regwmfMcty3SLw.png",
    },
    {
      name: "iOS 15 & Swift 5 - The Complete iOS App Development Bootcamp",
      author: "Angela Yu",
      star: 4.7,
      review: 98531,
      price: 899,
      poster: "https://techcrunch.com/wp-content/uploads/2021/09/iOS-15.jpeg",
    },
  ],
  openings: [
    {
      name: "Google",
      jobs: [
        { title: "Full Stack Developer", opening: 10 },
        { title: "Data Scientist", opening: 5 },
      ],
    },
    {
      name: "Microsoft",
      jobs: [
        { title: "Software Engineer", opening: 15 },
        { title: "Cloud Solutions Architect", opening: 8 },
      ],
    },
    {
      name: "Amazon",
      jobs: [
        { title: "Frontend Developer", opening: 7 },
        { title: "Machine Learning Engineer", opening: 12 },
      ],
    },
    {
      name: "Apple",
      jobs: [
        { title: "iOS Developer", opening: 6 },
        { title: "Hardware Engineer", opening: 10 },
      ],
    },
    {
      name: "Facebook",
      jobs: [
        { title: "UI/UX Designer", opening: 8 },
        { title: "Security Analyst", opening: 5 },
      ],
    },
    {
      name: "Tesla",
      jobs: [
        { title: "Autopilot Software Engineer", opening: 3 },
        { title: "Battery Systems Engineer", opening: 7 },
      ],
    },
    {
      name: "Netflix",
      jobs: [
        { title: "Backend Developer", opening: 4 },
        { title: "Content Strategist", opening: 6 },
      ],
    },
    {
      name: "Adobe",
      jobs: [
        { title: "Graphic Designer", opening: 9 },
        { title: "Product Manager", opening: 5 },
      ],
    },
    {
      name: "Uber",
      jobs: [
        { title: "Senior Mobile Developer", opening: 11 },
        { title: "Data Analyst", opening: 6 },
      ],
    },
  ],
  courseDetails: {
    videoUrl: "https://www.youtube.com/watch?v=kqtD5dpn9C8",
    content: [
      {
        title: "Python Introduction",
        time: "2:30",
        status: "completed",
        resource: [
          {
            title: "Github Project",
            link: "https://www.github.com",
          },
          {
            title: "Pycharam Project",
            link: "https://www.github.com",
          },
        ],
      },
      {
        title: "Python Variable",
        time: "10:30",
        status: "pending",
        resource: [
          {
            title: "Geeks for geeks",
            link: "https://www.github.com",
          },
        ],
      },
      {
        title: "Python functions",
        time: "10:30",
        status: "pending",
      },
    ],
    about:
      "Create a fast-paced, competitive multiplayer shooter in Unreal Engine 5 using C++!",
    certificates: [
      {
        title: "Completion of Java",
      },
    ],
    description:
      "Learn all about Unreal Engine multiplayer in this fully comprehensive C++ course. From scratch, we create a complete multiplayer shooter game, capable of creating and joining game sessions and connecting many players across the internet. We'll create our very own custom plugin that can be added to any Unreal Engine project to easily turn it into a multiplayer game. We learn how to optimize our game for the best performance, as competitive shooters require the highest performance possible. We'll implement lag compensation techniques such as client-side prediction and server-side rewind, so the game performs smoothly even in the presence of high lag! We learn how to use the proper game classes for each multiplayer mechanic. Custom match states, warmup/cooldown match timers, Elim announcements, HUD with health, shields, ammo and grenades, and more! We keep all important functionality on the server, to prevent cheating, and learn the proper way to use the Player Controller, Game Mode, Game State, Player State, Character and Game Instance classes. We make assault rifles, pistols, submachine guns, rocket launchers, grenade launchers, sniper rifles and throwing grenades! All functional in multiplayer, optimized for best performance. After this course, you'll be able to create any multiplayer game you want. If you can do a fast-paced shooter well, you can do any game, because fast-paced, competitive games require the best performance possible.",
  },
};

const assessment = {
  questions: [
    {
      image:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/3/3c/Language_region_maps_of_India.svg/1200px-Language_region_maps_of_India.svg.png",
      title: "Flag of India ??",
      options: ["Option A", "Option B", "Option C"],
    },
    {
      title: "What is the result of 2 + 2 in JavaScript?",
      options: ["3", "4", "5"],
    },
    {
      title: "How do you declare a variable in JavaScript?",
      options: ["var", "let", "const"],
    },
    {
      title: "What does 'NaN' stand for in JavaScript?",
      options: ["Not a Number", "New and Neglected", "Neverending Analysis"],
    },
    {
      title: "What does the 'typeof' operator return in JavaScript?",
      options: ["A string indicating the type", "A boolean value", "An object"],
    },
    {
      title: "What is a closure in JavaScript?",
      options: [
        "A combination of a function and the lexical environment within which that function was declared",
        "A type of loop",
        "An event listener",
      ],
    },
  ],
  totalMarks: 100,
  title: "Assement By Youtube.com",
  time: 30,
};
const posts = [
  {
    link: "https://www.youtube.com/watch?v=oYoWISUxke0",
    user: {
      image:
        "https://yt3.googleusercontent.com/vY3uYs71A_JwVcigyd2tVRHwuj05_cYktQSuzRCxta-9VFxHFtKjGrwG9WFi8ijXITBL3CwPQQ=s900-c-k-c0x00ffffff-no-rj",
      name: "Google",
      occupation: "Software Company",
    },
    description:
      "Google LLC is an American multinational technology company focusing on artificial intelligence, online advertising, search engine technology, cloud computing, computer software, quantum computing, e-commerce, and consumer electronics",
  
  },
  {
    photo: "https://www.zdnet.com/a/img/resize/4d812c16bb08710a12b4802db6789c9969ab8137/2020/04/30/120ce3e8-a4c8-4d15-baaf-75335c8da183/istock-1154834209.jpg?auto=webp&width=1280",
    user: {
      image:
        "https://img.freepik.com/premium-vector/blue-social-media-logo_197792-1759.jpg",
      name: "Facebook",
      occupation: "Software Company",
    },
    description:
      "Google LLC is an American multinational technology company focusing on artificial intelligence, online advertising, search engine technology, cloud computing, computer software, quantum computing, e-commerce, and consumer electronics",
  
 
  },{
    photo: "https://www.researchgate.net/publication/325170198/figure/fig4/AS:626828611973127@1526458995954/Interactive-space-in-Google-office-Dublin-Source-designboomcom.png",
    user: {
        image: "https://example.com/google-icon.jpg",
        name: "Google",
        occupation: "Technology Company",
    },
    description: "Google LLC is an American multinational technology company focusing on artificial intelligence, online advertising, search engine technology, cloud computing, computer software, quantum computing, e-commerce, and consumer electronics.",
},
{
    photo: "https://cdn.mos.cms.futurecdn.net/ixRZem5k2gpqhry7koAL4R.jpg",
    user: {
        image: "https://example.com/microsoft-icon.jpg",
        name: "Microsoft",
        occupation: "Technology Company",
    },
    description: "Microsoft Corporation is a leading global technology company that develops, licenses, and sells computer software, consumer electronics, and other personal computing and communications products and services.",
},
{
    photo: "https://storage.googleapis.com/gweb-uniblog-publish-prod/images/1_howzit_google_south_africa.width-500.format-webp.webp",
    user: {
        image: "https://example.com/apple-icon.jpg",
        name: "Apple",
        occupation: "Technology Company",
    },
    description: "Apple Inc. is an American multinational technology company that designs, manufactures, and markets consumer electronics, computer software, and online services.",
},
{
    photo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRUrLUULFqnTsbynCJLtm01dBIWiNW_tzwGDet40cxYd7bBNRBpIY1GMFlw9GY7jVfHs1Y&usqp=CAU",
    user: {
        image: "https://example.com/amazon-icon.jpg",
        name: "Amazon",
        occupation: "E-commerce Company",
    },
    description: "Amazon.com, Inc. is an American multinational technology and e-commerce company that focuses on e-commerce, cloud computing, digital streaming, artificial intelligence, and more.",
},
{
    photo: "https://i.insider.com/57e03173b0ef97011d8b672a?width=1000&format=jpeg&auto=webp",
    user: {
        image: "https://example.com/twitter-icon.jpg",
        name: "Twitter",
        occupation: "Social Media Company",
    },
    description: "Twitter is an American microblogging and social networking service where users post and interact with messages known as 'tweets'.",
},
{
    photo: "https://i.insider.com/57e04c2bb0ef973f1b8b694c?width=1000&format=jpeg&auto=webp",
    user: {
        image: "https://example.com/ibm-icon.jpg",
        name: "IBM",
        occupation: "Technology and Consulting Company",
    },
    description: "International Business Machines Corporation (IBM) is an American multinational technology and consulting company.",
},
{
    photo: "https://www.chairoffice.co.uk/media/4972/dublin-2.jpeg",
    user: {
        image: "https://example.com/netflix-icon.jpg",
        name: "Netflix",
        occupation: "Entertainment Company",
    },
    description: "Netflix, Inc. is an American subscription-based streaming service offering a wide variety of TV shows, movies, documentaries, and more.",
},
];

module.exports.Student = {
  profile,
  resumeDetails,
  dashboard,
  jobs,
  training,
  assessment,
  posts,
};
