import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { blue, green, orange, red } from '@mui/material/colors';
import { Button } from '@mui/material';

const applicationData = [
    {
        id: 1,
        company: "ABC Inc.",
        profile: "Software Engineer",
        applied_on: "2023-08-01",
        no_of_application: 10,
        status: "In Progress",
    },
    {
        id: 2,
        company: "XYZ Corp.",
        profile: "Product Manager",
        applied_on: "2023-07-15",
        no_of_application: 5,
        status: "Rejected",
    },
    {
        id: 3,
        company: "123 Tech",
        profile: "Data Scientist",
        applied_on: "2023-08-10",
        no_of_application: 8,
        status: "Reviewed",
    },
    {
        id: 4,
        company: "Acme Industries",
        profile: "Marketing Specialist",
        applied_on: "2023-07-20",
        no_of_application: 3,
        status: "Shortlisted",
    },
    {
        id: 5,
        company: "Global Solutions",
        profile: "Sales Representative",
        applied_on: "2023-08-05",
        no_of_application: 12,
        status: "In Progress",
    },
    {
        id: 6,
        company: "New Horizons",
        profile: "Graphic Designer",
        applied_on: "2023-08-15",
        no_of_application: 6,
        status: "Pending",
    },
];



const columns = [
    { field: 'company', headerName: 'COMPANY', flex: 1 },
    { field: 'profile', headerName: 'PROFILE', flex: 1 },
    { field: 'applied_on', headerName: 'APPLIED ON', flex: 1 },
    {
        field: 'no_of_application',
        headerName: 'NUMBER OF APPLICATIONS',

        flex: 1
    },
    {
        field: 'status',
        headerName: 'APPLICATION STATUS',

        flex: 1,
        renderCell: (params) => {
            let color;
      
            switch (params.value) {
              case 'In Progress':
                color = orange[500];
                break;
              case 'Accepted':
                color = green[500];
                break;
              case 'Rejected':
                color = red[500];
                break;
                case 'Shortlisted':
                color = green[500];
                break;
                case 'Reviewed':
                    color = blue[500];
                    break;
              default:
                color = 'inherit';
                break;
            }
      
            return (
              <div style={{ color }}>{params.value}</div>
            );
          },
    },
    {
        field: 'action',
        headerName: 'REVIEW APPLICATION	',

        flex: 1,
        renderCell: (params) => {
            let color;
      
            switch (params.value) {
              case 'In Progress':
                color = orange[500];
                break;
              case 'Accepted':
                color = green[500];
                break;
              case 'Rejected':
                color = red[500];
                break;
                case 'Shortlisted':
                color = green[500];
                break;
                case 'Reviewed':
                    color = blue[500];
                    break;
              default:
                color = 'inherit';
                break;
            }
      
            return (
                <Button
                variant="contained"
                sx={{
                  margin: "5px",
                
                  width: 80,
                  background: "black",
                  fontWeight: "bold",
                  fontFamily: "Quicksand",
                  textTransform: "none",
                }}
              >
                Apply
              </Button>
            );
          },
    },
];

const rows = [
    { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
    { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
    { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
    { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
    { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
    { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
    { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
    { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
    { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
];

export default function TableComponents() {
    return (
        <div style={{ width: '100%' }}>
            <DataGrid
                rows={applicationData}
                columns={columns}

                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                    },
                }}
                pageSizeOptions={[5, 10]}

            />
        </div>
    );
}