import React from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import HeaderText from "../HeaderText";

const BreadCumb = ({ data }) => {

  return (
    <div style={{ display: "flex",marginTop:10 }}>
      {data.map((item, index) => (
        <div style={{ marginRight: 10, display: "flex",alignItems:"center" }}>
          {item?.icon}
          <HeaderText style={{ fontSize: 14, marginRight: 10,color:index != data.length - 1?"#0049FC":"grey" }}>
            {item.name}
          </HeaderText>
          {index != data.length - 1 && <NavigateNextIcon />}
        </div>
      ))}
    </div>
  );
};

export default BreadCumb;