import logo from "./logo.svg";
import "./App.css";
import Layout from "./components/Layout/Layout";
import { createTheme } from "@mui/material/styles";
import JobDetails from "./Pages/JobDetails";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";
import Home from "./Pages/Home";
import Resume from "./Pages/Resume";
import MyApplication from "./Pages/MyApplication";
import Application from "./Pages/Application";
import Explore from "./Pages/Explore";
import Register from "./Pages/Register";
import Chat from "./Pages/Chat";
import Company from "./Pages/Company/Home";
import JobListing from "./Pages/Company/JobListing";
import Dashboard from "./Pages/Company/Dashboard";
import CollegeView from "./Pages/College/CollegeView";
import CollegeDashboard from "./Pages/College/CollegeView";
import StudentView from "./Pages/College/StudentView";
import Login from "./Pages/Login";
import Timelines from "./Pages/Timelines";
import StudentDashboard from "./Layout/StudentDashboard";
import Jobs from "./Pages/Jobs";
import Profile from "./Pages/Profile";
import JobForMe from "./Pages/JobsForMe";
import MyJobs from "./Pages/MyJobs";
import Assesment from "./Pages/Assesment";
import ApplicationDetails from "./Pages/ApplicationDetails";
import CompanyProfile from "./Pages/Profile/CompanyProfile";
import CollageViews from "./Pages/College/CollegeView";
import InactiveListing from "./Pages/Company/InactiveListing";
import PlacmentDrives from "./Pages/College/PlacmentDrives";
import JobsCollege from "./Pages/JobsCollege";
import CollegeProfile from "./Pages/Profile/CollegeProfile";
import ChatMobile from "./Pages/ChatMobile";
import JobListingInfo from "./Pages/Company/JobListingInfo";
import MobileListingInfo from "./Pages/Company/mobileListing";
import PlacmentDrivesDetails from "./Pages/College/PlacmentDriveDetails";
import JobPosting from "./Pages/Post/JobPosting";
import StudentCourses from "./Pages/Courses/StudentCourses";
import CourseDetails from "./Pages/Courses/CourseDetails/CourseDetails";
import CompanyCourse from "./Pages/Courses/CompanyCourse";
import CollegeCourses from "./Pages/Courses/CollegeCourse";
import CompanyCourseDetails from "./Pages/Courses/CourseDetails/CompanyCourseDetails";
import CompanyTimelines from "./Pages/CompanyTimeLine";
import AddCourse from "./Pages/AddCourse";
import CourseDashboard from "./Pages/Courses/CourseDetails/CourseDashboard";
import CandiadateApplication from "./Pages/CandidateApplication";
import InviteMembers from "./Pages/Company/InviteMembers";
import StudentProfile from "./Pages/Profile/StudentProfile";
import AddChapter from "./Pages/AddCourse/AddChpater";
import AddStudent from "./Pages/AddStudent";
import EditProfile from "./Pages/editProfile";
import EditCompanyProfile from "./Pages/editCompanyProfile";
import AddContent from "./Pages/AddCourse/AddContent";
import AddProfile from "./Pages/editProfile/addProfile";
let theme = createTheme({
  palette: {
    primary: {
      main: "#02bdde",
    },
    secondary: {
      main: "#edf2ff",
    },
  },
});

theme = createTheme(theme, {
  palette: {
    info: {
      main: theme.palette.secondary.main,
    },
  },
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/student",
    element: <Home />,
  },

  {
    path: "/assesment",
    element: <Assesment />,
  },
  {
    path: "/ApplicationDetails",
    element: <ApplicationDetails />,
  },
  {
    path: "jobs/job_for_me",
    element: <JobForMe />,
  },
  {
    path: "JobDetails/:id",
    element: <JobDetails />,
  },
  {
    path: "jobs/all_jobs",
    element: <Jobs />,
  },
  {
    path: "jobs/my_jobs",
    element: <MyJobs />,
  },
  {
    path: "profile",
    element: <Profile />,
  },
  {
    path: "Resume",
    element: <Resume />,
  },
  {
    path: "training",
    element: <StudentCourses />,
  },
  {
    path: "addchapter/:id",
    element: <AddChapter />,
  },
  {
    path: "addStudent",
    element: <AddStudent />,
  },
  {
    path: "editProfile",
    element: <EditProfile />,
  },
  {
    path: "addProfile",
    element: <AddProfile />,
  },
  {
    path: "editCompanyProfile",
    element: <EditCompanyProfile />,
  },
  {
    path: "training/:id",
    element: <CourseDetails />,
  },
  {
    path: "courseDashboard/:id",
    element: <CourseDashboard />,
  },
  {
    path: "companyCourses/courses",
    element: <CompanyCourse />,
  },
  {
    path: "companyCourses/:id",
    element: <CompanyCourseDetails />,
  },
  {
    path: "collegeCourses/courses",
    element: <CollegeCourses />,
  },
  {
    path: "collegeCourses/:id",
    element: <CompanyCourseDetails />,
  },
  {
    path: "collegeCourses/courses",
    element: <CollegeCourses />,
  },
  {
    path: "Register",
    element: <Register />,
  },
  {
    path: "MyApplication",
    element: <MyApplication />,
  },
  {
    path: "Application",
    element: <Application />,
  },
  {
    path: "Explore",
    element: <Explore />,
  },
  {
    path: "Joblisting/active_listing",
    element: <JobListing />,
  },
  {
    path: "candidate/:id",
    element: <CandiadateApplication />,
  },
  {
    path: "Joblisting/active_listing/:id",
    element: <JobListingInfo />,
  },
  {
    path: "Joblisting/mobileListingInfo/:id",
    element: <MobileListingInfo />,
  },
  {
    path: "company/userMangment",
    element: <InviteMembers />,
  },
  {
    path: "CollegeDashboard/studentProfile",
    element: <StudentProfile />,
  },
  {
    path: "Joblisting/job",
    element: <JobPosting />,
  },
  {
    path: "companyCourses/add",
    element: <AddCourse />,
  },
  {
    path: "companyCourses/content",
    element: <AddContent />,
  },
  {
    path: "collegeCourses/add",
    element: <AddCourse />,
  },
  {
    path: "Joblisting/inactive_listing",
    element: <InactiveListing />,
  },
  {
    path: "Company",
    element: <Company />,
  },
  {
    path: "CompanyProfile",
    element: <CompanyProfile />,
  },
  {
    path: "CollegeProfile",
    element: <CollegeProfile />,
  },
  {
    path: "Chat",
    element: <Chat />,
  },
  {
    path: "Chat/:id",
    element: <ChatMobile />,
  },
  {
    path: "Dashboard",
    element: <Dashboard />,
  },

  {
    path: "CollegeDashboard",
    element: <CollageViews />,
  },
  {
    path: "college/placment_drives",
    element: <PlacmentDrives />,
  },
  {
    path: "college/placment_drives/:id",
    element: <PlacmentDrivesDetails />,
  },
  {
    path: "college/job_opening",
    element: <JobsCollege />,
  },
  {
    path: "StudentView",
    element: <StudentView />,
  },
  {
    path: "Login",
    element: <Login />,
  },
  {
    path: "Timelines",
    element: <Timelines />,
  },
  {
    path: "companyTimeline",
    element: <CompanyTimelines />,
  },
]);
function App() {
  return <RouterProvider router={router} />;
}

export default App;
