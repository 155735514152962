import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import { Avatar, Button, Grid } from "@mui/material";
import HeaderText from "../../components/HeaderText";
import CardWrapper from "../../components/Card/CardWrapper";
import Header from "../../components/Header/Header";
import CompanyDashboard from "../../Layout/CompanyDashboard";
import { colors } from "../../constants/colors";
import { useNavigate } from "react-router-dom";
import GraphCard from "../../components/Card/GraphCard";
import { getCompanyDashboard } from "../../ApiCalls";

const Dashboard = () => {
  const navigate = useNavigate();
  const [dashboard, setDashboard] = useState();
  const hiringTasks = [
    {
      task: "Review resumes of Full Stack",
      assignedTo: "Hiring Manager",
      status: "Pending",
    },
    {
      task: "Conduct initial phone screens",
      assignedTo: "Recruiter",
      status: "Pending",
    },
    {
      task: "Schedule on-site interviews with Backend Developers",
      assignedTo: "Coordinator",
      status: "Pending",
    },
    {
      task: "Conduct technical assessments of Software Tester",
      assignedTo: "Technical Interviewer",
      status: "Pending",
    },
    {
      task: "Check references",
      assignedTo: "Hiring Manager",
      status: "Pending",
    },
    {
      task: "Make job offer",
      assignedTo: "Hiring Manager",
      status: "Pending",
    },
    {
      task: "Prepare onboarding plan",
      assignedTo: "HR",
      status: "Pending",
    },
    {
      task: "Hired Full Stack Developer",
      assignedTo: "Hiring Manager",
      status: "Completed",
      date: "2023-11-01",
    },
  ];
  useEffect(() => {
    const result = getCompanyDashboard();
    setDashboard(result);
  }, []);
  return (
    <Layout>
      {dashboard && (
        <div>
          <Grid container spacing={[0, 2]} style={{ marginTop: "10px" }}>
            <Grid item xs={6}>
              <GraphCard
                title={"Hired Candiadates"}
                value={"190"}
                graphData={dashboard.hired}
              />
            </Grid>
            <Grid item xs={6}>
              <GraphCard
                title={"Job posted"}
                value={"89"}
                type={"bar"}
                graphData={dashboard.job_posted}
              />
            </Grid>
          </Grid>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Grid container spacing={[0, 1]}>
              <Grid item xs={12} sm={6}>
                {" "}
                <CardWrapper style={{ flex: 1, marginRight: 5 }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <HeaderText style={{ fontSize: 16 }}>Applicants</HeaderText>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      style={{ flex: 1, textAlign: "center", marginTop: 20 }}
                    >
                      <HeaderText>
                        {dashboard.cards.applicant.applicant || 0}
                      </HeaderText>
                      <HeaderText style={{ fontWeight: "100", fontSize: 14 }}>
                        applicants
                      </HeaderText>
                    </div>
                    <div
                      style={{ flex: 1, textAlign: "center", marginTop: 20 }}
                    >
                      <HeaderText>
                        {dashboard.cards.applicant.matches}
                      </HeaderText>
                      <HeaderText style={{ fontWeight: "100", fontSize: 14 }}>
                        matches
                      </HeaderText>
                    </div>
                    <div
                      style={{ flex: 1, textAlign: "center", marginTop: 20 }}
                    >
                      <HeaderText>
                        {dashboard.cards.applicant.message}
                      </HeaderText>
                      <HeaderText style={{ fontWeight: "100", fontSize: 14 }}>
                        messages
                      </HeaderText>
                    </div>
                  </div>
                </CardWrapper>
              </Grid>
              <Grid item xs={12} sm={6}>
                {" "}
                <CardWrapper style={{ flex: 1, marginRight: 5 }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <HeaderText style={{ fontSize: 16 }}>Sourcing</HeaderText>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      style={{ flex: 1, textAlign: "center", marginTop: 20 }}
                    >
                      <HeaderText>{dashboard.cards.sourcing.views}</HeaderText>
                      <HeaderText style={{ fontWeight: "100", fontSize: 14 }}>
                        views
                      </HeaderText>
                    </div>
                    <div
                      style={{ flex: 1, textAlign: "center", marginTop: 20 }}
                    >
                      <HeaderText>
                        {dashboard.cards.sourcing.pitches}
                      </HeaderText>
                      <HeaderText style={{ fontWeight: "100", fontSize: 14 }}>
                        pitches
                      </HeaderText>
                    </div>
                    <div
                      style={{ flex: 1, textAlign: "center", marginTop: 20 }}
                    >
                      <HeaderText>562</HeaderText>
                      <HeaderText style={{ fontWeight: "100", fontSize: 14 }}>
                        {dashboard.cards.sourcing.matches}
                      </HeaderText>
                    </div>
                  </div>
                </CardWrapper>{" "}
              </Grid>
            </Grid>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Grid container spacing={[0, 2]}>
              <Grid item xs={12} sm={4}>
                {" "}
                <CardWrapper style={{ marginTop: 20, flex: 1, marginRight: 5 }}>
                  <img
                    src="https://img.freepik.com/premium-vector/digital-security-access-illustration-concept_644411-40.jpg"
                    style={{ height: 300, width: "100%", objectFit: "cover" }}
                  />
                  <HeaderText style={{ fontSize: 16 }}>
                    Find top-quality talent for your startup
                  </HeaderText>
                  <HeaderText style={{ fontWeight: "100", fontSize: 14 }}>
                    Unique filters help cut through the noise by looking for the
                    signals that truly matter to your team.
                  </HeaderText>
                  <Button
                    onClick={() => navigate("/Joblisting/job")}
                    fullWidth
                    variant="contained"
                    sx={{
                      margin: "5px",
                      marginLeft: "auto",
                      marginTop: "10px",
                      background: colors.primary,
                      borderRadius: "100px",
                      fontFamily: "Outfit",
                      textTransform: "none",
                    }}
                  >
                    Source talent
                  </Button>
                </CardWrapper>
              </Grid>
              <Grid item xs={12} sm={4}>
                {" "}
                <CardWrapper style={{ flex: 1, marginTop: 20, marginRight: 5 }}>
                  <img
                    src="https://img.freepik.com/premium-vector/blockchain-supply-chain-illustration-concept_644411-25.jpg?w=2000"
                    style={{ height: 300, width: "100%", objectFit: "cover" }}
                  />
                  <HeaderText style={{ fontSize: 16 }}>
                    Fast-track your workflow with pitch templates
                  </HeaderText>
                  <HeaderText style={{ fontWeight: "100", fontSize: 14 }}>
                    Set up, save and reuse your pitch templates to reduce work
                    and increase candidate outreach.
                  </HeaderText>
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{
                      margin: "5px",
                      marginLeft: "auto",
                      marginTop: "10px",
                      background: colors.primary,
                      borderRadius: "100px",
                      fontFamily: "Outfit",
                      textTransform: "none",
                    }}
                  >
                    Create Teamplate
                  </Button>
                </CardWrapper>
              </Grid>
              <Grid item xs={12} sm={4}>
                {" "}
                <CardWrapper style={{ marginTop: 20, flex: 1, marginRight: 5 }}>
                  <img
                    src="https://img.freepik.com/premium-vector/business-data-analysis_118813-8879.jpg"
                    style={{ height: 300, width: "100%", objectFit: "cover" }}
                  />
                  <HeaderText style={{ fontSize: 16 }}>
                    Collaborate with your team more efficiently
                  </HeaderText>
                  <HeaderText style={{ fontWeight: "100", fontSize: 14 }}>
                    Bookmark candidates, leave notes, and share with your team
                    to speed up your hiring process.
                  </HeaderText>
                  <Button
                    onClick={() => navigate("/company/userMangment")}
                    fullWidth
                    variant="contained"
                    sx={{
                      margin: "5px",
                      marginLeft: "auto",
                      marginTop: "10px",
                      background: colors.primary,
                      borderRadius: "100px",
                      fontFamily: "Outfit",
                      textTransform: "none",
                    }}
                  >
                    Invite Team Members
                  </Button>
                </CardWrapper>
              </Grid>
            </Grid>
          </div>
          <div style={{ marginTop: 20 }}>
            <HeaderText>Activity Feed</HeaderText>
            <HeaderText
              style={{ fontSize: 12, color: "grey", marginBottom: "20px" }}
            >
              Here's a reminder of what you and your team have been up to
              recently
            </HeaderText>
            {dashboard.tasks.map((item) => (
              <CardWrapper
                className={"maincontainer"}
                style={{
                  marginBottom: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <HeaderText style={{ fontSize: 12, color: "black", flex: 1 }}>
                  {item.task}
                </HeaderText>
                <HeaderText style={{ fontSize: 12, color: "black", flex: 1 }}>
                  {item.assignedTo}
                </HeaderText>
                <HeaderText
                  style={{
                    fontSize: 12,
                    flex: 1,
                    color:
                      item.status == "Pending"
                        ? "red"
                        : item.status == "Completed"
                        ? "green"
                        : "black",
                  }}
                >
                  {item.status}
                </HeaderText>
              </CardWrapper>
            ))}
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Dashboard;
