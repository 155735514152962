import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import CardWrapper from "../../components/Card/CardWrapper";
import HeaderText from "../../components/HeaderText";
import { Avatar, Box, Button, Divider, Grid, Tab, Tabs } from "@mui/material";
import ApplicantCard from "../../components/Applicantcard";
import StudentDashboard from "../../Layout/StudentDashboard";
import { colors } from "../../constants/colors";
import { useNavigate } from "react-router-dom";
import { getJobLisiting } from "../../ApiCalls";

const JobListing = () => {
  const [value, setValue] = React.useState("applied");
  const [jobListing, setJobListing] = useState();
  const navigate = useNavigate();
  const [application,setApplication]=useState([]);
  const handleChange = (event, newValue) => {
    console.log(newValue);
    setValue(newValue);
  };
  let Students = [
    {
      image:
        "https://www.corporatephotographylondon.com/wp-content/uploads/2019/11/HKstrategies-1210-1024x683.jpg",
      name: "John Smith",
      age: 18,
      grade: 85,
    },
    {
      image:
        "https://www.corporatephotographylondon.com/wp-content/uploads/2019/11/HKstrategies-755-1-1024x683.jpg",
      name: "Alice Johnson",
      age: 17,
      grade: 92,
    },
    {
      image:
        "https://media.istockphoto.com/id/1329936184/photo/head-shot-portrait-of-smiling-businesswoman-intern-looking-at-camera.jpg?s=612x612&w=0&k=20&c=cCeWt0GYUtcFsGFi6hTFci-d5KZY6FAd_UchY9V5aSo=",
      name: "Bob Brown",
      age: 19,
      grade: 78,
    },
    {
      image:
        "https://media.istockphoto.com/id/1303206558/photo/headshot-portrait-of-smiling-businessman-talk-on-video-call.jpg?s=612x612&w=0&k=20&c=hMJhVHKeTIznZgOKhtlPQEdZqb0lJ5Nekz1A9f8sPV8=",
      name: "Emily Davis",
      age: 20,
      grade: 88,
    },
    {
      image:
        "https://media.istockphoto.com/id/1309328823/photo/headshot-portrait-of-smiling-male-employee-in-office.jpg?s=612x612&w=0&k=20&c=kPvoBm6qCYzQXMAn9JUtqLREXe9-PlZyMl9i-ibaVuY=",
      name: "David Wilson",
      age: 18,
      grade: 76,
    },
    {
      image:
        "https://www.corporatephotographylondon.com/wp-content/uploads/2019/11/HKstrategies-1029-1024x683.jpg",
      name: "Sarah Lee",
      age: 19,
      grade: 95,
    },
    {
      image:
        "https://www.corporatephotographylondon.com/wp-content/uploads/2019/11/Option-3-1024x683.jpg",
      name: "Michael Jones",
      age: 17,
      grade: 89,
    },
    {
      image:
        "https://img.freepik.com/free-photo/portrait-optimistic-businessman-formalwear_1262-3600.jpg?size=626&ext=jpg&ga=GA1.1.1413502914.1696982400&semt=ais",
      name: "Olivia Martinez",
      age: 20,
      grade: 91,
    },
  ];
  useEffect(() => {
    let result = getJobLisiting();
    setJobListing(result.active);
    setApplication(result.candidates)
  }, []);
  return (
    <Layout>
      <div style={{ marginTop: 20, display: "flex", flexDirection: "row" }}>
        <Grid container>
          <Grid item xs={12} sm={4}>
            <div>
              <CardWrapper>
                <HeaderText style={{ fontFamily: "Outfit" }}>
                  Applicants
                </HeaderText>
                {jobListing?.map((item) => (
                  <div
                    style={{ marginTop: 20 }}
                    onClick={handleChange}
                  >
                    <HeaderText style={{ fontSize: 10, color: "grey" }}>
                      {item.applicant} Applicants
                    </HeaderText>
                    <HeaderText style={{ fontSize: 14 }}>
                      {item.title}
                    </HeaderText>
                    <HeaderText style={{ fontSize: 12, color: colors.primary }}>
                      {item.location}
                    </HeaderText>
                    <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                  </div>
                ))}
              </CardWrapper>
            </div>
          </Grid>
          <Grid item xs={12} sm={8}>
            <div
              style={{ flex: 1, marginLeft: 20 }}
              className="displayContainer"
            >
              <CardWrapper
                style={{ flex: 1, height: 600, overflow: "scroll" }}
                className="example"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <HeaderText style={{ fontFamily: "Outfit" }}>
                      Full Stack Devloper
                    </HeaderText>{" "}
                    <HeaderText style={{ fontSize: 14, color: "grey" }}>
                      Pune
                    </HeaderText>
                  </div>
                  <Button
                    variant="contained"
                    sx={{
                      margin: "5px",
                      marginLeft: "auto",
                      width: 80,

                      borderRadius: "100px",
                      background: colors.primary,
                      // fontWeight: "bold",
                      fontFamily: "Outfit",
                      textTransform: "none",
                    }}
                  >
                    Edit
                  </Button>
                </div>

                <Box sx={{ width: "100%" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="wrapped label tabs example"
                  >
                    <Tab value="applied" label="Needs Review" />

                    <Tab value="rejected" label="Rejected" />
                    <Tab value="saved" label="Saved For Later" />
                  </Tabs>
                </Box>
                {application?.filter(item=>item.status==value).map((item) => (
                  <CardWrapper
                    style={{
                      marginBottom: 10,
                      padding: 10,
                      flexDirection: "row",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Avatar src={item.image}>{item.name.split("")[0]}</Avatar>
                    <div>
                      <HeaderText
                        style={{
                          marginLeft: 20,
                          fontSize: "16px",
                          display: "flex",
                          fontFamily: "Outfit",
                        }}
                      >
                        {item.name}
                      </HeaderText>
                      <HeaderText style={{ marginLeft: 20, fontSize: 10 }}>
                       {item.grade}% score in Assement
                      </HeaderText>
                    </div>
                    <Button
                      onClick={() => navigate("/Joblisting/active_listing/1")}
                      variant="contained"
                      sx={{
                        margin: "5px",
                        marginLeft: "auto",
                        marginTop: "10px",
                        background: colors.primary,
                        // fontWeight: "bold",
                        fontFamily: "Outfit",
                        borderRadius: "100px",
                        textTransform: "none",
                      }}
                    >
                      View
                    </Button>
                  </CardWrapper>
                ))}
              </CardWrapper>
            </div>
          </Grid>
        </Grid>
      </div>
    </Layout>
  );
};

export default JobListing;
