import React, { useEffect } from "react";
import Layout from "../../../components/Layout/Layout";
import { Book, BookOnline } from "@mui/icons-material";
import BreadCumb from "../../../components/BradCumb";
import { Button, Divider, Grid, LinearProgress } from "@mui/material";
import CardWrapper from "../../../components/Card/CardWrapper";
import HeaderText from "../../../components/HeaderText";
import CourseContentCard from "./CourseContentCard";
import { colors } from "../../../constants/colors";
import { useLocation } from "react-router-dom";
import GraphCard from "../../../components/Card/GraphCard";

const CourseDashboard = () => {
  function convertToEmbedLink(youtubeLink) {
    return youtubeLink.replace(/watch\?v=/, "embed/");
  }
  const location = useLocation();
  let course = {
    link: "https://www.youtube.com/watch?v=kqtD5dpn9C8",
    content: [
      {
        title: "Python Introduction",
        time: "2:30",
        status:"completed",
        resource: [
          {
            title: "Github Project",
            link: "https://www.github.com",
          },
          {
            title: "Pycharam Project",
            link: "https://www.github.com",
          },
        ],
      },
      {
        title: "Python Variable",
        time: "10:30",
        status:"pending",
        resource: [
          {
            title: "Geeks for geeks",
            link: "https://www.github.com",
          },
        ],
      },
      {
        title: "Python functions",
        time: "10:30",
        status:"pending",
      },
    ],
  };
  const navigationdata = [
    {
      icon: (
        <BookOnline style={{ marginRight: 5, color: "0049FC", fontSize: 18 }} />
      ),
      name: "Courses",
      route: "/",
    },
    {
      name: "The Complete Python Bootcamp From Zero to Hero",
      route: "/all_jobs",
    },
  ];
  useEffect(()=>{
    console.log(new URLSearchParams(location.search))
  },[])
  return (
    <Layout>
      <BreadCumb data={navigationdata} />
      <Grid container spacing={[5, 5]}>
        <Grid item xs={8}>
          <iframe
          allowFullScreen
            style={{
              width: "100%",
              borderRadius: 10,
              marginTop: "10px",
              overflow: "hidden",
              border: "0px solid black",
            }}
            height="500"
            src={convertToEmbedLink(
              "https://www.youtube.com/watch?v=kqtD5dpn9C8"
            )}
          ></iframe>

<GraphCard title={"Course subscriptions"} value={""}  />
        </Grid>
        <Grid item xs={4}>
          <CardWrapper style={{ marginTop: "10px" }}>
            <HeaderText style={{ fontFamily: "Outfit", fontSize: "16px" }}>
              Course Content
            </HeaderText>
            {course.content.map((item) => (
              <CourseContentCard item={item} />
            ))}
          </CardWrapper>
          <GraphCard title={"People Reached"} value={"89"} type={"bar"} />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default CourseDashboard;
