import React from "react";
import HeaderText from "../../components/HeaderText";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import SubMenu from "./SubMenu";
import { Lock } from "@mui/icons-material";

const Menu = ({ items }) => {
  const navigate=useNavigate()
  return (
    <div style={{ padding: 10 }}>
      {items.map((item, index) => (
        <SubMenu route={""} item={item} index={index} />
      ))}
      <SubMenu route={""} item={{
      name: "Logout",
      onClick:()=>{navigate("/")},
      icon: <Lock />,
    }}  />
    </div>
  );
};

export default Menu;
