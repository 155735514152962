import React, { useEffect, useState } from "react";
import StudentDashboard from "../../Layout/StudentDashboard";
import Alljobs from "../../components/AllJobs";
import JobDetailsCard from "../../components/Card/JobDetailsCard";
import { Grid } from "@mui/material";
import BreadCumb from "../../components/BradCumb";
import rawdata from "../../components/RawData/jobs.json"
import WorkIcon from "@mui/icons-material/Work";
import Layout from "../../components/Layout/Layout";
import { useNavigate, useParams } from "react-router-dom";
import { getJobDetails, getStudentAllJobs } from "../../ApiCalls";
const Jobs = () => {
  const [jobListing,setJobListing]=useState([]);
  const [job,setJob]=useState([])
  const navigate=useNavigate()
  const [jobDetails,setJobDetails]=useState({});
  const params=useParams()

 
  
  const navigationdata = [
    {
      icon: (
        <WorkIcon style={{ marginRight: 5, color: "0049FC", fontSize: 18 }} />
      ),
      name: "Jobs",
      route: "/",
    },
    {
      name: "All Jobs",
      route: "/all_jobs",
    },
  ];

  useEffect(()=>{
    let result=getStudentAllJobs();
    setJobListing(result)
    setJob(result[0]);
    let result2=getJobDetails()
    setJobDetails(result2)
  },[])
  function handleClick(id) {
    if (window.innerWidth < 600) {
      navigate("/jobDetails/"+id)
    } else {
       setJob(jobListing.filter(item=>item.id==id)[0])
    }
}


  return (
    <Layout>
      <BreadCumb data={navigationdata} />
      <Grid container style={{ display: "flex", marginTop: 10 }}>
        <Grid item xs={12} sm={3} style={{ marginTop: 20 }}>
          {jobListing&&<Alljobs
          onClick={handleClick}
            jobListing={jobListing}
            title={"All Jobs"}
            button={"filters"}
            showAll
          />}
        </Grid>
        <Grid xs={12} sm={8} sx={{ marginLeft: "20px" }}>
          <div className="displayContainer">
         {jobDetails&& <JobDetailsCard jobDetails={jobDetails} job={job} />}
          </div>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Jobs;
