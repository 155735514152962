import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";
import { Button, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import HeaderText from "../../components/HeaderText";
import { colors } from "../../constants/colors";
import CardWrapper from "../../components/Card/CardWrapper";
import JobsTemplate from "../../components/JobTemplate";
import FormLabel from "../../components/formLabel";

const JobPosting = () => {
  const [age, setAge] = useState(0);
  const [title, setJobTitle] = useState();
  const [workplaceType, setWorkPlaceType] = useState();
  const [description, setDescription] = useState();
  const [minSalary, setMinSalary] = useState();
  const [maxSalary, setMaxSalary] = useState();
  const [skills, setSkills] = useState();

  const setTemplate = (item) => {
    setJobTitle(item.title);
    setWorkPlaceType(item.workplaceType);
    setDescription(item.jobDescription);
    setMinSalary(item.minSalary);
    setMaxSalary(item.maxSalary);
    setSkills(item.skills);
  };
  return (
    <Layout>
      <div style={{ display: "flex", width: "100%", flexDirection: "row" }}>
        <div style={{ margin: "auto", display: "flex", flexDirection: "row" }}>
          <CardWrapper
            style={{
              marginTop: "20px",
              padding: "20px",
              maxWidth: "500px",
              width: "500px",
            }}
          >
            <HeaderText
              style={{
                textAlign: "center",
                marginBottom: "20px",
                fontFamily: "Outfit",
              }}
            >
              Post a Job
            </HeaderText>
            <div>
              <FormLabel style={{ marginTop: "20px" }} id="posting-type-label">
                Job Title
              </FormLabel>
              <TextField
                value={title}
                onChange={(e) => setJobTitle(e.target.value)}
                fullWidth
                id="outlined-basic"
                variant="outlined"
              />
              <FormLabel style={{ marginTop: "20px" }} id="posting-type-label">
                Work Place type
              </FormLabel>
              <TextField
                fullWidth
                id="outlined-basic"
                value={workplaceType}
                onChange={(e) => setWorkPlaceType(e.target.value)}
                variant="outlined"
              />
              <FormLabel style={{ marginTop: "20px" }} id="posting-type-label">
                Skills
              </FormLabel>
              <TextField
                fullWidth
                id="outlined-basic"
                value={skills}
                onChange={(e) => setSkills(e.target.value)}
                variant="outlined"
              />
              <FormLabel style={{ marginTop: "20px" }} id="posting-type-label">
                Min Salary
              </FormLabel>
              <TextField
                fullWidth
                id="outlined-basic"
                value={minSalary}
                onChange={(e) => setMinSalary(e.target.value)}
                variant="outlined"
              />
              <FormLabel style={{ marginTop: "20px" }} id="posting-type-label">
                Max Salary
              </FormLabel>
              <TextField
                fullWidth
                id="outlined-basic"
                value={maxSalary}
                onChange={(e) => setMaxSalary(e.target.value)}
                variant="outlined"
              />
              <FormLabel style={{ marginTop: "20px" }} id="posting-type-label">
             Description
              </FormLabel>
              <TextField
                multiline
                rows={4}
                fullWidth
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                id="outlined-basic"
                variant="outlined"
              />
              <FormLabel style={{ marginTop: "20px" }} id="posting-type-label">
                Posting Type
              </FormLabel>
              <Select
                labelId="posting-type-label"
                value={age}
                displayEmpty
                placeholder="Posting Type"
                fullWidth
                onChange={(e) => setAge(e.target.value)}
              >
                <MenuItem disabled value={30}>
                  Posting Type
                </MenuItem>
                <MenuItem value={20}>Position</MenuItem>
                <MenuItem value={10}>Placment</MenuItem>
              </Select>
              <FormLabel style={{ marginTop: "20px" }} id="posting-type-label">
                Select Collage (Optional)
              </FormLabel>
              <Select
                value={age}
                fullWidth
                labelId="posting-collage-label"
                onChange={() => setAge()}
              >
                <MenuItem disabled value={30}>
                  Select Collage for Placment
                </MenuItem>
                <MenuItem value={10}>Alard Collage </MenuItem>
                <MenuItem value={20}>Savitaribai Collage</MenuItem>
              </Select>
            </div>
            <Button
              fullWidth
              variant="contained"
              sx={{
                margin: "5px",
                marginLeft: "auto",
                marginTop: "10px",
                background: colors.primary,
                borderRadius: "100px",
                fontFamily: "Outfit",
                textTransform: "none",
              }}
            >
              Post
            </Button>
            <Button
              fullWidth
              variant="contained"
              sx={{
                margin: "5px",
                marginLeft: "auto",
                marginTop: "10px",
                background: "green",
                borderRadius: "100px",
                fontFamily: "Outfit",
                textTransform: "none",
              }}
            >
              Save Template
            </Button>
          </CardWrapper>
          <JobsTemplate
            setTemplate={setTemplate}
            style={{
              padding: "20px",
              maxWidth: "500px",
              marginTop: "20px",
            }}
          />
        </div>
      </div>
    </Layout>
  );
};

export default JobPosting;
