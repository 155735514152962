import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";
import { Button, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import HeaderText from "../../components/HeaderText";
import { colors } from "../../constants/colors";
import CardWrapper from "../../components/Card/CardWrapper";
import { useNavigate } from "react-router-dom";

const EditCompanyProfile = () => {
  return (
    <Layout>
      <div style={{ display: "flex", width: "100%" }}>
        <CardWrapper
          style={{
            marginTop: "20px",
            padding: "20px",
            maxWidth: "500px",
            margin: "auto",
          }}
        >
          <HeaderText
            style={{
              textAlign: "center",
              marginBottom: "20px",
              fontFamily: "Outfit",
            }}
          >
            Update Profile
          </HeaderText>
          <div>
            <InputLabel htmlFor="file-input" style={{ marginTop: 20 }}>
              Profile Image:
            </InputLabel>
            <TextField
              type="file"
              fullWidth
              id="file-input"
              variant="outlined"
            />
            <TextField
              fullWidth
              id="outlined-basic"
              label="Company Name"
              variant="outlined"
              sx={{ marginTop: "20px" }}
            />

            <TextField
              fullWidth
              id="outlined-basic"
              sx={{ marginTop: "20px" }}
              label="Email Address"
              variant="outlined"
            />
            <TextField
              fullWidth
              id="outlined-basic"
              sx={{ marginTop: "20px" }}
              label="Company type"
              variant="outlined"
            />

            <TextField
              style={{ marginTop: "20px" }}
              fullWidth
              id="outlined-basic"
              label="Phone No"
              variant="outlined"
            />
            <TextField
              style={{ marginTop: "20px" }}
              fullWidth
              id="outlined-basic"
              label="Location"
              variant="outlined"
            />
            <TextField
              style={{ marginTop: "20px" }}
              multiline
              rows={4}
              fullWidth
              id="outlined-basic"
              label="Description"
              variant="outlined"
            />
          </div>

          <Button
            fullWidth
            variant="contained"
            sx={{
              margin: "5px",
              marginLeft: "auto",
              marginTop: "10px",
              background: colors.primary,
              borderRadius: "100px",
              fontFamily: "Outfit",
              textTransform: "none",
            }}
          >
            Update
          </Button>
        </CardWrapper>
      </div>
    </Layout>
  );
};

export default EditCompanyProfile;
