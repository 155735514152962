import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";
import { Button, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import HeaderText from "../../components/HeaderText";
import { colors } from "../../constants/colors";
import CardWrapper from "../../components/Card/CardWrapper";
import { useNavigate } from "react-router-dom";

const AddCourse = () => {
  const [topics, setTopics] = useState(1);
  const navigate=useNavigate();
  const [age,setAge]=useState();
  let engineeringDivisions = [
    "Civil ",
    "Mechanical ",
    "Electrical ",
    "Chemical ",
    "Computer ",
    "Aerospace ",
    "Biomedical ",
    "Environmental ",
    "Industrial ",
    "Materials ",

    "Petroleum ",
  ];
  return (
    <Layout>
      <div style={{ display: "flex", width: "100%" }}>
        <CardWrapper
          style={{
            marginTop: "20px",
            padding: "20px",
            maxWidth: "500px",
            margin: "auto",
          }}
        >
          <HeaderText
            style={{
              textAlign: "center",
              marginBottom: "20px",
              fontFamily: "Outfit",
            }}
          >
            Add Course
          </HeaderText>
          <div>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Course Title"
              variant="outlined"
            />

            <TextField
              style={{ marginTop: "20px" }}
              fullWidth
              id="outlined-basic"
              label="About this Course"
              variant="outlined"
            />
            <TextField
              style={{ marginTop: "20px" }}
              fullWidth
              id="outlined-basic"
              label="Certificates"
              variant="outlined"
            />
            <TextField
              style={{ marginTop: "20px" }}
              multiline
              rows={4}
              fullWidth
              id="outlined-basic"
              label="Description"
              variant="outlined"
            />
            <InputLabel htmlFor="file-input" style={{ marginTop: 20 }}>
              Thumbnail:
            </InputLabel>
            <TextField
              type="file"
              fullWidth
              id="file-input"
              variant="outlined"
            />
          </div>
          <InputLabel   sx={{ marginTop: "20px" }} id="posting-collage-label">Select Course branch</InputLabel>
            <Select
              value={age}
              fullWidth
              labelId="posting-collage-label"
              onChange={() => setAge()}
            >
              <MenuItem disabled value={30}>
                Select Course type
              </MenuItem>
              {engineeringDivisions.map(item=>   <MenuItem value={10}>{item}</MenuItem>)}
           
           
            </Select>
          <Button
            fullWidth
            onClick={()=>navigate("/addchapter/167")}
            variant="contained"
            sx={{
              margin: "5px",
              marginLeft: "auto",
              marginTop: "10px",
              background: colors.primary,
              borderRadius: "100px",
              fontFamily: "Outfit",
              textTransform: "none",
            }}
          >
            Post
          </Button>
        </CardWrapper>
      </div>
    </Layout>
  );
};

export default AddCourse;
