import React, { useState } from "react";
import StudentDashboard from "../../Layout/StudentDashboard";
import Alljobs from "../../components/AllJobs";
import JobDetailsCard from "../../components/Card/JobDetailsCard";
import { Grid } from "@mui/material";
import BreadCumb from "../../components/BradCumb";
import rawdata from "../../components/RawData/jobs.json"
import WorkIcon from "@mui/icons-material/Work";
import Layout from "../../components/Layout/Layout";
import { useNavigate, useParams } from "react-router-dom";
const JobsCollege = () => {
  const [jobListing,setJobListing]=useState(rawdata.jobs);
  const [job,setJob]=useState(rawdata.jobs[0])
  const navigate=useNavigate()
  const params=useParams()

  const jobDetails = {
    title: "Wizklub Educator",
    responsibilities: [
      "Facilitate learning for 6-14-year-old children using company programs",
      "Teach high-order thinking skills",
      "Conduct engaging and interactive sessions",
      "Track student performance and discuss progress with parents",
      "Conduct weekend classes between 3 pm to 8 pm",
    ],
    qualifications: [
      "Proficiency in English (knowledge of Hindi is an added advantage)",
      "Available from 4:00 pm to 8:00 pm, minimum 40 hours/month",
      "Mandatory availability on weekends",
      "Willingness to receive feedback for improvement",
      "Enthusiastic about new-age learning skills",
      "Minimum 1 year of teaching experience (preferred for grades 3 and above)",
      "Minimum 70% in UG/PG, graduate/post-graduate preferred",
      "Good internet connection",
      "Own a laptop/desktop with webcam",
      "Strong comprehension, critical thinking, reasoning, and problem-solving skills",
      "Good communication skills",
      "Candidates from arts, B.Ed, M.A, journalism, mass communication, and MA English can apply",
      "Female candidates required",
    ],
    additionalInfo: [
      "Interview process: HR interview",
      " post-assessment test (score > 6)",
      " role fitment call/screening round",
      " demonstration/final round (laptop mandatory)",
    ],
    skill: [
      "English Proficiency (Spoken)",
      " English Proficiency (Written)",
      "Online Teaching",
      " Teaching",
    ],
    certificate: [
      "English Proficiency (Spoken)",
      " English Proficiency (Written)",
    ],
    ctc: [
      "Fixed component: 80%",
      "Variable component: 10%",
      "Other incentives: 10%",
    ],
  };
  const navigationdata = [
    {
      icon: (
        <WorkIcon style={{ marginRight: 5, color: "0049FC", fontSize: 18 }} />
      ),
      name: "Jobs",
      route: "/",
    },
    {
      name: "All Jobs",
      route: "/all_jobs",
    },
  ];
  function handleClick(id) {
    if (window.innerWidth < 600) {
      navigate("/jobDetails/"+id)
    } else {
       setJob(jobListing.filter(item=>item.id==id)[0])
    }
}


  return (
    <Layout>
      <BreadCumb data={navigationdata} />
      <Grid container style={{ display: "flex", marginTop: 10 }}>
        <Grid item xs={12} sm={3} style={{ marginTop: 20 }}>
          <Alljobs
          onClick={handleClick}
            jobListing={jobListing}
            title={"All Jobs"}
            button={"All Jobs"}
            showAll
          />
        </Grid>
        <Grid xs={12} sm={8} sx={{ marginLeft: "20px" }}>
          <div className="displayContainer">
          <JobDetailsCard jobDetails={jobDetails} job={job} /></div>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default JobsCollege;
