import React from "react";
import CardWrapper from "../Card/CardWrapper";
import { Avatar, Divider } from "@mui/material";
import HeaderText from "../HeaderText";
import { colors } from "../../constants/colors";
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ShareIcon from "@mui/icons-material/Share";
import CommentIcon from '@mui/icons-material/Comment';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
const PostCard = ({ link, logo, company, companyType, desc, photo }) => {
  function convertToEmbedLink(youtubeLink) {
    return youtubeLink.replace(/watch\?v=/, "embed/");
  }
  return (
    <CardWrapper
      style={{
        // margin: 10,
        
        marginBottom: 20,
        padding:"10px",
        paddingBottom: 10,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          paddingBottom: 10,
        }}
      >
        <Avatar src={logo}></Avatar>
        <div>
          <HeaderText
            style={{ marginLeft: 10, fontSize: 16, fontFamily: "Outfit" }}
          >
            {company}
          </HeaderText>
          <HeaderText style={{ marginLeft: 10, fontSize: 12 }}>
            {companyType}
          </HeaderText>
        </div>
        <div style={{marginLeft:"auto"}}>
        <MoreHorizIcon style={{ color: colors.primary, marginLeft: 20 }} />
        </div>
      </div>{" "}
      {photo && (
        <img
          src={photo}
          style={{
            width: "100%",
            borderRadius: 10,
            overflow: "hidden",
            border: "0px solid black",
          }}
        />
      )}
      {link && (
        <iframe
          style={{
            width: "100%",
            borderRadius: 10,
            overflow: "hidden",
            border: "0px solid black",
          }}
          height="345"
          src={convertToEmbedLink(link)}
        ></iframe>
      )}
      {desc && (
        <HeaderText
          style={{ fontSize: 14, fontWeight: "400", fontFamily: "Outfit" }}
        >
          {desc}
        </HeaderText>
      )}
      <Divider style={{marginTop:"10px",marginBottom:"10px"}}/>
      <div style={{display:"flex"}}>
        <div style={{flex:1,display:"flex",alignItems:"center",justifyContent:"center",cursor:"pointer"}}>
          <ThumbUpOffAltIcon/>
        </div>
        <div style={{flex:1,display:"flex",alignItems:"center",justifyContent:"center",cursor:"pointer"}}>
          <CommentIcon/>
        </div>
        <div style={{flex:1,display:"flex",alignItems:"center",justifyContent:"center",cursor:"pointer"}}>
          <ShareIcon/>
        </div>
      </div>
    </CardWrapper>
  );
};

export default PostCard;
