import React, { useState,useEffect } from "react";
import StudentDashboard from "../../Layout/StudentDashboard";
import Alljobs from "../../components/AllJobs";
import JobDetailsCard from "../../components/Card/JobDetailsCard";
import { Grid } from "@mui/material";
import BreadCumb from "../../components/BradCumb";
import WorkIcon from "@mui/icons-material/Work";
import SwitchItems from "../../components/SwitchItems";
import Layout from "../../components/Layout/Layout";
import rawdata from "../../components/RawData/jobs.json";
import { useNavigate } from "react-router-dom";
import { getJobDetails, getMyJobs } from "../../ApiCalls";
const MyJobs = () => {
  const [active, setActive] = useState("All Jobs");
  const [jobListing, setJobListing] = useState(rawdata.my_jobs);
  const [job, setJob] = useState(rawdata.my_jobs[0]);
  const navigate = useNavigate();
  function handleClick(id) {
    if (window.innerWidth < 600) {
      navigate("/jobDetails/" + id);
    } else {
      setJob(jobListing.filter((item) => item.id == id)[0]);
    }
  }

  const [jobDetails, setJobDetails] = useState({});

  const navigationdata = [
    {
      icon: (
        <WorkIcon style={{ marginRight: 5, color: "0049FC", fontSize: 18 }} />
      ),
      name: "Jobs",
      route: "/",
    },
    {
      name: "My Jobs",
      route: "/my_jobs",
    },
  ];
  const menus = [
    "All Jobs",
    "Applied",
    "Short Listed",
    "Interview",
    "Hired",
    "Rejected",
  ];

  useEffect(() => {
    let result = getMyJobs();
    setJobListing(result);
    setJob(result[0]);
    let result2 = getJobDetails();
    setJobDetails(result2);
  }, []);
  return (
    <Layout>
      <BreadCumb data={navigationdata} />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{ margin: 10, display: "flex", alignItems: "center", flex: 1 }}
        >
          <SwitchItems items={menus} active={active} setActive={setActive} />
        </div>
      </div>
      {jobListing && (
        <Grid container style={{ display: "flex", marginTop: 10 }}>
          <Grid item xs={12} sm={3} style={{ marginTop: 20 }}>
            <Alljobs
              jobListing={
                active == "All Jobs"
                  ? jobListing
                  : jobListing.filter((item) => item.status == active)
              }
              onClick={handleClick}
              title={active}
              button={"Show All"}
              showAll
              type="My Job"
              pagination={false}
            />
          </Grid>
          <Grid xs={12} sm={8} sx={{ marginLeft: "20px" }}>
            <div className="displayContainer">
              {jobDetails && (
                <JobDetailsCard
                  jobDetails={jobDetails}
                  job={job}
                  applied
                  status="applied"
                />
              )}
            </div>
          </Grid>
        </Grid>
      )}
    </Layout>
  );
};

export default MyJobs;
