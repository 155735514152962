import React from "react";
import Layout from "../../components/Layout/Layout";
import { useState } from "react";
import SwitchItems from "../../components/SwitchItems";
import CardWrapper from "../../components/Card/CardWrapper";
import { Avatar, Button, Grid } from "@mui/material";
import HeaderText from "../../components/HeaderText";
import { colors } from "../../constants/colors";
import { useNavigate } from "react-router-dom";
import DriveDetailsCard from "../../components/Card/DriveDetailsCard";
import AllPlacmentDrive from "../../components/AllPlacmentDrive";

const PlacmentDrives = () => {
  const [active, setActive] = useState("All");
  const menus = ["All", "Upcoming", "Completed"];
  const navigate = useNavigate();
  const placementDrives = [
    {
      image:
        "https://img.freepik.com/premium-vector/creative-letter-s-logotype-abstract-hexagon-with-pixel-technology-logo-design-creative-dynamic-round-logotype-connection-symbol-design_144543-1685.jpg",
      jobTitle: "Software Engineer Level 1",
      date: "2023-11-15",
      companyName: "ABC Tech Solutions",
      location: "Pune",
      status: "Upcoming",
    },
    {
      image:
        "https://img.freepik.com/free-vector/system-corporation-logo_1043-180.jpg",
      jobTitle: "Software Engineer Level 2",
      date: "2023-11-20",
      companyName: "XYZ Innovations",
      location: "Delhi",
      status: "Upcoming",
    },
    {
      image:
        "https://img.freepik.com/premium-vector/gear-light-vector-logo-design_695276-2426.jpg?w=360",
      jobTitle: "System Engineer Level 1",
      date: "2023-10-25",
      companyName: "TechGuru",
      location: "Jungad",
      status: "Completed",
    },
    {
      image:
        "https://img.freepik.com/free-vector/gradient-abstract-logo_52683-8517.jpg",
      jobTitle: "Automobile Engineer Level 1",
      date: "2023-12-05",
      companyName: "InnoSys",
      location: "Pune",
      status: "Upcoming",
    },
    {
      image:
        "https://img.freepik.com/premium-vector/d-letter-logo-design_164960-418.jpg",
      jobTitle: "Design Engineer Level 1",
      date: "2023-10-10",
      companyName: "WebMasters",
      location: "Nahik",
      status: "Completed",
    },
  ];
  const jobDetails = {
    title: "Wizklub Educator",
    responsibilities: [
      "Facilitate learning for 6-14-year-old children using company programs",
      "Teach high-order thinking skills",
      "Conduct engaging and interactive sessions",
      "Track student performance and discuss progress with parents",
      "Conduct weekend classes between 3 pm to 8 pm",
    ],
    qualifications: [
      "Proficiency in English (knowledge of Hindi is an added advantage)",
      "Available from 4:00 pm to 8:00 pm, minimum 40 hours/month",
      "Mandatory availability on weekends",
      "Willingness to receive feedback for improvement",
      "Enthusiastic about new-age learning skills",
      "Minimum 1 year of teaching experience (preferred for grades 3 and above)",
      "Minimum 70% in UG/PG, graduate/post-graduate preferred",
      "Good internet connection",
      "Own a laptop/desktop with webcam",
      "Strong comprehension, critical thinking, reasoning, and problem-solving skills",
      "Good communication skills",
      "Candidates from arts, B.Ed, M.A, journalism, mass communication, and MA English can apply",
      "Female candidates required",
    ],
    additionalInfo: [
      "Interview process: HR interview",
      " post-assessment test (score > 6)",
      " role fitment call/screening round",
      " demonstration/final round (laptop mandatory)",
    ],
    skill: [
      "English Proficiency (Spoken)",
      " English Proficiency (Written)",
      "Online Teaching",
      " Teaching",
    ],
    certificate: [
      "English Proficiency (Spoken)",
      " English Proficiency (Written)",
    ],
    ctc: [
      "Fixed component: 80%",
      "Variable component: 10%",
      "Other incentives: 10%",
    ],
  };

  return (
    <Layout>
      <div style={{ margin: 10, display: "flex", alignItems: "center" }}>
        <SwitchItems items={menus} active={active} setActive={setActive} />
      </div>
      <Grid container style={{ display: "flex", marginTop: 10 }}>
        <Grid item xs={12} sm={3} style={{ marginTop: 20 }}>
          <AllPlacmentDrive
            jobListing={
              active == "All"
                ? placementDrives
                : placementDrives.filter((item) => item.status == active)
            }
            title={active}
            button={"Show All"}
            onClick={(id)=>navigate("/college/placment_drives/1")}
            showAll
            type="My Job"
            pagination={false}
          />
        </Grid>
        <Grid xs={12} sm={8} sx={{ marginLeft: "20px" }}>
          <div className="displayContainer">
          <DriveDetailsCard jobDetails={jobDetails} applied status="applied" /></div>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default PlacmentDrives;
