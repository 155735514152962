import React, { useEffect, useState } from "react";
import StudentDashboard from "../../Layout/StudentDashboard";
import HeaderText from "../../components/HeaderText";
import ShareIcon from "@mui/icons-material/Share";
import { colors } from "../../constants/colors";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Button, Chip, Divider, Grid } from "@mui/material";
import AssessmentIcon from "@mui/icons-material/Assessment";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import DescriptionIcon from "@mui/icons-material/Description";
import HelpIcon from "@mui/icons-material/Help";
import CardWrapper from "../../components/Card/CardWrapper";
import MenuCard from "../../components/Card/MenuCard";
import Layout from "../../components/Layout/Layout";
import { useNavigate } from "react-router-dom";
import { getStudentProfile, getStudentResume } from "../../ApiCalls";

const menuItems = [
  {
    icon: <AssessmentIcon sx={{ color: "black" }} />,
    text: "Anyalatics",
  },
  {
    icon: <RecordVoiceOverIcon sx={{ color: "black" }} />,
    text: "My Posts",
  },
  {
    icon: <DescriptionIcon sx={{ color: "black" }} />,
    text: "Resume Builder",
  },
  {
    icon: <HelpIcon sx={{ color: "black" }} />,
    text: "Job Seeker Guidance",
  },
];
const Profile = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState();
  const [resumeDetails,setResumeDetails]=useState();
  useEffect(() => {
    let result = getStudentProfile();
    setUser(result);
    const result2=getStudentResume();
    setResumeDetails(result2)
  }, []);

  return (
    <Layout>
      {user && (
        <div style={{borderRadius:10,paddingBottom:10}}>
          <img
            src={user.poster}
            style={{
              height: "300px",
              width: "100%",
              objectFit: "cover",
              borderRadius: 8,
            }}
          />
          <div
            className="maincontainer"
            style={{
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <img
              src={user.image}
              style={{
                height: 150,
                width: "150px",
                borderRadius: "100px",
                objectFit: "cover",
                marginLeft: 20,
                marginTop: "-60px",
                border: "6px solid white",
              }}
            />
            <div style={{ marginLeft: 10 }}>
              <HeaderText style={{ fontFamily: "Outfit" }}>
                {user.name}
              </HeaderText>
              <HeaderText
                style={{
                  fontFamily: "Outfit",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                {user.occupation}
              </HeaderText>
              <HeaderText
                style={{
                  fontFamily: "Outfit",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                {user.oneLiner}
              </HeaderText>
            </div>
            <div style={{ marginLeft: "auto" }}>
              <ShareIcon style={{ color: colors.primary }} />
              <MoreHorizIcon
                style={{ color: colors.primary, marginLeft: 20 }}
              />
            </div>
          </div>
        {resumeDetails&&  <Grid container>
            <Grid item xs={12} sm={4}>
              {" "}
              <div className="displayContainer">
                <MenuCard menu={menuItems} style={{ marginRight: "10px",marginLeft:10 }} />
              </div>
            </Grid>

            <Grid item xs={12} sm={8}>
              <CardWrapper style={{ padding: "20px",marginRight:10 }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={
                      "https://cdn-icons-png.flaticon.com/512/219/219986.png"
                    }
                    style={{ height: 56, width: 56 }}
                  />
                  <div style={{ marginLeft: 10 }}>
                    <HeaderText style={{ fontFamily: "Outfit" }}>
                      Basic Information
                    </HeaderText>
                    <HeaderText
                      style={{
                        fontFamily: "Outfit",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      Update profile information
                    </HeaderText>
                  </div>
                  <Button
                    onClick={() => navigate("/editProfile")}
                    variant="outlined"
                    style={{
                      marginLeft: "auto",
                      border: `1px solid ${colors.primary}`,
                      color: colors.primary,
                      textTransform: "none",
                      fontFamily: "Outfit",
                    }}
                  >
                    Edit
                  </Button>
                </div>
                <div style={{ marginTop: "20px", display: "flex" }}>
                  <div style={{ flex: 1 }}>
                    <div>
                      <HeaderText
                        style={{
                          fontFamily: "Outfit",
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "grey",
                        }}
                      >
                        Email Address
                      </HeaderText>
                      <HeaderText
                        style={{
                          fontFamily: "Outfit",
                          fontSize: "14px",
                        }}
                      >
                        {user.contact.email}
                      </HeaderText>
                    </div>
                    <div>
                      <HeaderText
                        style={{
                          marginTop: 20,
                          fontFamily: "Outfit",
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "grey",
                        }}
                      >
                        Phone Number
                      </HeaderText>
                      <HeaderText
                        style={{
                          fontFamily: "Outfit",
                          fontSize: "14px",
                        }}
                      >
                       {user.contact.phone}
                      </HeaderText>
                    </div>
                    <div>
                      <HeaderText
                        style={{
                          marginTop: 20,
                          fontFamily: "Outfit",
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "grey",
                        }}
                      >
                        Website
                      </HeaderText>
                      <HeaderText
                        style={{
                          fontFamily: "Outfit",
                          fontSize: "14px",
                        }}
                      >
                        {user.contact.website}
                      </HeaderText>
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div>
                      <HeaderText
                        style={{
                          fontFamily: "Outfit",
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "grey",
                        }}
                      >
                        Gender
                      </HeaderText>
                      <HeaderText
                        style={{
                          fontFamily: "Outfit",
                          fontSize: "14px",
                        }}
                      >
                         {user.gender}
                      </HeaderText>
                    </div>
                    <div>
                      <HeaderText
                        style={{
                          fontFamily: "Outfit",
                          fontSize: "14px",
                          marginTop: "20px",
                          fontWeight: "400",
                          color: "grey",
                        }}
                      >
                        Location
                      </HeaderText>
                      <HeaderText
                        style={{
                          fontFamily: "Outfit",
                          fontSize: "14px",
                        }}
                      >
                        {user.location}
                      </HeaderText>
                    </div>
                  </div>
                </div>
              </CardWrapper>
              <CardWrapper style={{ padding: "20px", marginTop: "20px",marginRight:10 }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={
                      "https://img.freepik.com/free-vector/business-briefcase-flat-style_78370-1031.jpg?size=338&ext=jpg&ga=GA1.1.386372595.1697932800&semt=ais"
                    }
                    style={{ height: 56, width: 56 }}
                  />
                  <div style={{ marginLeft: 10 }}>
                    <HeaderText style={{ fontFamily: "Outfit" }}>
                      Experiences
                    </HeaderText>
                    <HeaderText
                      style={{
                        fontFamily: "Outfit",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      Add experience to increase the chance of hiring
                    </HeaderText>
                  </div>
                  <Button
                    variant="outlined"
                    size="small"
                    style={{
                      marginLeft: "auto",
                      border: `1px solid ${colors.primary}`,
                      color: colors.primary,
                      textTransform: "none",
                      fontFamily: "Outfit",
                    }}
                  >
                    Add Experience
                  </Button>
                </div>
                {resumeDetails.jobs.map((item, index) => (
                  <div style={{ margin: 10 }}>
                    <div style={{ display: "flex" }}>
                      <img
                        src={item.img}
                        style={{ height: 56, width: 56, marginRight: 10 }}
                      />
                      <div>
                        <HeaderText
                          style={{ fontSize: 16, fontFamily: "Outfit" }}
                        >
                          {item.role}
                        </HeaderText>
                        <HeaderText style={{ fontSize: 12, marginTop: 3 }}>
                          {item.company}
                        </HeaderText>
                        <HeaderText style={{ fontSize: 12, marginTop: 3 }}>
                          {item.year}
                        </HeaderText>
                      </div>

                      <HeaderText
                        style={{
                          fontSize: 14,
                          marginTop: 3,
                          color: colors.primary,
                          marginLeft: "auto",
                        }}
                      >
                        Edit
                      </HeaderText>
                      <HeaderText
                        style={{
                          fontSize: 14,
                          marginTop: 3,
                          color: "grey",
                          marginLeft: "20px",
                        }}
                      >
                        Delete
                      </HeaderText>
                    </div>
                    <HeaderText style={{ fontSize: 14, marginTop: 3 }}>
                      {item.desc}
                    </HeaderText>{" "}
                    {item.skills.map((item, index) => (
                      <Chip
                        key={index}
                        label={item}
                        sx={{
                          fontSize: 14,
                          margin: "5px",
                          fontFamily: "Quicksand",
                          fontWeight: 600,
                          color: "black",
                        }}
                      >
                        {index + 1 + " "}. {item}
                      </Chip>
                    ))}
                    <Divider style={{ margin: 10, marginLeft: 0 }} />
                  </div>
                ))}
              </CardWrapper>
              <CardWrapper style={{ padding: "20px", marginTop: "20px",marginRight:10 }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={
                      "https://cdn-icons-png.flaticon.com/512/3197/3197948.png"
                    }
                    style={{ height: 56, width: 56 }}
                  />
                  <div style={{ marginLeft: 10 }}>
                    <HeaderText style={{ fontFamily: "Outfit" }}>
                      Education & Certifications
                    </HeaderText>
                    <HeaderText
                      style={{
                        fontFamily: "Outfit",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      Add education to increase the chance of hiring
                    </HeaderText>
                  </div>
                  <Button
                    variant="outlined"
                    size="small"
                    style={{
                      marginLeft: "auto",
                      border: `1px solid ${colors.primary}`,
                      color: colors.primary,
                      textTransform: "none",
                      fontFamily: "Outfit",
                    }}
                  >
                    Add Education
                  </Button>
                </div>
                <div>
                  {resumeDetails.education.map((item, index) => (
                    <div style={{ margin: 10 }}>
                      <div style={{ display: "flex" }}>
                        <img
                          src={item.img}
                          style={{ height: 56, width: 56, marginRight: 10 }}
                        />
                        <div>
                          <HeaderText
                            style={{ fontSize: 16, fontFamily: "Outfit" }}
                          >
                            {item.degreee}
                          </HeaderText>
                          <HeaderText style={{ fontSize: 12, marginTop: 3 }}>
                            {item.univercity}
                          </HeaderText>
                          <HeaderText style={{ fontSize: 12, marginTop: 3 }}>
                            {item.year}
                          </HeaderText>
                        </div>
                      </div>
                      <HeaderText style={{ fontSize: 14, marginTop: 3 }}>
                        {item.desc}
                      </HeaderText>
                      <Divider style={{ marginTop: "10px" }} />
                    </div>
                  ))}
                </div>
              </CardWrapper>
              <CardWrapper style={{ padding: "20px", marginTop: "20px",marginRight:10 }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={
                      "https://img.freepik.com/free-vector/open-laptop-icon-cartoon-illustration_107791-4113.jpg"
                    }
                    style={{ height: 56, width: 56 }}
                  />
                  <div style={{ marginLeft: 10 }}>
                    <HeaderText style={{ fontFamily: "Outfit" }}>
                      Skills
                    </HeaderText>
                    <HeaderText
                      style={{
                        fontFamily: "Outfit",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      Add skills to increase the chance of hiring
                    </HeaderText>
                  </div>
                  <Button
                    variant="outlined"
                    size="small"
                    style={{
                      marginLeft: "auto",
                      border: `1px solid ${colors.primary}`,
                      color: colors.primary,
                      textTransform: "none",
                      fontFamily: "Outfit",
                    }}
                  >
                    Add Skills
                  </Button>
                </div>
                <div>
                  <Grid container>
                    {resumeDetails.skills.map((item, index) => (
                      <Grid item xs={6}>
                        <div
                          style={{
                            margin: 5,
                            flexDirection: "row",
                            border: "1px solid grey",
                            borderRadius: "10px",
                            padding: 10,
                          }}
                        >
                          <HeaderText
                            style={{ fontSize: 16, fontFamily: "Outfit" }}
                          >
                            {item.title}
                          </HeaderText>
                          <HeaderText style={{ fontSize: 12 }}>
                            {item.level}
                          </HeaderText>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              </CardWrapper>
            </Grid>
          </Grid>}
        </div>
      )}
    </Layout>
  );
};

export default Profile;
