import React from "react";

import { ArrowDropDown, Timelapse } from "@mui/icons-material";

import { Divider } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { useState } from "react";
import HeaderText from "../../components/HeaderText";
import { colors } from "../../constants/colors";
const OpeningCard = ({ item }) => {
  const [active, setActive] = useState(false);
  return (
    <div
      style={{
        background:item.status=="completed"?"rgb(244, 245, 255)":"white",
        width: "100%",
        marginTop: "10px",
        border: item.status=="completed"?"1px solid "+colors.primary:"1px solid lightgrey",
        padding: 10,
        borderRadius: "10px",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <HeaderText style={{ fontFamily: "Outfit", fontSize: "16px" }}>
            {" "}
            {item.name}
          </HeaderText>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Timelapse style={{ fontSize: 14, color: colors.primary }} />{" "}
            <HeaderText
              style={{
                fontFamily: "Outfit",
                fontSize: "12px",
                fontWeight: "400",
                marginLeft: "4px",
              }}
            >
              {item.jobs.length} jobs
            </HeaderText>
          </div>
        </div>
        {item.jobs &&
          (!active ? (
            <ArrowDropDown
              onClick={() => setActive(true)}
              style={{ fontSize: 20, color: colors.primary, cursor: "pointer" }}
            />
          ) : (
            <ArrowDropUpIcon
              onClick={() => setActive(false)}
              style={{ fontSize: 20, color: colors.primary, cursor: "pointer" }}
            />
          ))}
      </div>
      {active && (
        <div style={{}}>
          <Divider style={{ marginTop: 5, marginBottom: 5 }}>
            <HeaderText style={{ fontFamily: "Outfit", fontSize: "10px" }}>
              Jobs
            </HeaderText>
          </Divider>

          {item.jobs.map((item) => (
                <div
                style={{
                  padding: "4px",
                  background: "#dadde3",
                  borderRadius: "4px",
                  margin: "5px",
                  paddingLeft: 10,
                  paddingRight: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {" "}
                <HeaderText
                  style={{
                    fontFamily: "Outfit",
                    fontSize: "14px",
                  }}
                >
                  {item.title}
                </HeaderText>
                <div>
                  <HeaderText
                    style={{
                      // fontFamily: "Outfit",
                      fontSize: "12px",
                      background: colors.primary,
                      textAlign: "center",
                      paddingLeft: "5px",
                      paddingRight: "5px",
                      height: 20,
                      paddingTop: "2px",
                      fontWeight: "300",
                      borderRadius: "100px",
                      color: "white",
                    }}
                  >
                    {item.opening}
                  </HeaderText>
                </div>
              </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default OpeningCard;
