import React from "react";
import Layout from "../../components/Layout/Layout";
import HeaderText from "../../components/HeaderText";
import rawData from "../../components/RawData/jobs.json";
import { Avatar, Button, Grid, Rating, TextField } from "@mui/material";
import CardWrapper from "../../components/Card/CardWrapper";
import { colors } from "../../constants/colors";
import { useNavigate } from "react-router-dom";
import GraphCard from "../../components/Card/GraphCard";
const CollegeCourses = () => {
  const navigate = useNavigate();
  let Students = [
    {
      image:
        "https://www.corporatephotographylondon.com/wp-content/uploads/2019/11/HKstrategies-1210-1024x683.jpg",
      name: "John Smith",
      age: 18,
      grade: 85,
      course: "Full stack web developing",
    },
    {
      image:
        "https://www.corporatephotographylondon.com/wp-content/uploads/2019/11/HKstrategies-755-1-1024x683.jpg",
      name: "Alice Johnson",
      age: 17,
      grade: 92,
      course: "Full stack Java developing",
    },
    {
      image:
        "https://media.istockphoto.com/id/1329936184/photo/head-shot-portrait-of-smiling-businesswoman-intern-looking-at-camera.jpg?s=612x612&w=0&k=20&c=cCeWt0GYUtcFsGFi6hTFci-d5KZY6FAd_UchY9V5aSo=",
      name: "Bob Brown",
      age: 19,
      grade: 78,
      course: "Full stack python developing",
    },
    {
      image:
        "https://media.istockphoto.com/id/1303206558/photo/headshot-portrait-of-smiling-businessman-talk-on-video-call.jpg?s=612x612&w=0&k=20&c=hMJhVHKeTIznZgOKhtlPQEdZqb0lJ5Nekz1A9f8sPV8=",
      name: "Emily Davis",
      age: 20,
      grade: 88,
      course: "Full stack android developing",
    },
    {
      image:
        "https://media.istockphoto.com/id/1309328823/photo/headshot-portrait-of-smiling-male-employee-in-office.jpg?s=612x612&w=0&k=20&c=kPvoBm6qCYzQXMAn9JUtqLREXe9-PlZyMl9i-ibaVuY=",
      name: "David Wilson",
      age: 18,
      grade: 76,
      course: "Full stack angular developing",
    },
    {
      image:
        "https://www.corporatephotographylondon.com/wp-content/uploads/2019/11/HKstrategies-1029-1024x683.jpg",
      name: "Sarah Lee",
      age: 19,
      grade: 95,
      course: "backend java developing",
    },
    {
      image:
        "https://www.corporatephotographylondon.com/wp-content/uploads/2019/11/Option-3-1024x683.jpg",
      name: "Michael Jones",
      age: 17,
      grade: 89,
      course: "git",
    },
    {
      image:
        "https://img.freepik.com/free-photo/portrait-optimistic-businessman-formalwear_1262-3600.jpg?size=626&ext=jpg&ga=GA1.1.1413502914.1696982400&semt=ais",
      name: "Olivia Martinez",
      age: 20,
      grade: 91,
      course: "linux development",
    },
  ];
  return (
    <Layout>
      <Grid container>
        <Grid item xs={8}>
          <div style={{ marginTop: "20px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <HeaderText style={{ fontFamily: "Outfit" }}>Courses</HeaderText>
            </div>
            <HeaderText
              style={{
                fontFamily: "Outfit",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              100+ published course
            </HeaderText>
            <div></div>
            <Grid container>
              {rawData.courses
                .map((item, index) => ({
                  ...item,
                  author: "Alard collage of engineering",
                  price: item.price * (index || 2),
                }))
                .map((item) => (
                  <Grid
                    xs={3}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginRight: "10px",
                      marginTop: "10px",
                    }}
                  >
                    <CardWrapper
                      onClick={() => {
                        navigate("/training/1");
                      }}
                      style={{
                        flexDirection: "column",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        src={item.poster}
                        style={{
                          width: "100%",
                          height: "150px",
                          borderRadius: "10px",
                        }}
                      />
                      <HeaderText
                        style={{
                          fontFamily: "Outfit",
                          fontSize: "14px",
                          maxWidth: "80%",
                        }}
                      >
                        {item.name.length > 30
                          ? item.name.slice(0, 30) + "..."
                          : item.name}
                      </HeaderText>
                      <HeaderText
                        style={{
                          fontFamily: "Outfit",
                          fontSize: "12px",
                          maxWidth: "80%",
                          fontWeight: "500",
                        }}
                      >
                        {item.author}
                      </HeaderText>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: 10,
                        }}
                      >
                        <HeaderText
                          style={{
                            fontFamily: "Outfit",
                            fontSize: "12px",
                            maxWidth: "80%",
                          }}
                        >
                          {item.star}
                        </HeaderText>
                        <Rating
                          style={{ fontSize: "14px" }}
                          name="read-only"
                          precision={0.5}
                          value={item.star}
                          readOnly
                        />
                        <HeaderText
                          style={{
                            fontFamily: "Outfit",
                            fontSize: "12px",
                            maxWidth: "80%",
                          }}
                        >
                          ({item.review})
                        </HeaderText>{" "}
                      </div>
                      <div>
                        <div>
                          <HeaderText
                            style={{
                              fontFamily: "Outfit",
                              fontSize: "14px",
                              maxWidth: "80%",
                            }}
                          >
                            {" "}
                            ₹ {item.price}
                          </HeaderText>
                        </div>
                        <HeaderText
                          style={{
                            fontFamily: "Outfit",
                            fontSize: "12px",
                            maxWidth: "80%",
                            background: "red",
                            marginTop: "10px",
                            textAlign: "center",
                            color: "white",
                            borderRadius: "100px",
                            fontWeight: "500",
                            width: "80px",
                          }}
                        >
                          Bestseller
                        </HeaderText>
                      </div>
                    </CardWrapper>
                  </Grid>
                ))}
            </Grid>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div style={{ marginTop: "20px" }}>
            <GraphCard title={"Passed students"} type={"bar"} value={120}/>
            <HeaderText style={{ fontFamily: "Outfit" }}>
              Top Students
            </HeaderText>
            <HeaderText
              style={{
                fontFamily: "Outfit",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              Top Students after assignments
            </HeaderText>
            <CardWrapper style={{ marginTop: "10px" }}>
              {Students.map((item) => (
                <CardWrapper
                  style={{
                    marginBottom: 10,
                    padding: 10,
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Avatar src={item.image}>{item.name.split("")[0]}</Avatar>
                  <div>
                    <HeaderText
                      style={{
                        marginLeft: 20,
                        fontSize: "16px",
                        display: "flex",
                        fontFamily: "Outfit",
                      }}
                    >
                      {item.name}
                    </HeaderText>
                    <HeaderText style={{ marginLeft: 20, fontSize: 10 }}>
                      {item.course}
                    </HeaderText>
                  </div>
                  <HeaderText
                    style={{
                      fontFamily: "Outfit",
                      fontSize: "12px",
                      maxWidth: "80%",
                      marginLeft: "auto",
                      background: colors.primary,
                      marginTop: "10px",
                      textAlign: "center",
                      color: "white",
                      borderRadius: "100px",
                      fontWeight: "500",
                      width: "40px",
                    }}
                  >
                    {item.grade}%
                  </HeaderText>
                </CardWrapper>
              ))}
            </CardWrapper>
          </div>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default CollegeCourses;
