import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import PostCard from "../../components/PostCard";
import { Grid } from "@mui/material";
import SampleJobCard from "../../components/SampleJobCard";
import MenuCard from "../../components/Card/MenuCard";
import AssessmentIcon from "@mui/icons-material/Assessment";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import DescriptionIcon from "@mui/icons-material/Description";
import HelpIcon from "@mui/icons-material/Help";
import { useNavigate } from "react-router-dom";
import CardWrapper from "../../components/Card/CardWrapper";
import GraphCard from "../../components/Card/GraphCard";
import HeaderText from "../../components/HeaderText";
import moment from "moment";
import { getCompanyPost } from "../../ApiCalls";

const CompanyTimelines = () => {
  const navigate = useNavigate();
  const [companyPost,setCompanyPost]=useState();
  const [companyNews,setCompanyNews]=useState();
  useEffect(()=>{
    const result=getCompanyPost();
    setCompanyNews(result.news);
    setCompanyPost(result.post)
  },[])
  const menuItems = [
    {
      icon: <AssessmentIcon sx={{ color: "black" }} />,
      text: "Code Assessment",
    },
    {
      icon: <RecordVoiceOverIcon sx={{ color: "black" }} />,
      text: "Company Tools",
    },
    {
      icon: <DescriptionIcon sx={{ color: "black" }} />,
      text: "Courses Editor",
    },
    {
      icon: <HelpIcon sx={{ color: "black" }} />,
      text: "Company Tools",
    },
  ];
  const jobListing = [
    {
      image:
        "https://images.tech.co/wp-content/uploads/2019/12/13052618/Tech-co-Logo-.png",
      jobTitle: "Software Engineer",
      location: "Bangalore, India",
      companyName: "TechCo Inc.",
      ctc: "₹ 12 LPA to ₹ 15 LPA",
    },
    {
      image:
        "https://yt3.googleusercontent.com/AAnXC4o1n8BKDsO5l6Uc71rf7WOJjm2-aUHzkvyp9vGYB5F4UtXWTecVzvPOBCFK0bNYsZlD7Hk=s900-c-k-c0x00ffffff-no-rj",
      jobTitle: "Data Scientist",
      location: "Mumbai, India",
      companyName: "Meta",
      ctc: "₹ 10 LPA to ₹ 13 LPA",
    },
    {
      image:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/9/95/Instagram_logo_2022.svg/1200px-Instagram_logo_2022.svg.png",
      jobTitle: "UX/UI Designer",
      location: "Delhi, India",
      companyName: "Instagram",
      ctc: "₹ 8 LPA to ₹ 10 LPA",
    },
  ];
  const news = [
    {
      title: "Big Ideas in tech, finance, retail",
      readers: 4878,
      time: new Date(),
    },
    {
      title: "B-schools prep startup leaders",
      readers: 4878,
      time: new Date(),
    },
    {
      title: "Productivity up at India Inc",
      readers: 300,
      time: new Date(),
    },
    {
      title: "Startups gear up to hire freshers",
      readers: 1000,
      time: new Date(),
    },
  ];
  return (
    <Layout>
      <div style={{ position: "relative" }}>
        <Grid container spacing={[3, 3]} style={{ marginTop: "10px" }}>
          <Grid item xs={12} sm={3}>
            <div className="displayContainer" style={{ position: "sticky" }}>
              <MenuCard menu={menuItems} />
            </div>
          </Grid>{" "}
          <Grid item xs={12} sm={6}>
          {companyPost?.map((item) => (
              <PostCard
                link={item.link}
                company={item.user.name}
                logo={item.user.logo}
                companyType={item.user.occupation}
                desc={item.description}
                photo={item.photo}
              />
            ))}
          </Grid>{" "}
          <Grid item xs={12} sm={3}>
            <div className="displayContainer">
              {" "}
              <CardWrapper>
                <HeaderText style={{ fontFamily: "Outfit", fontSize: "18px" }}>
           News
                </HeaderText>

                {companyNews?.map((item,index) => (
                  <div>
                    {" "}
                    <HeaderText
                      style={{
                        fontFamily: "Outfit",
                        fontSize: "14px",
                        marginTop: "10px",
                      }}
                    >
                 {item.title}
                    </HeaderText>
                    <HeaderText
                      style={{
                        fontFamily: "Outfit",
                        fontSize: "12px",
                        fontWeight: "100",
                        marginTop: "0px",
                      }}
                    >
                      {moment().format("MMMM DD, YYYY")} . {item.readers} readers
                    </HeaderText>
                  </div>
                ))}
              </CardWrapper>
            </div>
          </Grid>
        </Grid>
      </div>
    </Layout>
  );
};

export default CompanyTimelines;
