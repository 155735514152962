import React, { useState } from "react";
import Header from "../Header/Header";
import StudentDashboard from "../../Layout/StudentDashboard";
import CompanyDashboard from "../../Layout/CompanyDashboard";
import CollegeDashboard from "../../Layout/CollegeDashboard";
import { useEffect } from "react";
import { getCollageProfile, getCompanyProfile, getStudentProfile } from "../../ApiCalls";

const Layout = ({ children }) => {
  const [active, setActive] = useState();
  const [activeuser,setActiveUser]=useState();
  useEffect(() => {
    let user = localStorage.getItem("user");
    setActive(user);
    if(user=="student"){
      let result=getStudentProfile();
      setActiveUser(result)
    }
    else if(user=="company"){
      let result=getCompanyProfile();
      setActiveUser(result)
    }
    else
    {
      let result=getCollageProfile();
      setActiveUser(result)
    }
  }, []);
  return (
    <div style={{ display: "relative" }}>
      {active == "student" && <StudentDashboard user={activeuser}>{children}</StudentDashboard>}
      {active == "company" && <CompanyDashboard user={activeuser}>{children}</CompanyDashboard>}
      {active == "college" && <CollegeDashboard user={activeuser}>{children}</CollegeDashboard>}
    </div>
  );
};

export default Layout;
