import React, { useEffect, useState } from "react";
import StudentDashboard from "../../Layout/StudentDashboard";
import Alljobs from "../../components/AllJobs";
import JobDetailsCard from "../../components/Card/JobDetailsCard";
import { Grid } from "@mui/material";
import BreadCumb from "../../components/BradCumb";
import rawdata from "../../components/RawData/jobs.json";
import WorkIcon from "@mui/icons-material/Work";
import Layout from "../../components/Layout/Layout";
import { useNavigate, useParams } from "react-router-dom";
import ModalWrapper from "../../components/ModalWrapper";
import HeaderText from "../../components/HeaderText";
import EditIcon from "@mui/icons-material/Edit";
import { colors } from "../../constants/colors";
import { getJobDetails, getStudentAllJobs } from "../../ApiCalls";
const JobForMe = () => {
  const [jobListing, setJobListing] = useState(rawdata.jobs);
  const [open, setOpen] = useState(false);
  const [job, setJob] = useState(rawdata.jobs[0]);
  const navigate = useNavigate();
  const params = useParams();
  const [jobDetails,setJobDetails]=useState({});
  const navigationdata = [
    {
      icon: (
        <WorkIcon style={{ marginRight: 5, color: "0049FC", fontSize: 18 }} />
      ),
      name: "Jobs",
      route: "/",
    },
    {
      name: "Jobs for me",
      route: "/all_jobs",
    },
  ];
  function handleClick(id) {
    if (window.innerWidth < 600) {
      navigate("/jobDetails/" + id);
    } else {
      setJob(jobListing.filter((item) => item.id == id)[0]);
    }
  }
  useEffect(()=>{
    let result=getStudentAllJobs();
    setJobListing(result)
    setJob(result[0]);
    let result2=getJobDetails()
    setJobDetails(result2)
  },[])

  return (
    <Layout>
      <BreadCumb data={navigationdata} />
      <ModalWrapper open={open} handleClose={() => setOpen(false)}>
        <HeaderText style={{ fontFamily: "Outfit", fontSize: "16px" }}>
          Add preferences to get matching jobs
        </HeaderText>
        <div style={{ marginTop: "10px" }}>
          <HeaderText
            style={{
              fontFamily: "Outfit",
              fontSize: "14px",

              fontWeight: "500",
            }}
          >
            Preferred job role{" "}
            <EditIcon style={{ color: colors.primary, fontSize: "14px" }} />
          </HeaderText>
          <div style={{ display: "flex", flexWrap: "wrap", marginTop: "5px" }}>
            {[
              "Front End Developer",
              "Fullstack Software Developer",
              "React Native Developer",
            ].map((item) => (
              <div
                style={{
                  padding: "4px",
                  background: "#dadde3",
                  borderRadius: "100px",
                  margin: "5px",
                  paddingLeft: 10,
                  paddingRight: "10px",
                }}
              >
                {" "}
                <HeaderText
                  style={{
                    fontFamily: "Outfit",
                    fontSize: "14px",
                  }}
                >
                  {item}
                </HeaderText>
              </div>
            ))}
          </div>
        </div>
        <div style={{ marginTop: "10px" }}>
          <HeaderText
            style={{
              fontFamily: "Outfit",
              fontSize: "14px",
              
              fontWeight: "500",
            }}
          >
            Preferred work location{" "}
            <EditIcon style={{ color: colors.primary, fontSize: "14px" }} />
          </HeaderText>
          <div style={{ display: "flex", flexWrap: "wrap", marginTop: "5px" }}>
            {[
              "Remote",
              "Pune",
              "Noida",
              "Mumbai",
              "Delhi",
              "Banglore"
            ].map((item) => (
              <div
                style={{
                  padding: "4px",
                  background: "#dadde3",
                  borderRadius: "100px",
                  margin: "5px",
                  paddingLeft: 10,
                  paddingRight: "10px",
                }}
              >
                {" "}
                <HeaderText
                  style={{
                    fontFamily: "Outfit",
                    fontSize: "14px",
                  }}
                >
                  {item}
                </HeaderText>
              </div>
            ))}
          </div>
        </div>
        <div style={{ marginTop: "10px" }}>
          <HeaderText
            style={{
              fontFamily: "Outfit",
              fontSize: "14px",
              
              fontWeight: "500",
            }}
          >
            Preferred Salary{" "}
            <EditIcon style={{ color: colors.primary, fontSize: "14px" }} />
          </HeaderText>
          <div style={{ display: "flex", flexWrap: "wrap", marginTop: "5px" }}>
            {[
              "₹ 12,00,000"
            ].map((item) => (
              <div
                style={{
                  padding: "4px",
                  background: "#dadde3",
                  borderRadius: "100px",
                  margin: "5px",
                  paddingLeft: 10,
                  paddingRight: "10px",
                }}
              >
                {" "}
                <HeaderText
                  style={{
                    fontFamily: "Outfit",
                    fontSize: "14px",
                  }}
                >
                  {item}
                </HeaderText>
              </div>
            ))}
          </div>
        </div>
      </ModalWrapper>
      {jobListing&&<Grid container style={{ display: "flex", marginTop: 10 }}>
        <Grid item xs={12} sm={3} style={{ marginTop: 20 }}>
          <Alljobs
            onButtonClick={()=>setOpen(true)}
            onClick={handleClick}
            jobListing={jobListing}
            title={"Jobs for me"}
            button={"Edit Preferences"}
            showAll
          />
        </Grid>
        <Grid xs={12} sm={8} sx={{ marginLeft: "20px" }}>
          <div className="displayContainer">
            {jobDetails&&<JobDetailsCard jobDetails={jobDetails} job={job} />}
          </div>
        </Grid>
      </Grid>}
    </Layout>
  );
};

export default JobForMe;
