const profile = {
    image:"https://assets.telegraphindia.com/telegraph/2023/Mar/1679475968_pune-university-1.jpg",
  name: "Savitari Bai Phule Pune",
  website: "www.google.com",
  collage_type: "Engineering Collage",
  location: "1600 Amphitheatre Parkway, Mountain View, California, USA",
  employeeSize: "Over 100,000",
  headOffice: "1600 Amphitheatre Parkway, Mountain View, California, USA",
  type: "Public",
  since: 1998,
  followers: 30501642,
  students: 23187,
  collageDescription: `ALARD Charitable Trust is an educational trust registered under Bombay Charitable Trust ACT 1960 bearing Registration No. E-2964-Pune dated 3rd Nov 1999. The trust is established for promoting the educational activities & programs as well as to create job opportunities for rural masses.

    ALARD College of Engineering and Management, Marunje, Pune is established in 2009. The College has been imparting quality technical education to the inspiring students and emphasizes on 'excellence in technical education'. All efforts are focused on providing knowledge to the students as per industry standards. It is located at IT Hub conducive to teaching, learning and research.`,
};

const courses={
  courses: [
    {
      name: "The Complete Python Bootcamp From Zero to Hero",
      author: "Jose Portila",
      star: 4.5,
      review: 484705,
      price: 499,
      poster:
        "https://codeop.tech/wp-content/uploads/2021/11/artturi-jalli-g5_rxRjvKmg-unsplash-scaled.jpg",
    },

    {
      name: "JavaScript: The Good Parts",
      author: "Douglas Crockford",
      star: 4.7,
      review: 20563,
      price: 599,
      poster:
        "https://miro.medium.com/v2/resize:fit:1400/1*LyZcwuLWv2FArOumCxobpA.png",
    },
    {
      name: "Machine Learning A-Z™: Hands-On Python & R In Data Science",
      author: "Kirill Eremenko",
      star: 4.8,
      review: 125489,
      price: 799,
      poster:
        "https://miro.medium.com/v2/resize:fit:1358/1*cG6U1qstYDijh9bPL42e-Q.jpeg",
    },
    {
      name: "React - The Complete Guide (incl Hooks, React Router, Redux)",
      author: "Maximilian Schwarzmüller",
      star: 4.6,
      review: 84562,
      price: 699,
      poster:
        "https://bs-uploads.toptal.io/blackfish-uploads/components/blog_post_page/content/cover_image_file/cover_image/1279225/regular_1708x683_0521-react-redux-and-immutablejs-Waldek_Newsletter-993b50f4ae56e9ee6e024a309c23a6c4.png",
    },
    {
      name: "Data Structures and Algorithms: Deep Dive Using Java",
      author: "Raghavendra Dixit",
      star: 4.9,
      review: 35672,
      price: 899,
      poster:
        "https://miro.medium.com/v2/resize:fit:2000/1*2rKGJ6h1regwmfMcty3SLw.png",
    },
    {
      name: "iOS 15 & Swift 5 - The Complete iOS App Development Bootcamp",
      author: "Angela Yu",
      star: 4.7,
      review: 98531,
      price: 899,
      poster: "https://techcrunch.com/wp-content/uploads/2021/09/iOS-15.jpeg",
    },
  ],
}

module.exports.Collage={
    profile,
    courses
}