import React from "react";
import { useState } from "react";
import HeaderText from "../components/HeaderText";
import { Box, Divider, Tab, Tabs, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import SwitchItems from "../components/SwitchItems";
import { colors } from "../constants/colors";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Login = () => {
  const [selectUser, setSelectedUser] = useState("Students");
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(selectUser);
    if (selectUser == "Students") {
      localStorage.setItem("user", "student");
      navigate("/student");
    } else if (selectUser == "Collage") {
      localStorage.setItem("user", "college");
      navigate("/CollegeDashboard");
    } else {
      localStorage.setItem("user", "company");
      navigate("/Dashboard");
    }
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
      password: data.get("password"),
    });
  };
  const menus = ["Students", "Company", "Collage"];

  return (
    <div
      style={{
        display: "flex",
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
        flexDirection: "row",
        width: "100%",
        alignItems: "center",
      }}
    >
      <img
        className="displayContainer"
        style={{ marginTop: "auto", marginLeft: "10%" }}
        src="https://img.freepik.com/free-vector/employees-with-laptops-learning-professional-trainig_335657-3298.jpg"
      />
      <div
        className="login"
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          maxWidth: "450px",
          // margin: "auto",
          marginTop: "3%",
          padding: 10,

          marginLeft: "auto",
          // marginBottom: "100px",
        }}
      >
        <div
          style={{
            background: "white",
            border: "1px solid lightgrey",
            display: "flex",
            flexDirection: "column",
            borderRadius: 10,
            padding: 20,
          }}
        >
          <HeaderText style={{ textAlign: "center", marginBottom: 20 }}>
            Placement Cell
          </HeaderText>
          <Divider style={{ marginBottom: 20 }}></Divider>
          <SwitchItems
            items={menus}
            style={{ justifyContent: "center" }}
            itemStyle={{ flex: "1" }}
            active={selectUser}
            setActive={setSelectedUser}
          />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {selectUser == "Students" ? (
              <img
                src="https://img.freepik.com/free-vector/collection-modern-college-students-with-bags_1262-19753.jpg?size=626&ext=jpg&ga=GA1.1.1880011253.1699056000&semt=ais"
                style={{ height: "100px", objectFit: "contain" }}
              />
            ) : selectUser == "Company" ? (
              <img
                src="https://img.freepik.com/free-vector/flat-design-characters-greeting-workplace_23-2148266596.jpg?size=626&ext=jpg&ga=GA1.1.386372595.1698451200&semt=ais"
                style={{ height: "100px", objectFit: "contain" }}
              />
            ) : (
              <img
                src="https://img.freepik.com/free-vector/flat-graduate-students-mantle-cap-holding-university-diploma-paper-scroll-happy-young-people-academic-gown-with-bachelor-degree-celebrating-graduation-from-college-university-high-school_88138-929.jpg?size=626&ext=jpg&ga=GA1.1.1826414947.1699056000&semt=sph"
                style={{ height: "100px", objectFit: "contain" }}
              />
            )}

            <Typography
              sx={{ fontFamily: "Outfit" }}
              component="h1"
              variant="h5"
            >
              Login as {selectUser}
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                size="small"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                size="small"
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  fontFamily: "Outfit",
                  background: colors.primary,
                  borderRadius: "20px",
                }}
              >
                Login
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default Login;
