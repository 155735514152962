import React, { useEffect, useState } from "react";
import CardWrapper from "../Card/CardWrapper";
import HeaderText from "../HeaderText";
import { colors } from "../../constants/colors";
import { getJobTemplates } from "../../ApiCalls";

const JobsTemplate = ({ style, setTemplate }) => {
  const [templates,setDataTemplate]=useState();
 
  useEffect(()=>{
    const result=getJobTemplates()
    setDataTemplate(result)
  },[])

  return (
  
    <CardWrapper style={style}>
      
      <HeaderText
        style={{
          textAlign: "center",
          marginBottom: "30px",
          fontFamily: "Outfit",
        }}
      >
        Teamplates
      </HeaderText>
      {templates?.map((item) => (
        <CardWrapper
          onClick={() => setTemplate && setTemplate(item)}
          style={{
            border: "1px solid lightgrey",
            marginBottom: 10,
            width: 200,
          }}
        >
          <HeaderText
            style={{
              fontFamily: "Outfit",
              fontSize: "15px",
              color: colors.primary,
            }}
          >
            {item.title}
          </HeaderText>
          <HeaderText
            style={{
              fontFamily: "Outfit",
              fontSize: "10px",
              maxHeight: "30px",
              fontWeight: "400",
              overflow: "hidden",
            }}
          >
            {item.jobDescription}
          </HeaderText>
        </CardWrapper>
      ))}
    </CardWrapper>
  );
};

export default JobsTemplate;
