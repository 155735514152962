import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ReactApexChart from "react-apexcharts";

const LineChart = ({ type,graphData }) => {
const [height,setHeight]=useState(350);
  function handleClick() {
    if (window.innerWidth < 600) {
      setHeight(200)
    } else {
      setHeight(350)
    }}
    useEffect(()=>{
      handleClick()
    },[])
  let data = {
    series:graphData?.yaxis|| [
      {
        name: "Visits",
        data: [91, 125, 35, 50, 200, 60],
      },
    ],
    options: {
      chart: {
        type: "bar",
        stacked: false,
        height: 350,
        zoom: {
          type: "x",
          enabled: true,
          autoScaleYaxis: true,
        },
        toolbar: {
          show: false, // Hide the zoom and other controls
        },
      },
    
      dataLabels: {
        enabled: false,
      },
      toolbar: {
        show: false, // Hide the zoom and other controls
      },
      markers: {
        size: 0,
      },

     
      
      xaxis: graphData?.xaxis||{
        type: "date",
        categories: ["04/01", "04/05", "04/15", "04/20", "04/25", "04/30"],
      },
    },
  };


  return (
    <div style={{maxHeight:"350px"}}>
    <ReactApexChart
      options={data.options}
      series={data.series}
      type={type || "area"}
      height={height}
      maxHeight={350}
    /></div>
  );
};

export default LineChart;
