const profile = {
  name: "Google",
  website: "www.google.com",
  image:
    "https://seeklogo.com/images/G/google-logo-28FA7991AF-seeklogo.com.png",
  industry: "Technology",
  employeeSize: "Over 100,000",
  headOffice: "1600 Amphitheatre Parkway, Mountain View, California, USA",
  type: "Public",
  since: 1998,
  location: "Mountain View, CA ",
  followers: 30501642,
  employee: 23187,
  specialization: "Search Engine, Cloud Computing, Advertising, Software",
  companyGallery: [
    "https://www.google.com/about/static/images/social_thumbnail.png",
    "https://www.gstatic.com/webp/gallery/4.jpg",
    "https://www.gstatic.com/webp/gallery/3.jpg",
  ],
  companyDescription: `Google LLC is an American multinational technology company that specializes in internet-related services and products. It is widely known for its search engine, but Google's offerings also include online advertising technologies, cloud computing, software, and hardware. Google's mission is to organize the world's information and make it universally accessible and useful..`,
  jobs: [
    {
      image:
        "https://seeklogo.com/images/G/google-logo-28FA7991AF-seeklogo.com.png",
      jobTitle: "Software Engineer",
      location: "Bangalore, India",
      companyName: "Google",
      ctc: "₹ 12 LPA to ₹ 15 LPA",
    },
    {
      image:
        "https://seeklogo.com/images/G/google-logo-28FA7991AF-seeklogo.com.png",
      jobTitle: "Data Scientist",
      location: "Mumbai, India",
      companyName: "Google",
      ctc: "₹ 10 LPA to ₹ 13 LPA",
    },
    {
      image:
        "https://seeklogo.com/images/G/google-logo-28FA7991AF-seeklogo.com.png",
      jobTitle: "UX/UI Designer",
      location: "Delhi, India",
      companyName: "Google",
      ctc: "₹ 8 LPA to ₹ 10 LPA",
    },
  ],
};

const dashboard = {
  graphs: {
    hired: {
      total: 223,
      graph: {
        value: 99,
        yaxis: [
          {
            name: "Visits",
            data: [91, 125, 35, 50, 10, 60],
          },
        ],
        xaxis: {
          type: "date",
          categories: ["04/01", "04/05", "04/15", "04/20", "04/25", "04/30"],
        },
      },
    },
    job_posted: {
      total: 23,
      graph: {
        value: 99,
        yaxis: [
          {
            name: "Visits",
            data: [91, 125, 35, 50, 10, 60],
          },
        ],
        xaxis: {
          type: "date",
          categories: ["04/01", "04/05", "04/15", "04/20", "04/25", "04/30"],
        },
      },
    },
  },
  cards: {
    applicant: {
      applicants: 200,
      matches: 20,
      message: 12,
    },
    sourcing: {
      views: 300,
      pitches: 20,
      matches: 20,
    },
  },
  tasks: [
    {
      task: "Review resumes of Full Stack",
      assignedTo: "Hiring Manager",
      status: "Pending",
    },
    {
      task: "Conduct initial phone screens",
      assignedTo: "Recruiter",
      status: "Pending",
    },
    {
      task: "Schedule on-site interviews with Backend Developers",
      assignedTo: "Coordinator",
      status: "Pending",
    },
    {
      task: "Conduct technical assessments of Software Tester",
      assignedTo: "Technical Interviewer",
      status: "Pending",
    },
    {
      task: "Check references",
      assignedTo: "Hiring Manager",
      status: "Pending",
    },
    {
      task: "Make job offer",
      assignedTo: "Hiring Manager",
      status: "Pending",
    },
    {
      task: "Prepare onboarding plan",
      assignedTo: "HR",
      status: "Pending",
    },
    {
      task: "Hired Full Stack Developer",
      assignedTo: "Hiring Manager",
      status: "Completed",
      date: "2023-11-01",
    },
  ],
};
const jobsTemplates = [
  {
    title: "Frontend Developer",
    workplaceType: "Remote",
    skills: "React.js, HTML, CSS, JavaScript",
    minSalary: 250000,
    maxSalary: 500000,
    jobDescription: `1. Develop user-facing features using React.js
              2. Build reusable components and front-end libraries for future use
              3. Ensure the technical feasibility of UI/UX designs
              4. Collaborate with other team members and stakeholders to deliver high-quality software products`,
  },
  {
    title: "DevOps Engineer",
    workplaceType: "Hybrid",
    skills: "Docker, Kubernetes, Jenkins, AWS, Terraform",
    minSalary: 350000,
    maxSalary: 700000,
    jobDescription: `1. Implement and manage continuous integration and deployment
              2. Design and maintain infrastructure and server architecture
              3. Collaborate with development and operations teams to automate processes
              4. Troubleshoot and resolve issues in development, test, and production environments`,
  },
  {
    title: "Data Scientist",
    workplaceType: "Remote",
    skills: "Python, Pandas, NumPy, Machine Learning, SQL",
    minSalary: 400000,
    maxSalary: 800000,
    jobDescription: `1. Analyze and interpret complex data sets
              2. Develop and implement machine learning models
              3. Collaborate with cross-functional teams to drive business solutions
              4. Communicate findings to both technical and non-technical stakeholders`,
  },
];
const jobListing = {
  active: [
    {
      title: "Full Stack Developer",
      location: "Pune",
      applicant: 20,
      status: "applied",
    },
    {
      title: "Backend Developer",
      location: "Pune",
      applicant: 20,
      status: "applied",
    },
    {
      title: "SDE",
      location: "Mumbai",
      applicant: 23,
      status: "applied",
    },
    {
      title: "SDE",
      location: "Banglore",
      applicant: 23,
      status: "rejected",
    },
  ],
  candidates: [
    {
      image:
        "https://www.corporatephotographylondon.com/wp-content/uploads/2019/11/HKstrategies-1210-1024x683.jpg",
      name: "John Smith",
      age: 18,
      grade: 85,
      status: "rejected",
    },
    {
      image:
        "https://www.corporatephotographylondon.com/wp-content/uploads/2019/11/HKstrategies-755-1-1024x683.jpg",
      name: "Alice Johnson",
      age: 17,
      grade: 92,
      status: "rejected",
    },
    {
      image:
        "https://media.istockphoto.com/id/1329936184/photo/head-shot-portrait-of-smiling-businesswoman-intern-looking-at-camera.jpg?s=612x612&w=0&k=20&c=cCeWt0GYUtcFsGFi6hTFci-d5KZY6FAd_UchY9V5aSo=",
      name: "Bob Brown",
      age: 19,
      grade: 78,
      status: "rejected",
    },
    {
      image:
        "https://media.istockphoto.com/id/1303206558/photo/headshot-portrait-of-smiling-businessman-talk-on-video-call.jpg?s=612x612&w=0&k=20&c=hMJhVHKeTIznZgOKhtlPQEdZqb0lJ5Nekz1A9f8sPV8=",
      name: "Emily Davis",
      age: 20,
      grade: 88,
      status: "applied",
    },
    {
      image:
        "https://media.istockphoto.com/id/1309328823/photo/headshot-portrait-of-smiling-male-employee-in-office.jpg?s=612x612&w=0&k=20&c=kPvoBm6qCYzQXMAn9JUtqLREXe9-PlZyMl9i-ibaVuY=",
      name: "David Wilson",
      age: 18,
      grade: 76,
      status: "applied",
    },
    {
      image:
        "https://www.corporatephotographylondon.com/wp-content/uploads/2019/11/HKstrategies-1029-1024x683.jpg",
      name: "Sarah Lee",
      age: 19,
      grade: 95,
      status: "applied",
    },
    {
      image:
        "https://www.corporatephotographylondon.com/wp-content/uploads/2019/11/Option-3-1024x683.jpg",
      name: "Michael Jones",
      age: 17,
      grade: 89,
      status: "applied",
    },
    {
      image:
        "https://img.freepik.com/free-photo/portrait-optimistic-businessman-formalwear_1262-3600.jpg?size=626&ext=jpg&ga=GA1.1.1413502914.1696982400&semt=ais",
      name: "Olivia Martinez",
      age: 20,
      grade: 91,
      status: "saved",
    },
  ],
  inactive: [
    {
      id: 1,
      image:
        "https://images.tech.co/wp-content/uploads/2019/12/13052618/Tech-co-Logo-.png",
      jobTitle: "Software Engineer",
      location: "Bangalore, India",
      companyName: "TechCo Inc.",
      ctc: "₹ 12 LPA to ₹ 15 LPA",
      status: "Hired",
      students: [
        {
          image:
            "https://www.corporatephotographylondon.com/wp-content/uploads/2019/11/HKstrategies-1210-1024x683.jpg",
          name: "John Smith",
          age: 18,
          grade: 85,
        },
        {
          image:
            "https://www.corporatephotographylondon.com/wp-content/uploads/2019/11/HKstrategies-755-1-1024x683.jpg",
          name: "Alice Johnson",
          age: 17,
          grade: 92,
        },
      ],
    },
    {
      id: 2,
      image:
        "https://yt3.googleusercontent.com/AAnXC4o1n8BKDsO5l6Uc71rf7WOJjm2-aUHzkvyp9vGYB5F4UtXWTecVzvPOBCFK0bNYsZlD7Hk=s900-c-k-c0x00ffffff-no-rj",
      jobTitle: "Data Scientist",
      location: "Mumbai, India",
      companyName: "Meta",
      ctc: "₹ 10 LPA to ₹ 13 LPA",
      status: "Hired",
      students: [
        {
          image:
            "https://media.istockphoto.com/id/1329936184/photo/head-shot-portrait-of-smiling-businesswoman-intern-looking-at-camera.jpg?s=612x612&w=0&k=20&c=cCeWt0GYUtcFsGFi6hTFci-d5KZY6FAd_UchY9V5aSo=",
          name: "Bob Brown",
          age: 19,
          grade: 78,
        },
        {
          image:
            "https://media.istockphoto.com/id/1303206558/photo/headshot-portrait-of-smiling-businessman-talk-on-video-call.jpg?s=612x612&w=0&k=20&c=hMJhVHKeTIznZgOKhtlPQEdZqb0lJ5Nekz1A9f8sPV8=",
          name: "Emily Davis",
          age: 20,
          grade: 88,
        },
      ],
    },
    {
      id: 3,
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSCveFxCPxYQCHrpjBfEOipwpiIOUS7tvcVgYHHKOMr_-zo4nq1TCziMg5lV1-1LaNjPF4&usqp=CAU",
      jobTitle: "UX/UI Designer",
      location: "Delhi, India",
      companyName: "Instagram",
      ctc: "₹ 8 LPA to ₹ 10 LPA",
      students: [
        {
          image:
            "https://media.istockphoto.com/id/1309328823/photo/headshot-portrait-of-smiling-male-employee-in-office.jpg?s=612x612&w=0&k=20&c=kPvoBm6qCYzQXMAn9JUtqLREXe9-PlZyMl9i-ibaVuY=",
          name: "David Wilson",
          age: 18,
          grade: 76,
        },
      ],
    },
    {
      id: 4,
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSCveFxCPxYQCHrpjBfEOipwpiIOUS7tvcVgYHHKOMr_-zo4nq1TCziMg5lV1-1LaNjPF4&usqp=CAU",
      jobTitle: "UX/UI Designer",
      location: "Delhi, India",
      companyName: "Instagram",
      ctc: "₹ 8 LPA to ₹ 10 LPA",
    },
    {
      id: 5,
      image:
        "https://yt3.googleusercontent.com/AAnXC4o1n8BKDsO5l6Uc71rf7WOJjm2-aUHzkvyp9vGYB5F4UtXWTecVzvPOBCFK0bNYsZlD7Hk=s900-c-k-c0x00ffffff-no-rj",
      jobTitle: "Data Scientist",
      location: "Mumbai, India",
      companyName: "Meta",
      ctc: "₹ 10 LPA to ₹ 13 LPA",
    },
  ],
};
const courses = {
  courses: [
    {
      name: "The Complete Python Bootcamp From Zero to Hero",
      author: "Jose Portila",
      star: 4.5,
      review: 484705,
      price: 499,
      poster:
        "https://codeop.tech/wp-content/uploads/2021/11/artturi-jalli-g5_rxRjvKmg-unsplash-scaled.jpg",
    },

    {
      name: "JavaScript: The Good Parts",
      author: "Douglas Crockford",
      star: 4.7,
      review: 20563,
      price: 599,
      poster:
        "https://miro.medium.com/v2/resize:fit:1400/1*LyZcwuLWv2FArOumCxobpA.png",
    },
    {
      name: "Machine Learning A-Z™: Hands-On Python & R In Data Science",
      author: "Kirill Eremenko",
      star: 4.8,
      review: 125489,
      price: 799,
      poster:
        "https://miro.medium.com/v2/resize:fit:1358/1*cG6U1qstYDijh9bPL42e-Q.jpeg",
    },
    {
      name: "React - The Complete Guide (incl Hooks, React Router, Redux)",
      author: "Maximilian Schwarzmüller",
      star: 4.6,
      review: 84562,
      price: 699,
      poster:
        "https://bs-uploads.toptal.io/blackfish-uploads/components/blog_post_page/content/cover_image_file/cover_image/1279225/regular_1708x683_0521-react-redux-and-immutablejs-Waldek_Newsletter-993b50f4ae56e9ee6e024a309c23a6c4.png",
    },
    {
      name: "Data Structures and Algorithms: Deep Dive Using Java",
      author: "Raghavendra Dixit",
      star: 4.9,
      review: 35672,
      price: 899,
      poster:
        "https://miro.medium.com/v2/resize:fit:2000/1*2rKGJ6h1regwmfMcty3SLw.png",
    },
    {
      name: "iOS 15 & Swift 5 - The Complete iOS App Development Bootcamp",
      author: "Angela Yu",
      star: 4.7,
      review: 98531,
      price: 899,
      poster: "https://techcrunch.com/wp-content/uploads/2021/09/iOS-15.jpeg",
    },
  ],
  students:[
    {
      image:
        "https://www.corporatephotographylondon.com/wp-content/uploads/2019/11/HKstrategies-1210-1024x683.jpg",
      name: "John Smith",
      age: 18,
      grade: 85,
      course: "Full stack web developing",
    },
    {
      image:
        "https://www.corporatephotographylondon.com/wp-content/uploads/2019/11/HKstrategies-755-1-1024x683.jpg",
      name: "Alice Johnson",
      age: 17,
      grade: 92,
      course: "Full stack Java developing",
    },
    {
      image:
        "https://media.istockphoto.com/id/1329936184/photo/head-shot-portrait-of-smiling-businesswoman-intern-looking-at-camera.jpg?s=612x612&w=0&k=20&c=cCeWt0GYUtcFsGFi6hTFci-d5KZY6FAd_UchY9V5aSo=",
      name: "Bob Brown",
      age: 19,
      grade: 78,
      course: "Full stack python developing",
    },
    {
      image:
        "https://media.istockphoto.com/id/1303206558/photo/headshot-portrait-of-smiling-businessman-talk-on-video-call.jpg?s=612x612&w=0&k=20&c=hMJhVHKeTIznZgOKhtlPQEdZqb0lJ5Nekz1A9f8sPV8=",
      name: "Emily Davis",
      age: 20,
      grade: 88,
      course: "Full stack android developing",
    },
    {
      image:
        "https://media.istockphoto.com/id/1309328823/photo/headshot-portrait-of-smiling-male-employee-in-office.jpg?s=612x612&w=0&k=20&c=kPvoBm6qCYzQXMAn9JUtqLREXe9-PlZyMl9i-ibaVuY=",
      name: "David Wilson",
      age: 18,
      grade: 76,
      course: "Full stack angular developing",
    },
    {
      image:
        "https://www.corporatephotographylondon.com/wp-content/uploads/2019/11/HKstrategies-1029-1024x683.jpg",
      name: "Sarah Lee",
      age: 19,
      grade: 95,
      course: "backend java developing",
    },
    {
      image:
        "https://www.corporatephotographylondon.com/wp-content/uploads/2019/11/Option-3-1024x683.jpg",
      name: "Michael Jones",
      age: 17,
      grade: 89,
      course: "git",
    },
    {
      image:
        "https://img.freepik.com/free-photo/portrait-optimistic-businessman-formalwear_1262-3600.jpg?size=626&ext=jpg&ga=GA1.1.1413502914.1696982400&semt=ais",
      name: "Olivia Martinez",
      age: 20,
      grade: 91,
      course: "linux development",
    },
  ],
  courseDetails: {
    videoUrl: "https://www.youtube.com/watch?v=kqtD5dpn9C8",
    content: [
      {
        title: "Python Introduction",
        time: "2:30",
        status: "completed",
        resource: [
          {
            title: "Github Project",
            link: "https://www.github.com",
          },
          {
            title: "Pycharam Project",
            link: "https://www.github.com",
          },
        ],
      },
      {
        title: "Python Variable",
        time: "10:30",
        status: "pending",
        resource: [
          {
            title: "Geeks for geeks",
            link: "https://www.github.com",
          },
        ],
      },
      {
        title: "Python functions",
        time: "10:30",
        status: "pending",
      },
    ],
    about:
      "Create a fast-paced, competitive multiplayer shooter in Unreal Engine 5 using C++!",
    certificates: [
      {
        title: "Completion of Java",
      },
    ],
    description:
      "Learn all about Unreal Engine multiplayer in this fully comprehensive C++ course. From scratch, we create a complete multiplayer shooter game, capable of creating and joining game sessions and connecting many players across the internet. We'll create our very own custom plugin that can be added to any Unreal Engine project to easily turn it into a multiplayer game. We learn how to optimize our game for the best performance, as competitive shooters require the highest performance possible. We'll implement lag compensation techniques such as client-side prediction and server-side rewind, so the game performs smoothly even in the presence of high lag! We learn how to use the proper game classes for each multiplayer mechanic. Custom match states, warmup/cooldown match timers, Elim announcements, HUD with health, shields, ammo and grenades, and more! We keep all important functionality on the server, to prevent cheating, and learn the proper way to use the Player Controller, Game Mode, Game State, Player State, Character and Game Instance classes. We make assault rifles, pistols, submachine guns, rocket launchers, grenade launchers, sniper rifles and throwing grenades! All functional in multiplayer, optimized for best performance. After this course, you'll be able to create any multiplayer game you want. If you can do a fast-paced shooter well, you can do any game, because fast-paced, competitive games require the best performance possible.",
  },
};

const companyPost={
news: [
  {
    title: "Big Ideas in tech, finance, retail",
    readers: 4878,
    time: new Date(),
  },
  {
    title: "B-schools prep startup leaders",
    readers: 4878,
    time: new Date(),
  },
  {
    title: "Productivity up at India Inc",
    readers: 300,
    time: new Date(),
  },
  {
    title: "Startups gear up to hire freshers",
    readers: 1000,
    time: new Date(),
  },
],
post:[
  {
    link: "https://www.youtube.com/watch?v=oYoWISUxke0",
    user: {
      image:
        "https://yt3.googleusercontent.com/vY3uYs71A_JwVcigyd2tVRHwuj05_cYktQSuzRCxta-9VFxHFtKjGrwG9WFi8ijXITBL3CwPQQ=s900-c-k-c0x00ffffff-no-rj",
      name: "Google",
      occupation: "Software Company",
    },
    description:
      "Google LLC is an American multinational technology company focusing on artificial intelligence, online advertising, search engine technology, cloud computing, computer software, quantum computing, e-commerce, and consumer electronics",
  
  },
  {
    photo: "https://www.zdnet.com/a/img/resize/4d812c16bb08710a12b4802db6789c9969ab8137/2020/04/30/120ce3e8-a4c8-4d15-baaf-75335c8da183/istock-1154834209.jpg?auto=webp&width=1280",
    user: {
      image:
        "https://img.freepik.com/premium-vector/blue-social-media-logo_197792-1759.jpg",
      name: "Facebook",
      occupation: "Software Company",
    },
    description:
      "Google LLC is an American multinational technology company focusing on artificial intelligence, online advertising, search engine technology, cloud computing, computer software, quantum computing, e-commerce, and consumer electronics",
  
 
  },{
    photo: "https://www.researchgate.net/publication/325170198/figure/fig4/AS:626828611973127@1526458995954/Interactive-space-in-Google-office-Dublin-Source-designboomcom.png",
    user: {
        image: "https://example.com/google-icon.jpg",
        name: "Google",
        occupation: "Technology Company",
    },
    description: "Google LLC is an American multinational technology company focusing on artificial intelligence, online advertising, search engine technology, cloud computing, computer software, quantum computing, e-commerce, and consumer electronics.",
},
{
    photo: "https://cdn.mos.cms.futurecdn.net/ixRZem5k2gpqhry7koAL4R.jpg",
    user: {
        image: "https://example.com/microsoft-icon.jpg",
        name: "Microsoft",
        occupation: "Technology Company",
    },
    description: "Microsoft Corporation is a leading global technology company that develops, licenses, and sells computer software, consumer electronics, and other personal computing and communications products and services.",
},
{
    photo: "https://storage.googleapis.com/gweb-uniblog-publish-prod/images/1_howzit_google_south_africa.width-500.format-webp.webp",
    user: {
        image: "https://example.com/apple-icon.jpg",
        name: "Apple",
        occupation: "Technology Company",
    },
    description: "Apple Inc. is an American multinational technology company that designs, manufactures, and markets consumer electronics, computer software, and online services.",
},
{
    photo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRUrLUULFqnTsbynCJLtm01dBIWiNW_tzwGDet40cxYd7bBNRBpIY1GMFlw9GY7jVfHs1Y&usqp=CAU",
    user: {
        image: "https://example.com/amazon-icon.jpg",
        name: "Amazon",
        occupation: "E-commerce Company",
    },
    description: "Amazon.com, Inc. is an American multinational technology and e-commerce company that focuses on e-commerce, cloud computing, digital streaming, artificial intelligence, and more.",
},
{
    photo: "https://i.insider.com/57e03173b0ef97011d8b672a?width=1000&format=jpeg&auto=webp",
    user: {
        image: "https://example.com/twitter-icon.jpg",
        name: "Twitter",
        occupation: "Social Media Company",
    },
    description: "Twitter is an American microblogging and social networking service where users post and interact with messages known as 'tweets'.",
},
{
    photo: "https://i.insider.com/57e04c2bb0ef973f1b8b694c?width=1000&format=jpeg&auto=webp",
    user: {
        image: "https://example.com/ibm-icon.jpg",
        name: "IBM",
        occupation: "Technology and Consulting Company",
    },
    description: "International Business Machines Corporation (IBM) is an American multinational technology and consulting company.",
},
{
    photo: "https://www.chairoffice.co.uk/media/4972/dublin-2.jpeg",
    user: {
        image: "https://example.com/netflix-icon.jpg",
        name: "Netflix",
        occupation: "Entertainment Company",
    },
    description: "Netflix, Inc. is an American subscription-based streaming service offering a wide variety of TV shows, movies, documentaries, and more.",
},
],

}
module.exports.Company = {
  profile,
  dashboard,
  jobsTemplates,
  jobListing,courses,companyPost
};
