import React from "react";

const HeaderText = ({ children, style,onClick }) => {
  return (
    <p
    onClick={onClick}
      style={{
        fontSize: 20,
        margin:0,
        fontWeight: "bold",
        fontFamily: "Quicksand",
        ...style,
      }}
    >
      {children}
    </p>
  );
};

export default HeaderText;
