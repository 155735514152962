import React, { useEffect, useState } from "react";
import StudentDashboard from "../../Layout/StudentDashboard";
import HeaderText from "../../components/HeaderText";
import ShareIcon from "@mui/icons-material/Share";
import { colors } from "../../constants/colors";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import DescriptionIcon from "@mui/icons-material/Description";
import HelpIcon from "@mui/icons-material/Help";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Button, Grid } from "@mui/material";
import AssessmentIcon from "@mui/icons-material/Assessment";
import MenuCard from "../../components/Card/MenuCard";
import CardWrapper from "../../components/Card/CardWrapper";
import PostCard from "../../components/PostCard";
import SampleJobCard from "../../components/SampleJobCard";
import Layout from "../../components/Layout/Layout";
import { useNavigate } from "react-router-dom";
import { getCompanyProfile } from "../../ApiCalls";
import { formatNumber } from "../../utils/ConvertorFunctions";

const menuItems = [
  {
    icon: <AssessmentIcon sx={{ color: "black" }} />,
    text: "Posts",
  },
  {
    icon: <RecordVoiceOverIcon sx={{ color: "black" }} />,
    text: "Details",
  },
  {
    icon: <DescriptionIcon sx={{ color: "black" }} />,
    text: "Jobs",
  },
  {
    icon: <HelpIcon sx={{ color: "black" }} />,
    text: "Culture",
  },
];

const jobListing = [
  {
    image:
      "https://seeklogo.com/images/G/google-logo-28FA7991AF-seeklogo.com.png",
    jobTitle: "Software Engineer",
    location: "Bangalore, India",
    companyName: "Google",
    ctc: "₹ 12 LPA to ₹ 15 LPA",
  },
  {
    image:
      "https://seeklogo.com/images/G/google-logo-28FA7991AF-seeklogo.com.png",
    jobTitle: "Data Scientist",
    location: "Mumbai, India",
    companyName: "Google",
    ctc: "₹ 10 LPA to ₹ 13 LPA",
  },
  {
    image:
      "https://seeklogo.com/images/G/google-logo-28FA7991AF-seeklogo.com.png",
    jobTitle: "UX/UI Designer",
    location: "Delhi, India",
    companyName: "Google",
    ctc: "₹ 8 LPA to ₹ 10 LPA",
  },
];
const googleDetails = {
  companyName: "Google",
  website: "www.google.com",
  industry: "Technology",
  employeeSize: "Over 100,000",
  headOffice: "1600 Amphitheatre Parkway, Mountain View, California, USA",
  type: "Public",
  since: 1998,
  specialization: "Search Engine, Cloud Computing, Advertising, Software",
  companyGallery: [
    "https://www.google.com/about/static/images/social_thumbnail.png",
    "https://www.gstatic.com/webp/gallery/4.jpg",
    "https://www.gstatic.com/webp/gallery/3.jpg",
  ],
  companyDescription: `ALARD Charitable Trust is an educational trust registered under Bombay Charitable Trust ACT 1960 bearing Registration No. E-2964-Pune dated 3rd Nov 1999. The trust is established for promoting the educational activities & programs as well as to create job opportunities for rural masses.

ALARD College of Engineering and Management, Marunje, Pune is established in 2009. The College has been imparting quality technical education to the inspiring students and emphasizes on 'excellence in technical education'. All efforts are focused on providing knowledge to the students as per industry standards. It is located at IT Hub conducive to teaching, learning and research.`,
};
const CompanyProfile = () => {
  const [profile, setProfile] = useState();

  useEffect(() => {
    let result = getCompanyProfile();
    setProfile(result);
  }, []);
  const navigate = useNavigate();
  return (
    <Layout>
      {profile && (
        <div>
          <img
            src="https://images.livspace-cdn.com/plain/https://jumanji.livspace-cdn.com/magazine/wp-content/uploads/sites/2/2023/05/23163145/modern-office-interior-design-with-partition-plants.jpg"
            style={{
              height: "300px",
              width: "100%",
              objectFit: "cover",
              borderRadius: 8,
            }}
          />
          <div
            className="maincontainer"
            style={{
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <img
              src={profile.image}
              style={{
                height: 150,
                width: "150px",
                borderRadius: "100px",
                objectFit: "cover",
                marginLeft: 20,
                background: "white",
                marginTop: "-60px",
                border: "6px solid white",
              }}
            />
            <div style={{ marginLeft: 10 }}>
              <HeaderText style={{ fontFamily: "Outfit" }}>
                {profile.name}
              </HeaderText>
              <HeaderText
                style={{
                  fontFamily: "Outfit",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                {profile.industry} . {profile.location} .{" "}
                {formatNumber(profile.followers)} followers . {formatNumber(profile.employee)} employees
              </HeaderText>
            </div>
            <div style={{ marginLeft: "auto" }}>
              <ShareIcon style={{ color: colors.primary }} />
              <NotificationsIcon
                style={{ color: colors.primary, marginLeft: 20 }}
              />
            </div>
          </div>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <CardWrapper className={"displayContainer"}>
               
                <MenuCard menu={menuItems} style={{ marginRight: "10px" }} />
              </CardWrapper>
            </Grid>

            <Grid item xs={12} sm={8}>
           
           
              <CardWrapper style={{ marginLeft: "20px", marginTop: "10px" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={
                      "https://img.freepik.com/premium-vector/company-flat-vector-icon_9206-336.jpg"
                    }
                    style={{ height: 56, width: 56 }}
                  />
                  <div style={{ marginLeft: 10 }}>
                    <HeaderText style={{ fontFamily: "Outfit" }}>
                      About
                    </HeaderText>
                    <HeaderText
                      style={{
                        fontFamily: "Outfit",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      About Company
                    </HeaderText>
                  </div>
                  <Button
                    onClick={() => navigate("/editCompanyProfile")}
                    variant="outlined"
                    style={{
                      marginLeft: "auto",
                      border: `1px solid ${colors.primary}`,
                      color: colors.primary,
                      textTransform: "none",
                      fontFamily: "Outfit",
                    }}
                  >
                    More
                  </Button>
                </div>
                <HeaderText
                  style={{
                    fontFamily: "Outfit",
                    fontSize: "14px",
                    fontWeight: "400",
                    marginTop: "10px",
                  }}
                >
                  {profile.companyDescription}
                </HeaderText>
              </CardWrapper>
              <CardWrapper style={{ marginLeft: "20px", marginTop: "10px" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={
                      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQb5j32PC3fuBaQSqqyqrpuAc8ShNFWAnuJia4JANmS-Nxjk7INgOGiGwzL9zkEAdDq-3Y&usqp=CAU"
                    }
                    style={{ height: 56, width: 56 }}
                  />
                  <div style={{ marginLeft: 10 }}>
                    <HeaderText style={{ fontFamily: "Outfit" }}>
                      Jobs
                    </HeaderText>
                    <HeaderText
                      style={{
                        fontFamily: "Outfit",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      Available job lisiting
                    </HeaderText>
                  </div>
                  <Button
                    variant="outlined"
                    style={{
                      marginLeft: "auto",
                      border: `1px solid ${colors.primary}`,
                      color: colors.primary,
                      textTransform: "none",
                      fontFamily: "Outfit",
                    }}
                  >
                    More
                  </Button>
                </div>
                <SampleJobCard
                  jobListing={profile.jobs}
                  // title={"Jobs for you"}
                  // button={"All Jobs"}
                  showAll
                />
              </CardWrapper>
            </Grid>
          </Grid>
        </div>
      )}
    </Layout>
  );
};

export default CompanyProfile;
