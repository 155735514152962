import React from "react";
import CardWrapper from "../CardWrapper";
import HeaderText from "../../HeaderText";
import ArrowCircleUpSharpIcon from "@mui/icons-material/ArrowCircleUpSharp";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import TimelineSharpIcon from "@mui/icons-material/TimelineSharp";
const AnylyticsCard = ({ title, subtitle, value, up,percentage }) => {
  return (
    <CardWrapper>
      <div
        style={{
          marginLeft: 10,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <HeaderText style={{ fontFamily: "Outfit" }}>{title}</HeaderText>
          <HeaderText style={{ fontSize: 12 }}>{subtitle}</HeaderText>
        </div>
        <div style={{ marginLeft: 10, display: "flex", alignItems: "center" }}>
          {/* <HeaderText style={{ fontFamily: "Outfit",fontSize:30 }}>4029</HeaderText> */}
          <MoreHorizIcon
            style={{
              color: "#0049FC",
              marginLeft: 20,
              marginRight: 20,
              fontSize: 32,
            }}
          />
        </div>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <HeaderText style={{ fontFamily: "Outfit", fontSize: 30, margin: 10 }}>
          {value}
        </HeaderText>
        {up && (
          <div
            style={{
              background: "#EAFBE7",
              display: "flex",
              borderRadius: 100,
              alignItems: "center",
              padding: 4,
            }}
          >
            <HeaderText style={{ fontSize: 10, fontFamily: "Outfit" }}>
             {percentage}%
            </HeaderText>
            <ArrowCircleUpSharpIcon
              style={{ fontSize: 18, marginLeft: 2, color: "#13D427" }}
            />
          </div>
        )}
        {up && (
          <TimelineSharpIcon style={{ color: "#0049FC", marginLeft: 10 }} />
        )}
      </div>
    </CardWrapper>
  );
};

export default AnylyticsCard;
