import React from "react";
import CardWrapper from "../CardWrapper";
import HeaderText from "../../HeaderText";
import ArrowCircleUpSharpIcon from "@mui/icons-material/ArrowCircleUpSharp";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import TimelineSharpIcon from "@mui/icons-material/TimelineSharp";
import { Grid } from "@mui/material";
import EmployeeCard from "../EmployeeCard";
const ProfileViewers = ({ title, subtitle, users,onClick }) => {
  return (
    <CardWrapper>
      <div
      
        style={{
          marginLeft: 10,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <HeaderText style={{ fontFamily: "Outfit" }}>{title}</HeaderText>
          <HeaderText style={{ fontSize: 12 }}>{subtitle}</HeaderText>
        </div>
        <div style={{ marginLeft: 10, display: "flex", alignItems: "center" }}>
          {/* <HeaderText style={{ fontFamily: "Outfit",fontSize:30 }}>4029</HeaderText> */}
          <MoreHorizIcon
            style={{
              color: "#0049FC",
              marginLeft: 20,
              marginRight: 20,
              fontSize: 32,
            }}
          />
        </div>
      </div>
      
      <Grid container>
        {users.map((item, index) => (
          <Grid item xs={12} sm={6}>
            <EmployeeCard  flex  onClick={onClick} item={item} />
          </Grid>
        ))}
      </Grid>
    </CardWrapper>
  );
};

export default ProfileViewers;
