import React, { useEffect, useState } from "react";
import StudentDashboard from "../../Layout/StudentDashboard";
import { useNavigate } from "react-router-dom";
import MyJobDetails from "../../components/Card/MyJobDetails";
import Layout from "../../components/Layout/Layout";
import { getJobDetails, getStudentAllJobs } from "../../ApiCalls";

const ApplicationDetails = () => {
  const navigate = useNavigate();
  const [jobDetails,setJobDetails]=useState({});

  useEffect(()=>{
   
    let result2=getJobDetails()
    setJobDetails(result2)
  },[])
  
  return <Layout>
    {jobDetails&&<MyJobDetails  jobDetails={jobDetails} />}
  </Layout>;
};

export default ApplicationDetails;
