import React from "react";
import Layout from "../components/Layout/Layout";
import { Avatar, Button, Divider, Grid, Input, TextField } from "@mui/material";
import CardWrapper from "../components/Card/CardWrapper";
import Header from "../components/Header/Header";
import HeaderText from "../components/HeaderText";
import StudentDashboard from "../Layout/StudentDashboard";

import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import BreadCumb from "../components/BradCumb";
import { Search } from "@mui/icons-material";
import CircleIcon from "@mui/icons-material/Circle";
import { colors } from "../constants/colors";
import EmployeeCard from "../components/Card/EmployeeCard";
import { useNavigate } from "react-router-dom";
const Chat = () => {
  let employees = [
    {
      name: "John Doe",
      companyName: "ABC Inc.",
      position: "Developer",
      unread: 20,
    },
    {
      name: "Jane Smith",
      companyName: "XYZ Corp.",
      position: "Designer",
    },
    {
      name: "Bob Johnson",
      companyName: "123 Industries",
      position: "Manager",
    },
  ];
  const navigate=useNavigate()
  const navigationdata = [
    {
      icon: (
        <ChatBubbleIcon
          style={{ marginRight: 5, color: "0049FC", fontSize: 18 }}
        />
      ),
      name: "Chat",
      route: "/",
    },
    {
      name: "StellarTech Solutions",
      route: "?",
    },
  ];

  const chatConversation = [
    {
      sender: "user",
      message: "Hi there! How's it going?",
    },
    {
      sender: "assistant",
      message: "Hello! I'm here to help. Everything is going well, thank you!",
    },
    {
      sender: "user",
      message: "Great! I have a question about programming.",
    },
    {
      sender: "assistant",
      message:
        "Sure thing! Feel free to ask your programming question, and I'll do my best to assist you.",
    },
    {
      sender: "user",
      message:
        "I'm having trouble with a JavaScript loop. It's not working as expected.",
    },
    {
      sender: "assistant",
      message:
        "I'd be happy to help you with your JavaScript loop issue. Could you provide more details about what's happening?",
    },
    {
      sender: "assistant",
      message:
        "I'd be happy to help you with your JavaScript loop issue. Could you provide more details about what's happening?",
    },
  ];

  return (
    <Layout>
      <BreadCumb data={navigationdata} />

      <Grid container sx={{ marginTop: "20px" }}>
        <Grid  xs={12} sm={4}>
          <CardWrapper
            style={{
              margin: 10,
              height: 600,
              padding: "20px",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                marginBottom: 10,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <HeaderText style={{ fontFamily: "Outfit" }}>
                  Messages
                </HeaderText>
                <HeaderText style={{ fontSize: 12, color: colors.primary }}>
                  20 unread Messages
                </HeaderText>
              </div>
              <div
                style={{
                  marginLeft: 10,
                  display: "flex",
                  alignItems: "center",
                }}
              ></div>
            </div>
            <TextField
              size="small"
              fullWidth
              placeholder="Search message"
              type="search"
              sx={{ marginBottom: "10px" }}
              icon={<Search />}
            />
            <div
              style={{ height: 550, overflow: "scroll" }}
              className="example"
            >
              {employees.map((item, index) => (
                <EmployeeCard item={item} chat flex onClick={()=>{
                  navigate("/Chat/1")
                }} />
              ))}
            </div>
          </CardWrapper>
        </Grid>
        <Grid  xs={12} sm={8}>
        <div className="displayContainer">
          <CardWrapper style={{ margin: 10, height: "100%", height: 600 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {" "}
                <img
                  src="https://writestylesonline.com/wp-content/uploads/2018/11/Three-Statistics-That-Will-Make-You-Rethink-Your-Professional-Profile-Picture-1024x1024.jpg"
                  style={{
                    height: "40px",
                    width: "40px",
                    borderRadius: "100px",
                    marginRight: "10px",
                  }}
                />
                <div>
                  <HeaderText style={{ fontSize: 18, fontFamily: "Outfit" }}>
                    Jon Doe
                  </HeaderText>
                  <div style={{ display: "flex" }}>
                    <CircleIcon style={{ color: "#77eb34", fontSize: 12,marginTop:2 }} />{" "}
                    <HeaderText style={{ fontSize: 10, fontFamily: "Outfit" }}>
                      Online
                    </HeaderText>
                  </div>
                </div>
              </div>
           
            </div>
          
            <div style={{ height: "100%" }}>
              <div
          
                className="example"
                style={{ height: 480, overflow: "scroll", background: "#FAFAFA",padding:10,marginTop:10,borderRadius:10 }}
              >
                {chatConversation.map((item, index) => (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      style={{
                        padding: 10,
                        maxWidth: 250,
                        background:
                          item.sender == "user" ? colors.secondary :colors.primary,
                        color: item.sender == "user" ? "black" : "white",
                        borderTopLeftRadius: 10,
                        borderBottomRightRadius: 10,
                        margin: 5,
                        marginLeft: item.sender == "user" ? "auto" : 5,
                      }}
                    >
                      {item.message}
                    </div>
                  </div>
                ))}
              </div>
              <div style={{ display: "flex", flexDirection: "row",alignItems:"center" }}>
                <TextField fullWidth size="small" placeholder="type here ..." />
                <Button
                  variant="contained"
                  sx={{
                    margin: "5px",

                    width: 100,
                    background: colors.primary,
                    fontWeight: "bold",
                    fontFamily: "Quicksand",
                    textTransform: "none",
                  }}
                >
                  Send
                </Button>
              </div>
            </div>
          </CardWrapper></div>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Chat;
