import React from "react";
import CardWrapper from "../CardWrapper";
import { Avatar, Button } from "@mui/material";
import HeaderText from "../../HeaderText";
import { useNavigate, useNavigation } from "react-router-dom";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";
import moment from "moment/moment";
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import StarIcon from '@mui/icons-material/Star';
import CancelIcon from '@mui/icons-material/Cancel';
const PlacementCard = ({ item,onClick }) => {
  const navigate = useNavigate();
  return (
    <div className="employee" onClick={onClick} style={{ padding: 10,cursor:"pointer" }}>
      <div
        container
        style={{ alignItems: "center", width: "100%", display: "flex",borderRadius:10 }}
        spacing={[2, 2]}
      >
        <div item xs={2}>
          <img
            alt="Remy Sharp"
            src={item.image}
            style={{
              width: "56px",
              height: "56px",
              border: "0.25px solid lightgrey",
              borderRadius: 8,
              padding: 5,
            }}
          />
        </div>
        <div item xs={10}>
          <div style={{ width: "100%", marginLeft: 10 }}>
            <HeaderText style={{ fontSize: 16, fontFamily: "Outfit" }}>
              {item.jobTitle}
            </HeaderText>
            <div style={{ display: "flex" }}>
              <HeaderText
                style={{
                  fontSize: 12,
                  color: "black",
                  fontFamily: "Outfit",
                  fontWeight: "500",
                }}
              >
                {item.companyName}
              </HeaderText>
              <HeaderText
                style={{
                  fontSize: 12,
                  marginLeft: 10,
                  color: "grey",
                  fontFamily: "Outfit",
                  fontWeight: "500",
                }}
              >
                {item.Location || "Delhi India"}
              </HeaderText>
            </div>
            
            {item.status ? (
              <HeaderText
                style={{
                  fontSize: 14,
                  fontFamily: "Outfit",
                  color: "black",
                  cursor: "pointer",
                  fontWeight: "500",
                  display: "flex",
                  // alignItems: "center",
                }}
              >
               {item.status=="Applied"&& <CheckCircleSharpIcon
                  style={{ color: "#07e315", fontSize: 16,marginTop:2,marginRight:5}}
                />}
                {item.status=="Upcoming"&&<LibraryAddCheckIcon style={{ color: "#00CBFE", fontSize: 18,marginTop:2,marginRight:5}}/>}
                {item.status=="Short Listed"&&<AutoAwesomeIcon style={{ color: "#6e34eb", fontSize: 18,marginTop:2,marginRight:5}}/>}
                {item.status=="Interview"&&<StarIcon style={{ color: "#77eb34", fontSize: 18,marginTop:2,marginRight:5}}/>}
                {item.status=="Completed"&&<CancelIcon style={{ color: "red", fontSize: 18,marginTop:2,marginRight:5}}/>}
                
                {item.status} on {moment(item.statusUpadeted).format("MMMM DD, YYYY")}
              </HeaderText>
            ) : (
              <div   onClick={()=>navigate("/jobDetails")}>
              <HeaderText
            
                style={{
                  fontSize: 14,
                  fontFamily: "Outfit",
                  color: "#0049FC",
                  cursor: "pointer",
                }}
              >
                View Jobs
              </HeaderText>
              </div>
            )}{" "}
          </div>
        </div>
        {/* <div item xs={4}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Button
            onClick={()=>navigation("JobDetails")}
              variant="contained"
              sx={{
                margin: "5px",
                marginLeft: "auto",
                width: 80,
                background: "black",
                fontWeight: "bold",
                fontFamily: "Quicksand",
                textTransform: "none",
              }}
            >
              {owner?"Edit":"Apply"}
            </Button>
            <Button
              variant="outlined"
              sx={{
                margin: "5px",
                marginLeft: "auto",
                width: 80,
                border: "1px solid black",
                fontWeight: "bold",
                fontFamily: "Quicksand",
                color: "black",
                textTransform: "none",
              }}
            >
            {owner?"Delete":"Save"}
            </Button>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default PlacementCard;
