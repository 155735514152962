import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import HeaderText from "../../components/HeaderText";
import rawData from "../../components/RawData/jobs.json";
import { Grid, Rating } from "@mui/material";
import CardWrapper from "../../components/Card/CardWrapper";
import { colors } from "../../constants/colors";
import { useNavigate } from "react-router-dom";
import { getCourses } from "../../ApiCalls";
import { formatNumber } from "../../utils/ConvertorFunctions";
import OpeningCard from "./OpeningCard";
const StudentCourses = () => {
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [opening, setOpenings] = useState([]);
  useEffect(() => {
    const result = getCourses();
    setCourses(result.courses);
    setOpenings(result.openings);
  }, []);
  return (
    <Layout>
      <Grid container>
        <Grid item xs={8}>
          <div style={{ marginTop: "20px" }}>
            <HeaderText style={{ fontFamily: "Outfit" }}>
              A broad selection of courses
            </HeaderText>
            <HeaderText
              style={{
                fontFamily: "Outfit",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              Choose from over 210,000 online video courses with new additions
              published every month
            </HeaderText>
            <div></div>
            <Grid container>
              {courses?.map((item) => (
                <Grid
                  xs={3}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginRight: "10px",
                    marginTop: "10px",
                  }}
                >
                  <CardWrapper
                    onClick={() => {
                      navigate("/training/1");
                    }}
                    style={{
                      flexDirection: "column",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      src={item.poster}
                      style={{
                        width: "100%",
                        height: "150px",
                        borderRadius: "10px",
                      }}
                    />
                    <HeaderText
                      style={{
                        fontFamily: "Outfit",
                        fontSize: "14px",
                        maxWidth: "80%",
                      }}
                    >
                      {item.name.length > 30
                        ? item.name.slice(0, 30) + "..."
                        : item.name}
                    </HeaderText>
                    <HeaderText
                      style={{
                        fontFamily: "Outfit",
                        fontSize: "12px",
                        maxWidth: "80%",
                        fontWeight: "500",
                      }}
                    >
                      {item.author}
                    </HeaderText>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: 10,
                      }}
                    >
                      <HeaderText
                        style={{
                          fontFamily: "Outfit",
                          fontSize: "12px",
                          maxWidth: "80%",
                        }}
                      >
                        {item.star}
                      </HeaderText>
                      <Rating
                        style={{ fontSize: "14px" }}
                        name="read-only"
                        precision={0.5}
                        value={item.star}
                        readOnly
                      />
                      <HeaderText
                        style={{
                          fontFamily: "Outfit",
                          fontSize: "12px",
                          maxWidth: "80%",
                        }}
                      >
                        {formatNumber(item.review)}
                      </HeaderText>{" "}
                    </div>
                    <div>
                      <div>
                        <HeaderText
                          style={{
                            fontFamily: "Outfit",
                            fontSize: "14px",
                            maxWidth: "80%",
                          }}
                        >
                          {" "}
                          ₹ {item.price}
                        </HeaderText>
                      </div>
                      <HeaderText
                        style={{
                          fontFamily: "Outfit",
                          fontSize: "12px",
                          maxWidth: "80%",
                          background: "red",
                          marginTop: "10px",
                          textAlign: "center",
                          color: "white",
                          borderRadius: "100px",
                          fontWeight: "500",
                          width: "80px",
                        }}
                      >
                        Bestseller
                      </HeaderText>
                    </div>
                  </CardWrapper>
                </Grid>
              ))}
            </Grid>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div style={{ marginTop: "20px" }}>
            <HeaderText style={{ fontFamily: "Outfit", marginLeft:"10px"  }}>
              Top Compnaies Course
            </HeaderText>
            <HeaderText
              style={{
                fontFamily: "Outfit",
                fontSize: "16px",
                fontWeight: "500",
                marginLeft:"10px"
              }}
            >
              Choose course and get more chance to hire
            </HeaderText>
            <CardWrapper style={{    }}>
              {opening?.map((item) => (
                <OpeningCard item={item} />
              ))}
            </CardWrapper>
          </div>
         
        </Grid>
      </Grid>
    </Layout>
  );
};

export default StudentCourses;
