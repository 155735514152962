import React from "react";
import CardWrapper from "../CardWrapper";
import HeaderText from "../../HeaderText";
import { Tag } from "@mui/icons-material";
import TimelineIcon from "@mui/icons-material/Timeline";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import FaceIcon from "@mui/icons-material/Face";
import { Button, Chip, Divider, Grid, Stack } from "@mui/material";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import HomeIcon from "@mui/icons-material/Home";
import ShareIcon from "@mui/icons-material/Share";
import MailIcon from "@mui/icons-material/Mail";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { colors } from "../../../constants/colors";
import { useNavigate } from "react-router-dom";
import EmployeeCard from "../EmployeeCard";
const DriveDetailsCard = ({ jobDetails,applied,job }) => {
  const navigate = useNavigate();
  return (
    <CardWrapper style={{ marginTop: 20, padding: 50 }}>
     <div
        className="maincontainer"
      >
        <div style={{ flex: 1 }}>
          {" "}
          <HeaderText style={{ fontSize: 24, marginLeft: 8, marginBottom: 10 }}>
          {job?.jobTitle||"  English Instructor (Online Teaching)"}
          </HeaderText>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={
                job?.image||"https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png"
              }
              style={{ height: 30, objectFit: "contain", width: 30 }}
            />
            <HeaderText style={{ fontSize: 14 }}>{job?.companyName||"Google"}</HeaderText>
            <HeaderText style={{ fontSize: 14, color: "grey", marginLeft: 10 }}>
              {job?.location||"Dhaka, Bangladesh"}
            </HeaderText>
          </div>
        </div>
        <div  style={{ display: "flex", marginTop: 10, cursor: "pointer" }}>
          <MailIcon style={{ color: colors.primary }} />
          <ShareIcon style={{ color: colors.primary, marginLeft: 20 }} />
          <MoreHorizIcon style={{ color: colors.primary, marginLeft: 20 }} />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <HeaderText
          style={{
            fontSize: 14,
            marginLeft: 10,
            fontWeight: 400,
            color: "grey",
            fontFamily: "Outfit",
          }}
        >
          Drive on 20 jan 2023
        </HeaderText>
      
      </div>
    {!applied&& <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: 10,
          // justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          onClick={() => navigate("/Resume")}
          variant="contained"
          sx={{
            margin: "5px",
            width: 80,
            borderRadius: "8px",
            background: "#0049FC",
            fontWeight: "bold",
            fontFamily: "Quicksand",
            textTransform: "none",
          }}
        >
          Apply
        </Button>
        <Button
          variant="outlined"
          sx={{
            margin: "5px",
            borderRadius: "8px",
            border: "1px solid #0049FC",
            fontWeight: "bold",
            fontFamily: "Quicksand",
            color: "#0049FC",
            textTransform: "none",
          }}
        >
          Save Job
        </Button>
      </div>}
      {applied&& <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: 10,
          // justifyContent: "center",
          alignItems: "center",
        }}
      >
      
      
      </div>}
      <div
        style={{
          display: "flex",
          border: "1px solid grey",

          marginTop: 20,
          borderRadius: "8px",
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <EmployeeCard
              profile
              item={{
                name: "John Doe",
                companyName: "ABC Inc.",
                position: "HR Manager",
              }}
            />
          </Grid>
        </Grid>
      </div>
      <HeaderText style={{ fontSize: 14, marginTop: "20px" }}>
        About Job
      </HeaderText>
     {jobDetails?.responsibilities.map((item, index) => (
        <HeaderText
          key={index}
          style={{
            fontSize: 14,
            marginLeft: 20,
            color: "black",
            marginTop: "5px",
          }}
        >
          {index + 1 + " "}. {item}
        </HeaderText>
      ))}
        <HeaderText style={{ fontSize: 14, marginTop: "20px", color: "black" }}>
        Who can apply:
      </HeaderText>
      {jobDetails.qualifications.map((item, index) => (
        <HeaderText
          key={index}
          style={{
            fontSize: 14,
            color: "black",
            marginTop: "5px",
            marginLeft: 20,
          }}
        >
          {index + 1 + " "}. {item}
        </HeaderText>
      ))}{" "}
     <HeaderText style={{ fontSize: 14, marginTop: "20px", color: "black" }}>
        Additional Information:
      </HeaderText>
     {jobDetails.additionalInfo.map((item, index) => (
        <HeaderText
          key={index}
          style={{
            fontSize: 14,
            color: "black",
            marginTop: "5px",
            marginLeft: 20,
          }}
        >
          {index + 1 + " "}. {item}
        </HeaderText>
      ))}
        <div
        style={{
          display: "flex",
          border: "1px solid grey",
          padding: 10,
          marginTop: 20,
          borderRadius: "8px",
          flexDirection: "column",
          flexWrap:"wrap"
        }}
      >
        <div  style={{ display: "flex", width: "100%",  flexWrap:"wrap" }}>
          <img
            src={job?.image||"https://lh3.googleusercontent.com/wAPeTvxh_EwOisF8kMR2L2eOrIOzjfA5AjE28W5asyfGeH85glwrO6zyqL71dCC26R63chADTO7DLOjnqRoXXOAB8t2f4C3QnU6o0BA"}
            style={{ height: "48px", width: "48px", objectFit: "contain" }}
          />
          <div style={{ marginLeft: "20px" }}>
            <HeaderText style={{ fontSize: 16 }}>{job?.companyName||"Grameenphone Ltd."}</HeaderText>
            <div className="maincontainer" style={{ display: "flex"}}>
              <HeaderText
                style={{ fontWeight: 400, color: "grey", fontSize: 14 }}
              >
                Telecommunications
              </HeaderText>
              <HeaderText
                style={{
                  fontWeight: 400,
                  color: "grey",
                  fontSize: 14,
                  marginLeft: 10,
                }}
              >
                6,424 employees
              </HeaderText>
              <HeaderText
                style={{ color: "#13D427", fontSize: 14, marginLeft: 10 }}
              >
                Actively Hiring
              </HeaderText>
            </div>
          </div>
          <HeaderText
            onClick={() => navigate("/CompanyProfile")}
            style={{
              color: colors.primary,
              marginLeft: "auto",
              fontWeight: "600",
              fontSize: 14,
              cursor: "pointer",
            }}
          >
            View Page
          </HeaderText>
        </div>
        <div style={{ marginTop: 10 }}>
          <HeaderText
            style={{
              fontWeight: "500",
              color: "grey",
              fontSize: 14,
            }}
          >
            The right and contemporary use of technology is key to the progress
            of a nation. Keeping this in mind, Grameenphone always brings
            future-proof technology in order to facilitate your progress. The
            possibilities in this new world are immense and someone as bright as
            you should be the forerunner in leading the change...{" "}
          </HeaderText>
          <HeaderText
            style={{
              color: colors.primary,
              fontSize: 14,
            }}
          >
            see more
          </HeaderText>
        </div>
      </div> 
    </CardWrapper>
  );
};

export default DriveDetailsCard;
