import React from "react";
import CardWrapper from "../components/Card/CardWrapper";
import HeaderText from "../components/HeaderText";
import { Button, TextField } from "@mui/material";

import CircleIcon from "@mui/icons-material/Circle";
import { colors } from "../constants/colors";
import { useState } from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { BackHand } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
const chatConversation = [
  {
    sender: "user",
    message: "Hi there! How's it going?",
  },
  {
    sender: "assistant",
    message: "Hello! I'm here to help. Everything is going well, thank you!",
  },
  {
    sender: "user",
    message: "Great! I have a question about programming.",
  },
  {
    sender: "assistant",
    message:
      "Sure thing! Feel free to ask your programming question, and I'll do my best to assist you.",
  },
  {
    sender: "user",
    message:
      "I'm having trouble with a JavaScript loop. It's not working as expected.",
  },
  {
    sender: "assistant",
    message:
      "I'd be happy to help you with your JavaScript loop issue. Could you provide more details about what's happening?",
  },
  {
    sender: "assistant",
    message:
      "I'd be happy to help you with your JavaScript loop issue. Could you provide more details about what's happening?",
  },
];
const ChatMobile = () => {
  const [message, setMessage] = useState("");

  const [conversion, setConversion] = useState(chatConversation);
  const navigate=useNavigate();
  return (
    <div>
      <CardWrapper style={{ margin: 10, height: "100%", height: "98vh" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
          <ArrowBackIcon onClick={()=>{navigate("/chat")}}/>
            <img
              src="https://writestylesonline.com/wp-content/uploads/2018/11/Three-Statistics-That-Will-Make-You-Rethink-Your-Professional-Profile-Picture-1024x1024.jpg"
              style={{
                height: "40px",
                width: "40px",
                borderRadius: "100px",
                marginRight: "10px",
              }}
            />
            <div>
              <HeaderText style={{ fontSize: 18, fontFamily: "Outfit" }}>
                Jon Doe
              </HeaderText>
              <div style={{ display: "flex" }}>
                <CircleIcon
                  style={{ color: "#77eb34", fontSize: 12, marginTop: 2 }}
                />{" "}
                <HeaderText style={{ fontSize: 10, fontFamily: "Outfit" }}>
                  Online
                </HeaderText>
              </div>
            </div>
          </div>
          <Button
            variant="contained"
            sx={{
              margin: "5px",

              // width: 200,
              background: colors.primary,
              fontWeight: "bold",
              // fontFamily: "Quicksand",
              textTransform: "none",
              borderRadius: 50,
            }}
          >
            View your Application
          </Button>
        </div>

        <div style={{ height: "100%" }}>
          <div
            className="example"
            style={{height:"80%",
            
              overflow: "scroll",
              background: "#FAFAFA",
              padding: 10,
              marginTop: 10,
              borderRadius: 10,
            }}
          >
            {conversion.map((item, index) => (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    padding: 10,
                    maxWidth: 250,
                    background:
                      item.sender == "user" ? colors.secondary : colors.primary,
                    color: item.sender == "user" ? "black" : "white",
                    borderTopLeftRadius: 10,
                    borderBottomRightRadius: 10,
                    margin: 5,
                    marginLeft: item.sender == "user" ? "auto" : 5,
                  }}
                >
                  {item.message}
                </div>
              </div>
            ))}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <TextField
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              fullWidth
              size="small"
              placeholder="type here..."
            />
            <Button
              variant="contained"
              onClick={() => {
                setConversion((item) => [
                  ...item,
                  {
                    sender: "user",
                    message,
                  },
                ]);
                setMessage("");
              }}
              sx={{
                margin: "5px",

                width: 100,
                background: colors.primary,
                fontWeight: "bold",
                fontFamily: "Quicksand",
                textTransform: "none",
              }}
            >
              Send
            </Button>
          </div>
        </div>
      </CardWrapper>
    </div>
  );
};

export default ChatMobile;
