import React from "react";
import CardWrapper from "../Card/CardWrapper";
import HeaderText from "../HeaderText";
import JobCard from "../Card/JobCard";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Button, Pagination } from "@mui/material";
const Alljobs = ({
  pagination,
  jobListing,
  title,
  applied,
  showAll,
  onButtonClick,
  onClick,
  owner,
  button,
  type,
}) => {
  return (
    <CardWrapper style={{ marginBottom: 30 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <HeaderText style={{ marginLeft: 10, fontFamily: "Outfit" }}>
          {title}
        </HeaderText>
        <HeaderText
        onClick={onButtonClick||(()=>{})}
          style={{
            fontSize: 14,
            color: "#0049FC",
            cursor: "pointer",
            fontFamily: "Outfit",
            marginRight: 10,
          }}
        >
          {button}
        </HeaderText>
      </div>
      {jobListing.map((item) => (
        <JobCard
          title={title}
          type={type}
          onClick={onClick}
          applied={applied || type}
          owner={owner}
          item={item}
        />
      ))}
      {pagination && (
        <Pagination count={5} variant="outlined" shape="rounded" />
      )}
      {!showAll && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            sx={{
              margin: "10px",
              marginLeft: "auto",
              width: 100,
              background: "black",
              fontWeight: "bold",
              marginBottom: 0,
              fontFamily: "Quicksand",
              textTransform: "none",
            }}
          >
            Show all
          </Button>
        </div>
      )}
    </CardWrapper>
  );
};

export default Alljobs;
