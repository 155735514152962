import React from "react";
import HeaderText from "../../../components/HeaderText";
import { ArrowDropDown, Timelapse } from "@mui/icons-material";
import { colors } from "../../../constants/colors";
import { Divider } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { useState } from "react";
const CourseContentCard = ({ item }) => {
  const [active, setActive] = useState(false);
  return (
    <div
      style={{
        background:item.status=="completed"?"rgb(244, 245, 255)":"white",
        width: "100%",
        marginTop: "10px",
        border: item.status=="completed"?"1px solid "+colors.primary:"1px solid lightgrey",
        padding: 10,
        borderRadius: "10px",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <HeaderText style={{ fontFamily: "Outfit", fontSize: "16px" }}>
            {" "}
            {item.title}
          </HeaderText>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Timelapse style={{ fontSize: 14, color: colors.primary }} />{" "}
            <HeaderText
              style={{
                fontFamily: "Outfit",
                fontSize: "12px",
                fontWeight: "400",
                marginLeft: "4px",
              }}
            >
              {item.time} min
            </HeaderText>
          </div>
        </div>
        {item.resource &&
          (!active ? (
            <ArrowDropDown
              onClick={() => setActive(true)}
              style={{ fontSize: 20, color: colors.primary, cursor: "pointer" }}
            />
          ) : (
            <ArrowDropUpIcon
              onClick={() => setActive(false)}
              style={{ fontSize: 20, color: colors.primary, cursor: "pointer" }}
            />
          ))}
      </div>
      {active && (
        <div style={{}}>
          <Divider style={{ marginTop: 5, marginBottom: 5 }}>
            <HeaderText style={{ fontFamily: "Outfit", fontSize: "10px" }}>
              Resources
            </HeaderText>
          </Divider>

          {item.resource.map((item) => (
            <a href={item.link} target="_blank">
              <HeaderText
                style={{
                  fontFamily: "Outfit",
                  fontSize: "12px",
                  fontWeight: "400",
                  marginLeft: "4px",
                }}
              >
                {item.title}
              </HeaderText>
            </a>
          ))}
        </div>
      )}
    </div>
  );
};

export default CourseContentCard;
