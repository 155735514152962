import React, { useState } from "react";
import Layout from "../components/Layout/Layout";

import { useNavigate, useParams } from "react-router-dom";
import BreadCumb from "../components/BradCumb";
import WindowIcon from "@mui/icons-material/Window";
import JobDetailsCard from "../components/Card/JobDetailsCard";
import { useEffect } from "react";
import rawData from "../components/RawData/jobs.json"
const JobDetails = () => {
  const navigate = useNavigate();
  const [job, setJob] = useState();
  const params=useParams()

 useEffect(()=>{
  setJob(rawData.jobs.filter(item=>item.id==params.id)[0])
  console.log(rawData.jobs.filter(item=>item.id==params.id)[0])
 },[])
  const jobDetails = {
    title: "Wizklub Educator",
    responsibilities: [
      "Facilitate learning for 6-14-year-old children using company programs",
      "Teach high-order thinking skills",
      "Conduct engaging and interactive sessions",
      "Track student performance and discuss progress with parents",
      "Conduct weekend classes between 3 pm to 8 pm",
    ],
    qualifications: [
      "Proficiency in English (knowledge of Hindi is an added advantage)",
      "Available from 4:00 pm to 8:00 pm, minimum 40 hours/month",
      "Mandatory availability on weekends",
      "Willingness to receive feedback for improvement",
      "Enthusiastic about new-age learning skills",
      "Minimum 1 year of teaching experience (preferred for grades 3 and above)",
      "Minimum 70% in UG/PG, graduate/post-graduate preferred",
      "Good internet connection",
      "Own a laptop/desktop with webcam",
      "Strong comprehension, critical thinking, reasoning, and problem-solving skills",
      "Good communication skills",
      "Candidates from arts, B.Ed, M.A, journalism, mass communication, and MA English can apply",
      "Female candidates required",
    ],
    additionalInfo: [
      "Interview process: HR interview",
      " post-assessment test (score > 6)",
      " role fitment call/screening round",
      " demonstration/final round (laptop mandatory)",
    ],
    skill: [
      "English Proficiency (Spoken)",
      " English Proficiency (Written)",
      "Online Teaching",
      " Teaching",
    ],
    certificate: [
      "English Proficiency (Spoken)",
      " English Proficiency (Written)",
    ],
    ctc: [
      "Fixed component: 80%",
      "Variable component: 10%",
      "Other incentives: 10%",
    ],
  };

  const navigationdata = [
    {
      icon: (
        <WindowIcon style={{ marginRight: 5, color: "0049FC", fontSize: 18 }} />
      ),
      name: "Dashboard",
      route: "/",
    },
    {
      name: "All Jobs",
      route: "/all_jobs",
    },
    {
      name: "Job Details",
      route: "/jobDetails",
    },
  ];
  return (
    <Layout>
      <BreadCumb data={navigationdata} />
   {job&&   <JobDetailsCard jobDetails={jobDetails} job={job} />}
    </Layout>
  );
};

export default JobDetails;
