import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layout/Layout";
import { Book, BookOnline } from "@mui/icons-material";
import BreadCumb from "../../../components/BradCumb";
import { Button, Divider, Grid, LinearProgress } from "@mui/material";
import CardWrapper from "../../../components/Card/CardWrapper";
import HeaderText from "../../../components/HeaderText";
import CourseContentCard from "./CourseContentCard";
import { colors } from "../../../constants/colors";
import { useLocation } from "react-router-dom";
import { getCourses } from "../../../ApiCalls";
import SwitchItems from "../../../components/SwitchItems";

const CourseDetails = () => {
  function convertToEmbedLink(youtubeLink) {
    return youtubeLink.replace(/watch\?v=/, "embed/");
  }
  const location = useLocation();
  const [courseDetail, setCourseDetails] = useState();
  const [active, setActive] = useState("Video");
  const navigationdata = [
    {
      icon: (
        <BookOnline style={{ marginRight: 5, color: "0049FC", fontSize: 18 }} />
      ),
      name: "Courses",
      route: "/",
    },
    {
      name: "The Complete Python Bootcamp From Zero to Hero",
      route: "/all_jobs",
    },
  ];
  useEffect(() => {
    let result = getCourses();
    setCourseDetails(result.courseDetails);
  }, []);
  return (
    <Layout>
      <BreadCumb data={navigationdata} />
      
      {courseDetail && (
        <Grid container spacing={[5, 5]}>
          <Grid item xs={8}>
            {active == "Video" && (
              <iframe
                allowFullScreen
                style={{
                  width: "100%",
                  borderRadius: 10,
                  marginTop: "10px",
                  overflow: "hidden",
                  border: "0px solid black",
                }}
                height="500"
                src={convertToEmbedLink(
                  "https://www.youtube.com/watch?v=kqtD5dpn9C8"
                )}
              ></iframe>
            )}
            {active == "PDF" && (
              <iframe
                src="https://media.geeksforgeeks.org/wp-content/cdn-uploads/20210101201653/PDF.pdf"
                style={{
                  width: "100%",
                  borderRadius: 10,
                  marginTop: "10px",
                  overflow: "hidden",
                  border: "0px solid black",
                }}
                height="500"
              />
            )}
            <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: 10,
          marginBottom: 10,
          zIndex:10,
        }}
      >
        <SwitchItems
          items={["Video", "PDF"]}
          style={{ justifyContent: "center" }}
          itemStyle={{ flex: "1" }}
          active={active}
          setActive={setActive}
        />
      </div>
            <div
              style={{
                marginTop: "20px",
                paddingBottom: "10px",
                borderBottom: "1px solid grey",
              }}
            >
              <HeaderText style={{ fontFamily: "Outfit" }}>
                About this Course
              </HeaderText>
              <HeaderText
                style={{
                  fontFamily: "Outfit",
                  marginTop: 10,
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                {courseDetail.about}
              </HeaderText>
            </div>
            <div
              style={{
                marginTop: "20px",
                paddingBottom: "10px",
                borderBottom: "1px solid grey",
              }}
            >
              <HeaderText style={{ fontFamily: "Outfit" }}>
                Certificates
              </HeaderText>
              <HeaderText
                style={{
                  fontFamily: "Outfit",
                  marginTop: 10,
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                Get placement cell certificate by completing entire course
              </HeaderText>
              <HeaderText
                style={{
                  fontFamily: "Outfit",
                  marginTop: 10,
                  fontSize: "14px",
                  fontWeight: "400",
                  color: colors.primary,
                  cursor: "pointer",
                }}
              >
                Claim Certificates
              </HeaderText>
            </div>
            <div
              style={{
                marginTop: "20px",
                paddingBottom: "10px",
                borderBottom: "1px solid grey",
              }}
            >
              <HeaderText style={{ fontFamily: "Outfit" }}>
                Description
              </HeaderText>
              <HeaderText
                style={{
                  fontFamily: "Outfit",
                  marginTop: 10,
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                {courseDetail.description}
              </HeaderText>
            </div>
          </Grid>
          <Grid item xs={4}>
            <CardWrapper style={{ marginTop: "10px" }}>
              <LinearProgress
                variant="determinate"
                value={50}
                color="success"
              />
              <HeaderText
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                  fontFamily: "Outfit",
                  fontSize: "14px",
                  textAlign: "right",
                }}
              >
                50% completed
              </HeaderText>
              <HeaderText style={{ fontFamily: "Outfit", fontSize: "16px" }}>
                Course Content
              </HeaderText>
              {courseDetail.content.map((item) => (
                <CourseContentCard item={item} />
              ))}
            </CardWrapper>
          </Grid>
        </Grid>
      )}
    </Layout>
  );
};

export default CourseDetails;
