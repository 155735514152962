import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";
import {
  Avatar,
  Button,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import HeaderText from "../../components/HeaderText";
import { colors } from "../../constants/colors";
import CardWrapper from "../../components/Card/CardWrapper";
import { Navigate, useNavigate } from "react-router-dom";
import Back from "../../components/Back";

const InviteMembers = () => {
  const navigate = useNavigate();
  let Students = [
    {
      image:
        "https://www.corporatephotographylondon.com/wp-content/uploads/2019/11/HKstrategies-1210-1024x683.jpg",
      name: "John Smith",
      age: 18,
      grade: 85,
    },
    {
      image:
        "https://www.corporatephotographylondon.com/wp-content/uploads/2019/11/HKstrategies-755-1-1024x683.jpg",
      name: "Alice Johnson",
      age: 17,
      grade: 92,
    },
    {
      image:
        "https://media.istockphoto.com/id/1329936184/photo/head-shot-portrait-of-smiling-businesswoman-intern-looking-at-camera.jpg?s=612x612&w=0&k=20&c=cCeWt0GYUtcFsGFi6hTFci-d5KZY6FAd_UchY9V5aSo=",
      name: "Bob Brown",
      age: 19,
      grade: 78,
    },
    {
      image:
        "https://media.istockphoto.com/id/1303206558/photo/headshot-portrait-of-smiling-businessman-talk-on-video-call.jpg?s=612x612&w=0&k=20&c=hMJhVHKeTIznZgOKhtlPQEdZqb0lJ5Nekz1A9f8sPV8=",
      name: "Emily Davis",
      age: 20,
      grade: 88,
    },
    {
      image:
        "https://media.istockphoto.com/id/1309328823/photo/headshot-portrait-of-smiling-male-employee-in-office.jpg?s=612x612&w=0&k=20&c=kPvoBm6qCYzQXMAn9JUtqLREXe9-PlZyMl9i-ibaVuY=",
      name: "David Wilson",
      age: 18,
      grade: 76,
    },
    {
      image:
        "https://www.corporatephotographylondon.com/wp-content/uploads/2019/11/HKstrategies-1029-1024x683.jpg",
      name: "Sarah Lee",
      age: 19,
      grade: 95,
    },
    {
      image:
        "https://www.corporatephotographylondon.com/wp-content/uploads/2019/11/Option-3-1024x683.jpg",
      name: "Michael Jones",
      age: 17,
      grade: 89,
    },
    {
      image:
        "https://img.freepik.com/free-photo/portrait-optimistic-businessman-formalwear_1262-3600.jpg?size=626&ext=jpg&ga=GA1.1.1413502914.1696982400&semt=ais",
      name: "Olivia Martinez",
      age: 20,
      grade: 91,
    },
  ];
  const [age, setAge] = useState();
  let engineeringDivisions = [
    "Civil ",
    "Mechanical ",
    "Electrical ",
    "Chemical ",
    "Computer ",
    "Aerospace ",
    "Biomedical ",
    "Environmental ",
    "Industrial ",
    "Materials ",
    "Petroleum ",
  ];
  return (
    <Layout>
      <Back/>
      <CardWrapper
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <TextField
          id="outlined-basic"
          label="Email"
          variant="outlined"
          size="small"
        />
        <Button
          onClick={() => navigate("/addProfile")}
          variant="contained"
          sx={{
            marginLeft: "5px",

            background: colors.primary,

            fontFamily: "Outfit",
            textTransform: "none",
          }}
        >
          Invite
        </Button>
        <Button
          onClick={() => navigate("/editProfile")}
          variant="contained"
          sx={{
            margin: "5px",
            marginLeft: "auto",
            marginTop: "10px",
            background: colors.primary,
            borderRadius: "100px",
            fontFamily: "Outfit",
            textTransform: "none",
          }}
        >
          Add People
        </Button>
      </CardWrapper>
      <div style={{ marginTop: 10 }}>
        <HeaderText
          style={{
            marginBottom: "20px",
            fontFamily: "Outfit",
          }}
        >
          Profiles
        </HeaderText>
        {Students?.map((item) => (
          <CardWrapper
            style={{
              marginBottom: 10,
              padding: 10,
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Avatar src={item.image}>{item.name.split("")[0]}</Avatar>
            <div>
              <HeaderText
                style={{
                  marginLeft: 20,
                  fontSize: "16px",
                  display: "flex",
                  fontFamily: "Outfit",
                }}
              >
                {item.name}
              </HeaderText>
              <HeaderText style={{ marginLeft: 20, fontSize: 10 }}>
                Junior HR
              </HeaderText>
            </div>
            <Button
              variant="contained"
              sx={{
                margin: "5px",
                marginLeft: "auto",
                marginTop: "10px",
                background: "red",
                // fontWeight: "bold",
                fontFamily: "Outfit",
                borderRadius: "100px",
                textTransform: "none",
              }}
            >
              Remove Access
            </Button>
          </CardWrapper>
        ))}
      </div>
    </Layout>
  );
};

export default InviteMembers;
