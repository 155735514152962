import React from "react";
import HeaderText from "../../components/HeaderText";

const UserProfile = ({profile,name,image}) => {
  return (
    <div
      style={{ display: "flex", alignItems: "center", flexDirection: "column",marginTop:20,marginBottom:20 }}
  >
      <div  style={{ position: 'relative' }}>
      <img
        src={
          profile|| "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?cs=srgb&dl=pexels-pixabay-220453.jpg&fm=jpg"
        }
        style={{ height: 88, width: 88, borderRadius: 100, objectFit: "cover" }}
      />
     </div>
      <HeaderText style={{fontSize:16,marginTop:10,fontWeight:600,padding:"10px",textAlign:"center"}}>Hello {name||"Anamoul"}</HeaderText>
    </div>
  );
};

export default UserProfile;
