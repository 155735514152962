import React from "react";
import CardWrapper from "../CardWrapper";
import LineChart from "./LineChart";
import HeaderText from "../../HeaderText";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
const GraphCard = ({title,type,value,graphData}) => {

  return (
    <CardWrapper style={{ marginBottom: 30 }}>
      <div
        style={{
          marginLeft: 10,
          display: "flex",
          justifyContent: "space-between",
          alignItems:"center"
        }}
      >
        <div>
          <HeaderText style={{fontFamily: "Outfit"}}>{title}</HeaderText>
          <HeaderText style={{ fontSize: 12 }}>(This Month)</HeaderText>
        </div>
        <div style={{ marginLeft: 10, display: "flex", alignItems:"center" }}>
        <HeaderText style={{ fontFamily: "Outfit",fontSize:30 }}>{graphData?.value||value||"4029"}</HeaderText>
        <MoreHorizIcon style={{color:"#0049FC",marginLeft:20,marginRight:20,fontSize:32}}/>
      </div>
      </div>
      
      <LineChart graphData={graphData}  type={type} />
    </CardWrapper>
  );
};

export default GraphCard;
