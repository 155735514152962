import React from "react";
import HeaderText from "../HeaderText";
import { Avatar } from "@mui/material";

import ShareIcon from "@mui/icons-material/Share";
import MailIcon from "@mui/icons-material/Mail";

import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

import Check from "@mui/icons-material/Check";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { styled } from "@mui/material/styles";
import {
  Button,
  Chip,
  Divider,
  Grid,
  Stack,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material";
const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

const steps = ["Completed Test", "Interview", "Get hired"];

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <SettingsIcon />,
    2: <GroupAddIcon />,
    3: <VideoLabelIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}
const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  }),
}));
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ApplicantCard = () => {

  const programmingLanguages = [
    "JavaScript",
    "Python",
    "C++",
    "Java",
    "Ruby",
    "Swift",
    "PHP",
    "C#",
    "Rust",
    "Go",
    "Kotlin",
    "TypeScript",
    "Perl",
    "Scala",
    "Haskell",
    "Lua",
    "Dart",
    "R",
    "MATLAB",
    "Objective-C",
    "COBOL",
    "Fortran",
    "Assembly",
  ];
  return (
    <div style={{ marginTop: 20 }}>
      
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <Avatar
          src="https://i.pinimg.com/564x/bd/da/b7/bddab779c1b5e0bded2f6e4face72dfd.jpg"
          size="large"
        >
          S
        </Avatar>
        <div style={{ marginLeft: 10 }}>
          <HeaderText style={{ fontSize: 18 }}>Ashwani Kumar</HeaderText>
          <HeaderText style={{ fontSize: 14, color: "grey" }}>
            9 years of exp • Gurgaon, India • 0.5 hours behind • Remote only
          </HeaderText>
        </div>
        <Button sx={{ marginLeft: "auto" }}>Resume</Button>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: 10,
          // justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Stack sx={{ width: "100%" }} spacing={4}>
          <Stepper
            alternativeLabel
            activeStep={1}
            connector={<ColorlibConnector />}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Stack>
      </div>
      <HeaderText
        style={{
          fontSize: 14,
          marginTop: 10,
          fontWeight: 300,
          fontFamily: "Outfit",
          fontWeight: "600",
        }}
      >
        Looking for
      </HeaderText>
      <HeaderText style={{ fontSize: 14, marginTop: 10, fontWeight: 300 }}>
        Three things that I’m looking for in my next job are a collaborative,
        team-focused culture, opportunities to learn and grow my skills from a
        technical standpoint, and a chance to learn more leadership skills over
        time
      </HeaderText>

      <HeaderText
        style={{
          fontSize: 14,
          marginTop: 10,
          fontWeight: 300,
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          fontFamily: "Outfit",
          fontWeight: "600",
        }}
      >
        Experience{" "}
        <p
          style={{
            color: "blue",
            fontWeight: "bold",
            fontFamily: "Quicksand",
          }}
        >
          - 4 more
        </p>
      </HeaderText>
      <div
        style={{
          marginTop: 0,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Avatar>G</Avatar>
        <div style={{ marginLeft: 10 }}>
          <HeaderText style={{ fontSize: 15 }}>Full Stack Developer</HeaderText>
          <HeaderText style={{ fontSize: 12 }}>Google</HeaderText>
          <HeaderText style={{ fontSize: 12, fontWeight: 300 }}>
            Feb 2020 to Present · 3 years 7 months
          </HeaderText>
        </div>
      </div>

      <HeaderText
        style={{
          fontSize: 14,
          marginTop: 10,
          fontWeight: 300,
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          fontFamily: "Outfit",
          fontWeight: "600",
        }}
      >
        Education{" "}
        <p
          style={{
            color: "blue",
            fontWeight: "bold",
            fontFamily: "Quicksand",
          }}
        >
          - 4 more
        </p>
      </HeaderText>
      <HeaderText
        style={{
          fontSize: 14,
          marginTop: 0,
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
        }}
      >
        Master's, Computer Science{" "}
        <p
          style={{
            color: "blue",
            fontWeight: "bold",
            fontFamily: "Quicksand",
          }}
        >
          - Himachal Pradesh University (2020-2023){" "}
        </p>
      </HeaderText>

      <HeaderText
        style={{
          fontSize: 14,
          marginTop: 10,
          fontFamily: "Outfit",
          fontWeight: "600",
        }}
      >
        Skills
      </HeaderText>
      <div style={{ display: "flex", flexWrap: "wrap", marginTop: "10px" }}>
        {programmingLanguages.map((item) => (
          <Chip
            label={item}
            style={{ margin: 2, borderRadius: 5, fontFamily: "Outfit" }}
            color="secondary"
          ></Chip>
        ))}
      </div>
      <div style={{ display: "flex", flexDirection: "row", marginTop: 20 }}>
        <Button
          variant="contained"
          sx={{
            margin: "5px",
            marginLeft: "auto",
            width: 80,
            background: "green",

            fontFamily: "Outfit",
            textTransform: "none",
          }}
        >
          Accept
        </Button>
        <Button
          variant="contained"
          sx={{
            margin: "5px",
            marginLeft: "10px",
            width: 80,
            background: "red",

            fontFamily: "Outfit",
            textTransform: "none",
          }}
        >
          Reject
        </Button>
      </div>
    </div>
  );
};

export default ApplicantCard;
