import React from "react";
import Layout from "../../components/Layout/Layout";
import CardWrapper from "../../components/Card/CardWrapper";
import HeaderText from "../../components/HeaderText";
import { Box, Button, Divider, Grid, Tab, Tabs } from "@mui/material";
import ApplicantCard from "../../components/Applicantcard";
import StudentDashboard from "../../Layout/StudentDashboard";
import { colors } from "../../constants/colors";
import { useNavigate } from "react-router-dom";
const CandiadateApplication = () => {
  const [value, setValue] = React.useState("one");
  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    console.log(newValue);
    setValue(newValue);
  };
  return (
    <Layout>
    <div>
      <Grid container>
        <Grid item xs={12} sm={12}>
          <div style={{ flex: 1, marginLeft: 20 }} className="displayContainer">
            <CardWrapper
              style={{ flex: 1,  overflow: "scroll" }}
              className="example"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div>
                  <HeaderText style={{ fontFamily: "Outfit" }}>
                    Full Stack Devloper
                  </HeaderText>{" "}
                  <HeaderText style={{ fontSize: 14, color: "grey" }}>
                    Pune
                  </HeaderText>
                </div>
                <Button
                  variant="contained"
                  sx={{
                    margin: "5px",
                    marginLeft: "auto",
                    width: 80,

                    borderRadius: "100px",
                    background: colors.primary,
                    // fontWeight: "bold",
                    fontFamily: "Outfit",
                    textTransform: "none",
                  }}
                >
                  Chat
                </Button>
              </div>

            
              <ApplicantCard />
            </CardWrapper>
          </div>
        </Grid>
      </Grid>
    </div></Layout>
  );
};

export default CandiadateApplication;
