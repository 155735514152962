import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import Layout from "../components/Layout/Layout";
import HeaderText from "../components/HeaderText";
import CardWrapper from "../components/Card/CardWrapper";
import { Alert, AlertTitle, Button, Chip, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import StudentDashboard from "../Layout/StudentDashboard";
import { colors } from "../constants/colors";
import { getStudentProfile, getStudentResume } from "../ApiCalls";
const Resume = () => {
  const navigate = useNavigate();

  const [user, setUser] = useState();
  const [resumeDetails,setResumeDetails]=useState();
  useEffect(() => {
    let result = getStudentProfile();
    setUser(result);
    const result2=getStudentResume();
    setResumeDetails(result2)
  }, []);

  return (
    <Layout>
     {user&& <div style={{ marginTop: 20, padding: 20 ,display:"flex",flexDirection:"column"}}>
        <Button
          onClick={() => navigate("/Application")}
          variant="contained"
          sx={{
            margin: "5px",
            float: "right",
            background: colors.primary,
            borderRadius:"10px",
            marginLeft:"auto",
            fontFamily: "Outfit",
            textTransform: "none",
          }}
        >
          Continue to details
        </Button>
      
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
              margin: 10,
              marginLeft: 0,
            }}
          >
            <HeaderText style={{ textAlign: "center", fontFamily: "Outfit" }}>
              Resume
            </HeaderText>
            <EditIcon style={{ color: "blue" }} />
          </div>
          <HeaderText style={{ fontSize: 18, marginTop: 3 }}>
            {user.name}
          </HeaderText>
          <HeaderText style={{ fontSize: 14, marginTop: 3 }}>
            {user.contact.phone}
          </HeaderText>
          <HeaderText style={{ fontSize: 14, marginTop: 3 }}>
            {user.contact.mobile}
          </HeaderText>
          <HeaderText style={{ fontSize: 14, marginTop: 3 }}>
            {user.location}
          </HeaderText>
     
        <Divider
          style={{
            marginTop: 20,
            marginBottom: 10,
            borderBottom: "2px solid grey",
          }}
        ></Divider>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            margin: 10,
            marginLeft: 0,
          }}
        >
          <HeaderText style={{ textAlign: "center" }}>EDUCATION</HeaderText>
          <EditIcon style={{ color: "blue" }} />
        </div>
        {resumeDetails.education.map((item, index) => (
          <div style={{ margin: 10 }}>
            <HeaderText style={{ fontSize: 16 }}>{item.degreee}</HeaderText>
            <HeaderText style={{ fontSize: 14, marginTop: 3 }}>
              {item.univercity}
            </HeaderText>
            <HeaderText style={{ fontSize: 14, marginTop: 3 }}>
              {item.year}
            </HeaderText>
            <HeaderText style={{ fontSize: 14, marginTop: 3 }}>
              {item.percetage}
            </HeaderText>
          </div>
        ))}
        <Divider
          style={{
            marginTop: 20,
            marginBottom: 10,
            borderBottom: "2px solid grey",
          }}
        ></Divider>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            margin: 10,
            marginLeft: 0,
          }}
        >
          <HeaderText style={{ textAlign: "center" }}>JOBS</HeaderText>
          <EditIcon style={{ color: "blue" }} />
        </div>
        {resumeDetails.jobs.map((item, index) => (
          <div style={{ margin: 10 }}>
            <HeaderText style={{ fontSize: 16 }}>{item.role}</HeaderText>
            <HeaderText style={{ fontSize: 14, marginTop: 3 }}>
              {item.company}
            </HeaderText>
            <HeaderText style={{ fontSize: 14, marginTop: 3 }}>
              {item.year}
            </HeaderText>
            <HeaderText style={{ fontSize: 14, marginTop: 3 }}>
              {item.desc}
            </HeaderText>{" "}
            {item.skills.map((item, index) => (
              <Chip
                key={index}
                label={item}
                sx={{
                  fontSize: 14,
                  margin: "5px",
                  fontFamily: "Quicksand",
                  fontWeight: 600,
                  color: "black",
                }}
              >
                {index + 1 + " "}. {item}
              </Chip>
            ))}
            <Divider style={{ margin: 10, marginLeft: 0 }} />
          </div>
        ))}
        <Divider
          style={{
            marginTop: 20,
            marginBottom: 10,
            borderBottom: "2px solid grey",
          }}
        ></Divider>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            margin: 10,
            marginLeft: 0,
          }}
        >
          <HeaderText style={{ textAlign: "center" }}>
            TRAININGS/ COURSES
          </HeaderText>
          <EditIcon style={{ color: "blue" }} />
        </div>
        {resumeDetails.courses.map((item, index) => (
          <div style={{ margin: 10 }}>
            <HeaderText style={{ fontSize: 16 }}>{item.title}</HeaderText>
            <HeaderText style={{ fontSize: 14, marginTop: 3 }}>
              {item.autorized}
            </HeaderText>
          </div>
        ))}
        <Divider
          style={{
            marginTop: 20,
            marginBottom: 10,
            borderBottom: "2px solid grey",
          }}
        ></Divider>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            margin: 10,
            marginLeft: 0,
          }}
        >
          <HeaderText style={{ textAlign: "center" }}>
            ACADEMICS/ PERSONAL PROJECTS
          </HeaderText>
          <EditIcon style={{ color: "blue" }} />
        </div>
        {resumeDetails.project.map((item, index) => (
          <div style={{ margin: 10 }}>
            <HeaderText style={{ fontSize: 16 }}>{item.name}</HeaderText>
            <HeaderText style={{ fontSize: 14, marginTop: 3 }}>
              {item.time}
            </HeaderText>
            {item.ref.map((item, index) => (
              <HeaderText
                style={{ fontSize: 14, marginTop: 3, color: "skyblue" }}
              >
                {item}
              </HeaderText>
            ))}
            <HeaderText style={{ fontSize: 14, marginTop: 3 }}>
              {item.desc}
            </HeaderText>
          </div>
        ))}{" "}
        <Divider
          style={{
            marginTop: 20,
            marginBottom: 10,
            borderBottom: "2px solid grey",
          }}
        ></Divider>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            margin: 10,
            marginLeft: 0,
          }}
        >
          <HeaderText style={{ textAlign: "center" }}>Skills</HeaderText>
          <EditIcon style={{ color: "blue" }} />
        </div>{" "}
        {resumeDetails.skills.map((item, index) => (
          <div style={{ margin: 10, display: "flex", flexDirection: "row" }}>
            <HeaderText style={{ fontSize: 16, flex: 1 }}>
              {item.title}
            </HeaderText>
            <HeaderText style={{ fontSize: 16, flex: 1 }}>
              {item.level}
            </HeaderText>
            <HeaderText style={{ fontSize: 16, flex: 1 }}>
              {item.experince} Years
            </HeaderText>
          </div>
        ))}
        <Divider
          style={{
            marginTop: 20,
            marginBottom: 10,
            borderBottom: "2px solid grey",
          }}
        ></Divider>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            margin: 10,
            marginLeft: 0,
          }}
        >
          <HeaderText style={{ textAlign: "center" }}>
            PORTFOLIO/ WORK SAMPLES
          </HeaderText>
          <EditIcon style={{ color: "blue" }} />
        </div>
        {resumeDetails.portfolio.map((item, index) => (
          <div style={{ margin: 10 }}>
            <HeaderText style={{ fontSize: 16 }}>{item.title}</HeaderText>
            <HeaderText
              style={{ fontSize: 14, marginTop: 3, color: "skyblue" }}
            >
              {item.link}
            </HeaderText>
          </div>
        ))}
        <Divider
          style={{
            marginTop: 20,
            marginBottom: 10,
            borderBottom: "2px solid grey",
          }}
        ></Divider>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            margin: 10,
            marginLeft: 0,
          }}
        >
          <HeaderText style={{ textAlign: "center" }}>
            {" "}
            ACCOMPLISHMENTS/ ADDITIONAL DETAILS
          </HeaderText>
          <EditIcon style={{ color: "blue" }} />
        </div>
        {resumeDetails.additionalInfo.map((item, index) => (
          <div style={{ margin: 10 }}>
            <HeaderText style={{ fontSize: 16 }}>{item}</HeaderText>
          </div>
        ))}
      </div>}
    </Layout>
  );
};

export default Resume;
