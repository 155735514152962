import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";

import WindowIcon from "@mui/icons-material/Window";
import BreadCumb from "../../components/BradCumb";
import HeaderText from "../../components/HeaderText";
import SwitchItems from "../../components/SwitchItems";
import CardWrapper from "../../components/Card/CardWrapper";
import { Avatar, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { colors } from "../../constants/colors";
const StudentProfile = () => {
    const menus = ["Current",  "Rejected"];
    const navigate=useNavigate()
    const [active, setActive] = useState("Current");
    let Students = [
        {
          image:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Google_%22G%22_logo.svg/1200px-Google_%22G%22_logo.svg.png",
          name: "Google",
          age: 18,
          status: "Applied",
          grade: 85,
        },
        {
          image:
            "https://1000logos.net/wp-content/uploads/2021/10/logo-Meta.png",
          name: "Meta",
          age: 17,
          grade: 92,
          status: "2nd Technical round",
        },
        {
          image:
            "https://static.vecteezy.com/system/resources/previews/020/335/987/non_2x/netflix-logo-netflix-icon-free-free-vector.jpg",
          name: "Netflix",
          age: 19,
          status: "2nd Technical Round",
          grade: 78,
        },
        {
          image:
            "https://cdn.icon-icons.com/icons2/2699/PNG/512/walmart_logo_icon_170230.png",
          name: "Walmart",
          status: "2nd Technical Round",
          age: 20,
          grade: 88,
        },
        {
          image:
            "https://i.pinimg.com/originals/01/ca/da/01cada77a0a7d326d85b7969fe26a728.jpg",
          name: "Amazon",
          age: 18,
          grade: 76,
          status: "Rejected",
        },
        {
          image:
            "https://static.vecteezy.com/system/resources/previews/020/336/451/non_2x/infosys-logo-infosys-icon-free-free-vector.jpg",
          name: "Infosys",
          status: "Rejected",
          age: 19,
          grade: 95,
        },
        {
          image:
            "https://png.pngtree.com/element_our/md/20180627/md_5b334611860fa.jpg",
          name: "Microsoft",
          age: 17,
          status: "Rejected",
          grade: 89,
        },
        {
          image:
            "https://i.pinimg.com/originals/15/77/30/1577300000e1168cd44d3e33e6446adc.png",
          name: "Teasla ",
          age: 20,
          status: "Rejected",
          grade: 91,
        },
      ];
    const navigationdata = [
        {
          icon: (
            <WindowIcon style={{ marginRight: 5, color: "0049FC", fontSize: 18 }} />
          ),
          name: "Dashboard",
          route: "/",
        },
        {
          name: "Student Mangment",
          route: "/all_jobs",
        },
        {
          name: "Student Profile",
          route: "/jobDetails",
        },
      ];
      let years = ["1st year ", "2nd year ", "3rd  year ", "Final Year "];
  return <Layout>
     <BreadCumb data={navigationdata} />
     <div  style={{ position: 'relative',marginTop:"20px",display:"flex",alignItems:"center" }}>
      <img
        src={
         "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?cs=srgb&dl=pexels-pixabay-220453.jpg&fm=jpg"
        }
        style={{ height: 88, width: 88, borderRadius: 100, objectFit: "cover" }}
      />
      <div style={{marginLeft:"20px"}}>
        <HeaderText style={{fontSize:16}}>Ajay Sharama</HeaderText>
        <HeaderText style={{fontSize:14}}>Computer 3rd Year</HeaderText>
      </div>
      
     </div>
     <div style={{ marginTop: 20, display: "flex", alignItems: "center" }}>
        <SwitchItems items={menus} active={active} setActive={setActive} />
      </div>
      <div style={{marginTop:20}}>
      {(active=="Rejected"?Students.filter(item=>item.status=="Rejected"):Students.filter(item=>item.status!="Rejected")).map((item) => (
            <CardWrapper
              style={{
                marginBottom: 10,
                padding: 10,
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Avatar src={item.image}>{item.name.split("")[0]}</Avatar>
              <div>
                <HeaderText
                  style={{
                    marginLeft: 20,
                    fontSize: "16px",
                    display: "flex",
                    fontFamily: "Outfit",
                  }}
                >
                  {item.name}
                </HeaderText>
                <HeaderText style={{ marginLeft: 20, fontSize: 10 }}>
                  {item.status}
                </HeaderText>
              </div>
              <Button
                onClick={() => navigate("/companyProfile")}
                variant="contained"
                sx={{
                  margin: "5px",
                  marginLeft: "auto",
                  marginTop: "10px",
                  background: colors.primary,
                  // fontWeight: "bold",
                  fontFamily: "Outfit",
                  borderRadius: "100px",
                  textTransform: "none",
                }}
              >
                Company Details
              </Button>
            </CardWrapper>
          ))}
      </div>
    </Layout>;
};

export default StudentProfile;
