import { Modal } from '@mui/material'
import React from 'react'
import HeaderText from '../HeaderText'
import CloseIcon from '@mui/icons-material/Close';
const ModalWrapper = ({open,handleClose,children,title}) => {
  return (
    <div id="myModal" style={{display:open?"flex":"none"}} class="modal">

 
    <div class="modal-content">
        <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
            <HeaderText style={{flex:1,fontFamily:"Outfit",fontSize:18}}>{title}</HeaderText>
                <CloseIcon onClick={handleClose} style={{cursor:"pointer"}}/>
        </div>
      {children}
    </div>
  
  </div>
  
  )
}

export default ModalWrapper