import React from 'react'
import CardWrapper from '../Card/CardWrapper'
import HeaderText from '../HeaderText'
import { Button, Divider, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { useState } from 'react'

const SkillsInfo = () => {
    const [age, setAge] = useState();
    const handleChange = () => {


    }
    return (
        <CardWrapper style={{ marginTop: 20 }}>
            <HeaderText>Skills Information</HeaderText>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            <div style={{ flexDirection: "row", display: "flex" }}>
                <TextField label="Skills Name" fullWidth sx={{marginRight:"10px"}}/><FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Experince</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={age}
                        label="Experince"
                        onChange={handleChange}
                    >
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                </FormControl>
            </div> <Button
         
          variant="contained"
          sx={{
            margin: "5px",
          
            width: 80,
            background: "black",
            fontWeight: "bold",
            fontFamily: "Quicksand",
            textTransform: "none",
          }}
        >
          Add
        </Button>
        </CardWrapper>
    )
}

export default SkillsInfo