import React from "react";
import CardWrapper from "../CardWrapper";
import HeaderText from "../../HeaderText";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Tag } from "@mui/icons-material";
import TimelineIcon from "@mui/icons-material/Timeline";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import FaceIcon from "@mui/icons-material/Face";
import {
  Button,
  Chip,
  Divider,
  Grid,
  Stack,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import HomeIcon from "@mui/icons-material/Home";
import ShareIcon from "@mui/icons-material/Share";
import MailIcon from "@mui/icons-material/Mail";

import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

import Check from "@mui/icons-material/Check";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { colors } from "../../../constants/colors";
import { useNavigate } from "react-router-dom";
// import EmployeeCard from "../EmployeeCard";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

const steps = ["Completed Test", "Interview", "Get hired"];

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <SettingsIcon />,
    2: <GroupAddIcon />,
    3: <VideoLabelIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}
const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  }),
}));
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const MyJobDetails = ({ jobDetails, status }) => {
  const navigate = useNavigate();
  return (
    <CardWrapper style={{ marginTop: 20, padding: 50 }}>
      <div
        style={{
          display: "flex",
          // alignItems: "center",
          flexDirection: "row",
        }}
      >
        <div style={{ flex: 1 }}>
          {" "}
          <HeaderText style={{ fontSize: 24, marginLeft: 8, marginBottom: 10 }}>
           {jobDetails?.title}
          </HeaderText>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={
                "https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png"
              }
              style={{ height: 30, objectFit: "contain", width: 30 }}
            />
            <HeaderText style={{ fontSize: 14 }}>Google</HeaderText>
            <HeaderText style={{ fontSize: 14, color: "grey", marginLeft: 10 }}>
              Dhaka, Bangladesh
            </HeaderText>
          </div>
        </div>
        <div style={{ display: "flex", marginTop: 10, cursor: "pointer" }}>
          <MailIcon style={{ color: colors.primary }} />
          <ShareIcon style={{ color: colors.primary, marginLeft: 20 }} />
          <MoreHorizIcon style={{ color: colors.primary, marginLeft: 20 }} />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <HeaderText
          style={{
            fontSize: 14,
            marginLeft: 10,
            fontWeight: 400,
            color: "grey",
            fontFamily: "Outfit",
          }}
        >
          Applied on May 12, 2012
        </HeaderText>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: 10,
          // justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Stack sx={{ width: "100%" }} spacing={4}>
          <Stepper
            alternativeLabel
            activeStep={1}
            connector={<ColorlibConnector />}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Stack>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          border: "1px solid grey",
          padding: "10px",
          marginTop: "20px",
          borderRadius: "10px",
        }}
      >
        <HeaderText
          style={{ fontFamily: "Outfit", fontWeight: "600", fontSize: "16px" }}
        >
          Apply for Test
        </HeaderText>
        <p>
          <HeaderText
            style={{
              fontFamily: "Outfit",
              fontWeight: "400",
              fontSize: "16px",
            }}
          >
            {" "}
            Ensure that you are attempting the test using the correct email ID.
          </HeaderText>
          <HeaderText
            style={{
              fontFamily: "Outfit",
              fontWeight: "400",
              fontSize: "16px",
            }}
          >
            {" "}
            You must click Submit after you answer each question. If you need
          </HeaderText>
          <HeaderText
            style={{
              fontFamily: "Outfit",
              fontWeight: "400",
              fontSize: "16px",
            }}
          >
            {" "}
            assistance during the test, click the question mark (?) in the
          </HeaderText>
          <HeaderText
            style={{
              fontFamily: "Outfit",
              fontWeight: "400",
              fontSize: "16px",
            }}
          >
            {" "}
            lower-right corner of the page to raise a ticket.
          </HeaderText>
        </p>
        <Button
        onClick={()=>navigate("/Assesment")}
          variant="contained"
          style={{
            width: "200px",
            background: colors.primary,
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          Start Test
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          border: "1px solid grey",

          marginTop: 20,
          borderRadius: "8px",
        }}
      >
        {/* <Grid container>
          <Grid item xs={12}>
            <EmployeeCard
              profile
              item={{
                name: "John Doe",
                companyName: "ABC Inc.",
                position: "HR Manager",
              }}
            />
          </Grid>
        </Grid> */}
      </div>
      <HeaderText style={{ fontSize: 14, marginTop: "20px" }}>
        About Job
      </HeaderText>
      {jobDetails.responsibilities.map((item, index) => (
        <HeaderText
          key={index}
          style={{
            fontSize: 14,
            marginLeft: 20,
            color: "black",
            marginTop: "5px",
          }}
        >
          {index + 1 + " "}. {item}
        </HeaderText>
      ))}
      <HeaderText style={{ fontSize: 14, marginTop: "20px", color: "black" }}>
        Who can apply:
      </HeaderText>
      {jobDetails.qualifications.map((item, index) => (
        <HeaderText
          key={index}
          style={{
            fontSize: 14,
            color: "black",
            marginTop: "5px",
            marginLeft: 20,
          }}
        >
          {index + 1 + " "}. {item}
        </HeaderText>
      ))}{" "}
      <HeaderText style={{ fontSize: 14, marginTop: "20px", color: "black" }}>
        Additional Information:
      </HeaderText>
      {jobDetails.additionalInfo.map((item, index) => (
        <HeaderText
          key={index}
          style={{
            fontSize: 14,
            color: "black",
            marginTop: "5px",
            marginLeft: 20,
          }}
        >
          {index + 1 + " "}. {item}
        </HeaderText>
      ))}
      <div
        style={{
          display: "flex",
          border: "1px solid grey",
          padding: 10,
          marginTop: 20,
          borderRadius: "8px",
          flexDirection: "column",
        }}
      >
        <div style={{ display: "flex", width: "100%" }}>
          <img
            src="https://lh3.googleusercontent.com/wAPeTvxh_EwOisF8kMR2L2eOrIOzjfA5AjE28W5asyfGeH85glwrO6zyqL71dCC26R63chADTO7DLOjnqRoXXOAB8t2f4C3QnU6o0BA"
            style={{ height: "48px", width: "48px", objectFit: "contain" }}
          />
          <div style={{ marginLeft: "20px" }}>
            <HeaderText style={{ fontSize: 16 }}>Grameenphone Ltd.</HeaderText>
            <div style={{ display: "flex", alignItems: "center" }}>
              <HeaderText
                style={{ fontWeight: 400, color: "grey", fontSize: 14 }}
              >
                Telecommunications
              </HeaderText>
              <HeaderText
                style={{
                  fontWeight: 400,
                  color: "grey",
                  fontSize: 14,
                  marginLeft: 10,
                }}
              >
                6,424 employees
              </HeaderText>
              <HeaderText
                style={{ color: "#13D427", fontSize: 14, marginLeft: 10 }}
              >
                Actively Hiring
              </HeaderText>
            </div>
          </div>
          <HeaderText
          onClick={()=>navigate("/CompanyProfile")}
            style={{
              color: colors.primary,
              marginLeft: "auto",
              fontWeight: "600",
              fontSize: 14,
              cursor:"pointer"
            }}
          >
            View Page
          </HeaderText>
        </div>
        <div style={{ marginTop: 10 }}>
          <HeaderText
            style={{
              fontWeight: "500",
              color: "grey",
              fontSize: 14,
              width: "495px",
            }}
          >
            The right and contemporary use of technology is key to the progress
            of a nation. Keeping this in mind, Grameenphone always brings
            future-proof technology in order to facilitate your progress. The
            possibilities in this new world are immense and someone as bright as
            you should be the forerunner in leading the change...{" "}
          </HeaderText>
          <HeaderText
            style={{
              color: colors.primary,
              fontSize: 14,
              width: "495px",
            }}
          >
            see more
          </HeaderText>
        </div>
      </div>
    </CardWrapper>
  );
};

export default MyJobDetails;
