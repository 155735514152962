import React from "react";
import CardWrapper from "../Card/CardWrapper";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { colors } from "../../constants/colors";
import HeaderText from "../HeaderText";
const Back = ({ title }) => {
  const navigate = useNavigate();
  return (
    <CardWrapper style={{ marginBottom: 10, marginTop: 10, display: "flex" }}>
      <IconButton
        onClick={() => navigate(-1)}
        size="small"
        aria-label="show 4 new mails"
        style={{ background: colors.primary,color:"white" }}
      >
        <ArrowBackIcon color="white" />
      </IconButton>
      <HeaderText
        style={{ textAlign: "center", flex: 1, fontFamily: "Outfit" }}
      >
        {title}
      </HeaderText>
    </CardWrapper>
  );
};

export default Back;
