import React from "react";
import HeaderText from "../HeaderText";
import { colors } from "../../constants/colors";

const SwitchItems = ({ items, active, setActive, style, itemStyle }) => {
  return (
    <div
      style={{
        display: "flex",
        border: "1px solid grey",
        borderRadius: "8px",
        // overflowX: "scroll",
        flexWrap: "wrap",
        ...style,
      }}
    >
      {items.map((item, index) => (
        <div
          onClick={() => setActive(item)}
          style={{
            ...itemStyle,
            padding: "5px",
            borderRadius: "8px",
            height: "100%",
            paddingLeft: 10,
            width: "100px",
            alignItems: "center",
            justifyItems: "center",
            paddingRight: 10,
            background: active == item ? "#a5c6f0" : "#FAFAFA",
            margin: 3,
            cursor: "pointer",
          }}
        >
          <HeaderText
            style={{
              fontSize: "14px",
              fontFamily: "Outfit",
              textAlign: "center",
              //   fontWeight: "SemiBold",
              color: active == item ? colors.primary : "black",
            }}
          >
            {item}
          </HeaderText>
        </div>
      ))}
    </div>
  );
};

export default SwitchItems;
