import React from 'react'
import HeaderText from '../HeaderText'
import CardWrapper from '../Card/CardWrapper'
import { Grid, TextField } from '@mui/material'

const ProfileInfo = () => {
  return (
    <div style={{marginTop:20}}>
       
        <CardWrapper style={{marginTop:20}}>
        <HeaderText style={{textAlign:"center",margin:10}}>Profile Information</HeaderText>
            <Grid container spacing={1}>
                <Grid item xs={4}>  <TextField  label="Name" fullWidth/></Grid>
                <Grid item xs={4}>  <TextField  label="Email" fullWidth/></Grid>
                <Grid item xs={4}>  <TextField  label="Mobile" fullWidth/></Grid>
                <Grid item xs={4}>  <TextField  label="Gender" fullWidth/></Grid>
                <Grid item xs={4}>  <TextField  label="Age" fullWidth/></Grid>
      </Grid>
        </CardWrapper>
        
        </div>
  )
}

export default ProfileInfo