import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import ExploreIcon from '@mui/icons-material/Explore';
import IconButton from "@mui/material/IconButton";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import WorkIcon from "@mui/icons-material/Work";
import MenuIcon from "@mui/icons-material/Menu";
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import Toolbar from "@mui/material/Toolbar";
import Header from "../../components/Header/Header";
import UserProfile from "../UserProfile";
import Menu from "../Menu";

import WindowIcon from "@mui/icons-material/Window";
import { BookOnline } from "@mui/icons-material";
const drawerWidth = 240;

function StudentDashboard(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const menu = [
  
    {
      name: "Home",
      route: "/Timelines",
      icon: <ExploreIcon />,
    },  {
      name: "Dashboard",
      route: "/student",
      icon: <WindowIcon />,
    },
    {
      name: "Jobs",
      route: "/jobs",
      subMenu: [
        {
          name: "All Jobs",
          route: "/all_jobs",
        },
        {
          name: "Job For Me",
          route: "/job_for_me",
        },
        {
          name: "My Jobs",
          route: "/my_jobs",
        },
      ],
      icon: <WorkIcon />,
    },
   
    {
      name: "Courses",
      route: "/training",
      icon: <BookOnline />,
    }, {
      name: "Chat",
      route: "/chat",
      icon: <ChatBubbleIcon />,
    },{
      name: "Profile",
      route: "/profile",
      icon: <AccountCircleIcon />,
    },
  ];

  const drawer = (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src="https://yt3.googleusercontent.com/AAnXC4o1n8BKDsO5l6Uc71rf7WOJjm2-aUHzkvyp9vGYB5F4UtXWTecVzvPOBCFK0bNYsZlD7Hk=s900-c-k-c0x00ffffff-no-rj"
          style={{ height: 100 }}
        />
      </div>
      <UserProfile profile={props.user.image} name={props.user.name} />

      <Menu items={menu} />
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          background: "white",
          color: "black",
        }}
      >
        <Toolbar>
          <IconButton
            color="black"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Header profile={props.user.image} name={props.user.name}/>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          background: "#FAFAFA",
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <div style={{ paddingTop: "50px" }}>{props.children}</div>
      </Box>
    </Box>
  );
}

export default StudentDashboard;
