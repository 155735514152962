import React, { useState } from "react";
import { Grid } from "@mui/material";
import SampleJobCard from "../components/SampleJobCard";
import GraphCard from "../components/Card/GraphCard";
import AnylyticsCard from "../components/Card/AnylyticsCard";
import ProfileViewers from "../components/Card/ProfileViewers";
import { useNavigate } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import rawdata from "../components/RawData/jobs.json";
import { useEffect } from "react";
import { getStudentDashboard } from "../ApiCalls";
import WindowIcon from "@mui/icons-material/Window";
import BreadCumb from "../components/BradCumb";
const Home = () => {
  const navigate = useNavigate();
  const [jobListing, setJobListing] = useState([]);
  const [employees, setEmployee] = useState([]);
  const [dashboard, setDashboard] = useState();
  const getJobLisiting = () => {
    let result = getStudentDashboard();
    setDashboard(result);
    setJobListing(rawdata.jobs);
    setEmployee(rawdata.employee);
  };
  useEffect(() => {
    getJobLisiting();
  }, []);
  const navigationdata = [
    {
      icon: (
        <WindowIcon
          style={{ marginRight: 5, color: "0049FC", fontSize: 18 }}
        />
      ),
      name: "Dashboard",
      route: "/",
    },
    {
      name: "Student Dashboard",
      route: "?",
    },
  ];

  return (
    <Layout>
      <BreadCumb data={navigationdata} />
      {dashboard && (
        <Grid container spacing={[3, 3]} style={{ marginTop: 10 }}>
          <Grid item xs={12} sm={8}>
            <GraphCard
              graphData={dashboard.profileView.graph}
              title={"Profile View"}
            />

            <Grid container spacing={[3, 3]}>
              <Grid item xs={12} sm={6}>
                <AnylyticsCard
                  title={"Potential Jobs"}
                  subtitle={"(This Month)"}
                  value={dashboard.jobCardData.potential}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {" "}
                <AnylyticsCard
                  title={"Matched Jobs"}
                  subtitle={"(This Month)"}
                  value={dashboard.jobCardData.matched}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {" "}
                <AnylyticsCard
                  up
                  title={"Search Appearance"}
                  subtitle={"(This Month)"}
                  value={dashboard.jobCardData.searchApperance.count}
                  percentage={dashboard.jobCardData.searchApperance.percent}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {" "}
                <AnylyticsCard
                  title={"Applied Job"}
                  subtitle={"(This Month)"}
                  value={dashboard.jobCardData.applied}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                {" "}
                <ProfileViewers
                  onClick={() => navigate("/profile")}
                  title={"Profile Viewer"}
                  users={dashboard.profile_viewers}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <SampleJobCard
              onClick={() => {
                navigate("/ApplicationDetails");
              }}
              jobListing={dashboard.jobs.applied}
              title={"Applied Jobs"}
              button={"All Jobs"}
              showAll
            />
            <SampleJobCard
              jobListing={dashboard.jobs.suggestion}
              title={"Jobs for you"}
              button={"All Jobs"}
              onClick={(id) => {
                navigate("/jobDetails/" + id);
              }}
              showAll
            />
          </Grid>
        </Grid>
      )}
    </Layout>
  );
};

export default Home;
