import React from 'react'
import Layout from '../../components/Layout/Layout'
import CardWrapper from '../../components/Card/CardWrapper'
import { Button, Chip, Divider, LinearProgress } from '@mui/material'
import HeaderText from '../../components/HeaderText'
import Header from '../../components/Header/Header'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SampleJobCard from '../../components/SampleJobCard'
import CompanyDashboard from '../../Layout/CompanyDashboard'
const Company = () => {
    const [value, setValue] = React.useState('one');

    const handleChange = (event, newValue) => {
        console.log(newValue)
        setValue(newValue);
    };
    const jobListing2 = [
        {
            image: 'path/to/image1.jpg',
            jobTitle: 'Software Engineer',
            location: 'Bangalore, India',
            companyName: 'TechCo Inc.',
            ctc: '₹ 12 LPA to ₹ 15 LPA'
        },
        {
            image: 'path/to/image2.jpg',
            jobTitle: 'Data Scientist',
            location: 'Mumbai, India',
            companyName: 'Data Analytics Co.',
            ctc: '₹ 10 LPA to ₹ 14 LPA'
        },
        {
            image: 'path/to/image3.jpg',
            jobTitle: 'UX Designer',
            location: 'New Delhi, India',
            companyName: 'Design Innovators Ltd.',
            ctc: '₹ 8 LPA to ₹ 12 LPA'
        },
        {
            image: 'path/to/image4.jpg',
            jobTitle: 'Product Manager',
            location: 'Hyderabad, India',
            companyName: 'Tech Innovations Corp.',
            ctc: '₹ 14 LPA to ₹ 18 LPA'
        },
        {
            image: 'path/to/image5.jpg',
            jobTitle: 'Marketing Specialist',
            location: 'Chennai, India',
            companyName: 'Marketing Maven Inc.',
            ctc: '₹ 9 LPA to ₹ 11 LPA'
        },
        {
            image: 'path/to/image6.jpg',
            jobTitle: 'Financial Analyst',
            location: 'Kolkata, India',
            companyName: 'Financial Insights Ltd.',
            ctc: '₹ 11 LPA to ₹ 13 LPA'
        },
        {
            image: 'path/to/image7.jpg',
            jobTitle: 'Graphic Designer',
            location: 'Pune, India',
            companyName: 'Visual Creations Co.',
            ctc: '₹ 7 LPA to ₹ 10 LPA'
        },
        // Add more items here...
    ];

    return (
        <CompanyDashboard>
            <div style={{ marginTop: 20 }}>
                <CardWrapper>
                    <LinearProgress variant="determinate" value={30} />
                    <HeaderText style={{ marginTop: 10 }}>30% Profile Completed</HeaderText>
                    <HeaderText style={{ fontSize: 14, color: "grey" }}>Fill out missing information to increase your chances of attracting top talent!</HeaderText>
                </CardWrapper>
                <HeaderText style={{ marginTop: 20, fontSize: 16, color: "skyblue", marginBottom: 20 }}>{`Company > Startups > NextIn`}</HeaderText>
                <CardWrapper>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <img src='https://static.vecteezy.com/system/resources/previews/010/353/285/non_2x/colourful-google-logo-on-white-background-free-vector.jpg' style={{ height: 50, width: 50 }} />
                        <div><HeaderText>Google</HeaderText>
                            <HeaderText style={{ fontSize: 14, color: "grey" }}>Next in studio is game development studio based in Pune. we build games and softwares</HeaderText>
                        </div>
                        <Button
                            variant="contained"
                            sx={{
                                margin: "5px",
                                marginLeft: "auto"
                                , width: 80,
                                background: "black",
                                fontWeight: "bold",
                                fontFamily: "Quicksand",
                                textTransform: "none",
                            }}
                        >
                            Edit
                        </Button>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ flex: 1 }}>
                            <Box sx={{ width: '100%' }}>
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="wrapped label tabs example"
                                >
                                    <Tab
                                        value="one"
                                        label="Overview"

                                    />
                                   
                                    <Tab value="six" label="Jobs" />
                                </Tabs>
                            </Box>
                            <div>

                                {
                                    value == "one" && <div>
                                        <div style={{ display: "flex", flexDirection: "row", marginTop: 20, alignItems: "center" }}>
                                            <div>
                                                <HeaderText>Next in studio careers</HeaderText>
                                                <Chip style={{ margin: 10 }} label="Technology company"></Chip>
                                                <HeaderText>Next in studio is game development studio based in Pune. we build games and softwares</HeaderText>
                                                <HeaderText style={{ fontSize: 14, color: "grey" }}>Google LLC is an American multinational technology company focusing on artificial intelligence, online advertising, search engine technology, cloud computing, computer software, quantum computing, e-commerce, and consumer electronics. </HeaderText>
                                            </div>
                                            <img src='https://wellfound.com/cdn-cgi/image/width=732,height=457.5,fit=cover,gravity=0.5x0.5,quality=90,format=auto/https://d2gn4xht817m0g.cloudfront.net/p/media_uploads/images/original/000/681/831/681831-deebe49e71c8ff673d33a197a533891aa1c0676c.jpg' style={{ width: 200, height: 150, marginLeft: 20, }} />
                                        </div>
                                    </div>
                                }
                                {value == "six" &&
                                    <SampleJobCard owner jobListing={jobListing2} title={"Campus Jobs"} subtitle={"Hiring from your Collage"} />

                                }
                            </div></div>
                        <div style={{ marginLeft: 20 }}>
                            <CardWrapper>
                                <HeaderText style={{ fontSize: 13, color: "grey" }}>
                                    RECRUIT FOR NEXT IN STUDIO
                                </HeaderText>
                                <Divider style={{ marginTop: 10, marginBottom: 10 }}>

                                </Divider>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: 300 }}>
                                    <div>
                                        <HeaderText style={{ fontSize: 16 }}>Post a job</HeaderText> <HeaderText style={{ fontSize: 13, color: "grey" }}>
                                            Reach 2 million job-seekers
                                        </HeaderText></div>
                                    <ArrowForwardIosIcon style={{ marginLeft: "auto" }} />
                                    <div>


                                    </div>

                                </div>
                                <Divider style={{ marginTop: 10, marginBottom: 10 }}></Divider>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: 300 }}>
                                    <div>
                                        <HeaderText style={{ fontSize: 16 }}>Invite your team</HeaderText> <HeaderText style={{ fontSize: 13, color: "grey" }}>
                                            Unlimited seats, free of charge
                                        </HeaderText></div>
                                    <ArrowForwardIosIcon style={{ marginLeft: "auto" }} />
                                    <div>


                                    </div>

                                </div>
                                <Divider style={{ marginTop: 10, marginBottom: 10 }}></Divider>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: 300 }}>
                                    <div>
                                        <HeaderText style={{ fontSize: 16 }}>Connect your ATS</HeaderText> <HeaderText style={{ fontSize: 13, color: "grey" }}>
                                            All your hiring in one place
                                        </HeaderText></div>
                                    <ArrowForwardIosIcon style={{ marginLeft: "auto" }} />
                                    <div>


                                    </div>

                                </div>

                            </CardWrapper>
                            <div style={{ marginTop: 20 }}>
                                <CardWrapper >
                                    <HeaderText style={{ fontSize: 13, color: "grey" }}>
                                        ABOUT NEXT IN STUDIO
                                    </HeaderText>

                                    <HeaderText style={{ fontSize: 15, marginTop: 10 }}>
                                        Website
                                    </HeaderText>
                                    <HeaderText style={{ fontSize: 13, color: "blue" }}>
                                        nextinstudio.com
                                    </HeaderText>

                                    <HeaderText style={{ fontSize: 15, marginTop: 10 }}>
                                        Loation
                                    </HeaderText>
                                    <HeaderText style={{ fontSize: 13, color: "grey" }}>
                                        Pune
                                    </HeaderText>

                                    <HeaderText style={{ fontSize: 15, marginTop: 10 }}>
                                        Company size

                                    </HeaderText>
                                    <HeaderText style={{ fontSize: 13, color: "grey" }}>
                                        11-50 people

                                    </HeaderText>


                                    <HeaderText style={{ fontSize: 15, marginTop: 10 }}>
                                        Total raised

                                    </HeaderText>
                                    <HeaderText style={{ fontSize: 13, color: "grey" }}>
                                        $ 2B
                                    </HeaderText>


                                    <HeaderText style={{ fontSize: 15, marginTop: 10 }}>
                                        Company type

                                    </HeaderText>
                                    <HeaderText style={{ fontSize: 13, color: "grey" }}>
                                        Technology
                                    </HeaderText>



                                </CardWrapper></div>
                        </div>
                    </div>
                </CardWrapper>

            </div>
        </CompanyDashboard>
    )
}

export default Company