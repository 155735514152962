import React from "react";
import Layout from "../../components/Layout/Layout";
import { Avatar, Button, Grid } from "@mui/material";
import HeaderText from "../../components/HeaderText";
import CardWrapper from "../../components/Card/CardWrapper";
import Header from "../../components/Header/Header";
import { useNavigate } from "react-router-dom";
import CollegeDashboard from "../../Layout/CollegeDashboard";
import { colors } from "../../constants/colors";
import GraphCard from "../../components/Card/GraphCard";

const CollageViews = () => {
  const navigate = useNavigate();
  const collegePlacementTasks = [
    {
      task: "Coordinate college placement event",
      assignedTo: "Placement Coordinator",
      status: "InProgress",
    },
    {
      task: "Send invitations to companies",
      assignedTo: "Placement Coordinator",
      status: "Pending",
    },
    {
      task: "Prepare promotional materials",
      assignedTo: "Marketing Team",
      status: "Pending",
    },
    {
      task: "Arrange transportation for visiting companies",
      assignedTo: "Logistics Team",
      status: "Pending",
    },
    {
      task: "Set up event venue",
      assignedTo: "Operations Team",
      status: "Pending",
    },
    {
      task: "Coordinate with college staff for event logistics",
      assignedTo: "Placement Coordinator",
      status: "Pending",
    },
    {
      task: "Collect feedback from participating companies",
      assignedTo: "Placement Coordinator",
      status: "Pending",
    },
    // Add more tasks as needed
  ];

  return (
    <Layout>
      <GraphCard title={"Placed Students"} value={"99"} />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Grid container  spacing={[0,2]}>
          <Grid item xs={12} sm={6}>
            <CardWrapper style={{  flex: 1, marginRight: 5 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <HeaderText style={{ fontSize: 16 }}>Active Student</HeaderText>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ flex: 1, textAlign: "center", marginTop: 20 }}>
                  <HeaderText>20</HeaderText>
                  <HeaderText style={{ fontWeight: "100", fontSize: 14 }}>
                    Placed Students
                  </HeaderText>
                </div>
                <div style={{ flex: 1, textAlign: "center", marginTop: 20 }}>
                  <HeaderText>10</HeaderText>
                  <HeaderText style={{ fontWeight: "100", fontSize: 14 }}>
                    Students in Interview
                  </HeaderText>
                </div>
                <div style={{ flex: 1, textAlign: "center", marginTop: 20 }}>
                  <HeaderText>562</HeaderText>
                  <HeaderText style={{ fontWeight: "100", fontSize: 14 }}>
                    Total Students
                  </HeaderText>
                </div>
              </div>
            </CardWrapper>
          </Grid>

          <Grid item xs={12} sm={6}>
            <CardWrapper style={{  flex: 1, marginRight: 5 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <HeaderText style={{ fontSize: 16 }}>Companies</HeaderText>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ flex: 1, textAlign: "center", marginTop: 20 }}>
                  <HeaderText>11</HeaderText>
                  <HeaderText style={{ fontWeight: "100", fontSize: 14 }}>
                    Active Jobs
                  </HeaderText>
                </div>
                <div style={{ flex: 1, textAlign: "center", marginTop: 20 }}>
                  <HeaderText>10</HeaderText>
                  <HeaderText style={{ fontWeight: "100", fontSize: 14 }}>
                    pitches
                  </HeaderText>
                </div>
                <div style={{ flex: 1, textAlign: "center", marginTop: 20 }}>
                  <HeaderText>562</HeaderText>
                  <HeaderText style={{ fontWeight: "100", fontSize: 14 }}>
                    matches
                  </HeaderText>
                </div>
              </div>
            </CardWrapper>
          </Grid>
        </Grid>
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Grid container spacing={[1,2]}>
          <Grid item xs={12} sm={4}>
            <CardWrapper style={{  marginTop: 20,flex: 1, marginRight: 5 }}>
              <img
                src="https://img.freepik.com/premium-vector/digital-security-access-illustration-concept_644411-40.jpg"
                style={{ height: 300, width: "100%", objectFit: "cover" }}
              />
              <HeaderText style={{ fontSize: 16 }}>College Students</HeaderText>
              <HeaderText style={{ fontWeight: "100", fontSize: 14 }}>
                Unique filters help cut through the noise by looking for the
                signals that truly matter to your Students.
              </HeaderText>
              <Button
                onClick={() => navigate("/StudentView")}
                fullWidth
                variant="contained"
                sx={{
                  margin: "5px",
                  marginLeft: "auto",
                  marginTop: "10px",
                  background: colors.primary,
                  borderRadius: 20,
                  fontFamily: "Outfit",
                  textTransform: "none",
                }}
              >
                View Students
              </Button>
            </CardWrapper>
          </Grid>{" "}
          <Grid item xs={12} sm={4}>
            <CardWrapper style={{  marginTop: 20,flex: 1, marginRight: 5 }}>
              <img
                src="https://img.freepik.com/premium-vector/blockchain-supply-chain-illustration-concept_644411-25.jpg?w=2000"
                style={{ height: 300, width: "100%", objectFit: "cover" }}
              />
              <HeaderText style={{ fontSize: 16 }}>
                Fast-track your workflow with course templates
              </HeaderText>
              <HeaderText style={{ fontWeight: "100", fontSize: 14 }}>
                Set up, save and reuse your course templates to reduce work and
                increase Student outreach.
              </HeaderText>
              <Button
                fullWidth
                variant="contained"
                sx={{
                  margin: "5px",
                  marginLeft: "auto",
                  marginTop: "10px",
                  background: colors.primary,
                  borderRadius: 20,
                  fontFamily: "Quicksand",
                  textTransform: "none",
                }}
              >
                Upload Courses
              </Button>
            </CardWrapper>
          </Grid>{" "}
          <Grid item xs={12} sm={4}>
            <CardWrapper style={{ marginTop: 20, flex: 1, marginRight: 5 }}>
              <img
                src="https://img.freepik.com/premium-vector/business-data-analysis_118813-8879.jpg"
                style={{ height: 300, width: "100%", objectFit: "cover" }}
              />
              <HeaderText style={{ fontSize: 16 }}>
                Collaborate with your team more efficiently
              </HeaderText>
              <HeaderText style={{ fontWeight: "100", fontSize: 14 }}>
                Bookmark candidates, leave notes, and share with your team to
                speed up your hiring process.
              </HeaderText>
              <Button
                fullWidth
                variant="contained"
                sx={{
                  margin: "5px",
                  marginLeft: "auto",
                  marginTop: "10px",
                  background: colors.primary,
                  borderRadius: 20,
                  fontFamily: "Quicksand",
                  textTransform: "none",
                }}
              >
                Invite Students
              </Button>
            </CardWrapper>
          </Grid>
        </Grid>
      </div>
      <div style={{ marginTop: 20 }}>
        <HeaderText>Activity Feed</HeaderText>
        <HeaderText
          style={{ fontSize: 12, color: "grey", marginBottom: "20px" }}
        >
          Here's a reminder of what you and your team have been up to recently
        </HeaderText>
      </div>
      {collegePlacementTasks.map((item) => (
        <CardWrapper
        className="maincontainer"
          style={{
            marginBottom: "10px",
           
            justifyContent: "space-between",
          }}
        >
          <HeaderText style={{ fontSize: 12, color: "black", flex: 1 }}>
            {item.task}
          </HeaderText>
          <HeaderText style={{ fontSize: 12, color: "black", flex: 1 }}>
            {item.assignedTo}
          </HeaderText>
          <HeaderText
            style={{
              fontSize: 12,
              flex: 1,
              color:
                item.status == "Pending"
                  ? "red"
                  : item.status == "Completed"
                  ? "green"
                  : "black",
            }}
          >
            {item.status}
          </HeaderText>
        </CardWrapper>
      ))}
    </Layout>
  );
};

export default CollageViews;
