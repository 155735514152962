import React from "react";
import CardWrapper from "../CardWrapper";
import { Avatar, Button, Grid } from "@mui/material";
import HeaderText from "../../HeaderText";
import { useNavigate, useNavigation } from "react-router-dom";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";
import { colors } from "../../../constants/colors";
const EmployeeCard = ({ item, profile,flex, chat, onClick }) => {
  
  return (
    <div
      onClick={onClick}
      style={{ padding: 10, cursor: "pointer" }}
      className="employee"
    >
      <div
         className={flex?"":"maincontainer"}
        style={{ alignItems: "center", width: "100%", display: "flex" }}
      >
        <div>
          <img
            alt="Remy Sharp"
            src={
              item.image ||
              "https://img.freepik.com/premium-vector/businessman-avatar-illustration-cartoon-user-portrait-user-profile-icon_118339-4386.jpg?w=2000"
            }
            style={{
              width: "48px",
              height: "48px",

              objectFit: "cover",
              borderRadius: 100,
            }}
          />
        </div>
        <div  className="maincontainer" style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ width: "100%", marginLeft: 10 }}>
            <HeaderText style={{ fontSize: 16, fontFamily: "Outfit" }}>
              {item.name}
            </HeaderText>
            <div  className="maincontainer" style={{ display: "flex" }}>
              <HeaderText
                style={{
                  fontSize: 12,
                  color: "black",
                  fontFamily: "Outfit",
                  fontWeight: "500",
                }}
              >
                {item.position}
              </HeaderText>
              <HeaderText
                style={{
                  fontSize: 12,
                  marginLeft: 10,
                  color: "grey",
                  fontFamily: "Outfit",
                  fontWeight: "500",
                }}
              >
                {item.companyName || "Delhi India"}
              </HeaderText>
            </div>

            {!profile && !chat && (
              <HeaderText
                style={{
                  fontSize: 14,
                  fontFamily: "Outfit",
                  color: "#0049FC",
                  cursor: "pointer",
                }}
              >
                View Profile
              </HeaderText>
            )}
          </div>
        </div>
        {item.unread && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              background: colors.primary,
              color: "white",
              padding: 3,
              borderRadius: 20,
              paddingLeft: 5,
              paddingRight: 5,
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "auto",
            }}
          >
            <HeaderText style={{ fontSize: 13, marginLeft: "auto" }}>
              {item.unread}
            </HeaderText>
          </div>
        )}
        {profile && (
          <HeaderText
            style={{
              fontSize: 14,
              fontFamily: "Outfit",
              color: "#0049FC",
              marginLeft: "auto",
              cursor: "pointer",
            }}
          >
            Send Message
          </HeaderText>
        )}
        {/* <Grid item xs={4}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Button
            onClick={()=>navigation("JobDetails")}
              variant="contained"
              sx={{
                margin: "5px",
                marginLeft: "auto",
                width: 80,
                background: "black",
                fontWeight: "bold",
                fontFamily: "Quicksand",
                textTransform: "none",
              }}
            >
              {owner?"Edit":"Apply"}
            </Button>
            <Button
              variant="outlined"
              sx={{
                margin: "5px",
                marginLeft: "auto",
                width: 80,
                border: "1px solid black",
                fontWeight: "bold",
                fontFamily: "Quicksand",
                color: "black",
                textTransform: "none",
              }}
            >
            {owner?"Delete":"Save"}
            </Button>
          </div>
        </Grid> */}
      </div>
    </div>
  );
};

export default EmployeeCard;
