import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";
import { Button, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import HeaderText from "../../components/HeaderText";
import { colors } from "../../constants/colors";
import CardWrapper from "../../components/Card/CardWrapper";

const AddStudent = () => {
  const [age, setAge] = useState(0);
  let engineeringDivisions = [
    "Civil ",
    "Mechanical ",
    "Electrical ",
    "Chemical ",
    "Computer ",
    "Aerospace ",
    "Biomedical ",
    "Environmental ",
    "Industrial ",
    "Materials ",
    "Petroleum ",
  ];
  return (
    <Layout>
      <div style={{ display: "flex", width: "100%" }}>
        <CardWrapper
          style={{
            marginTop: "3%",
            padding: "20px",
            maxWidth: "500px",
            margin: "auto",
            marginBottom:"3%"
          }}
        >
          <HeaderText
            style={{
              textAlign: "center",
              marginBottom: "20px",
              fontFamily: "Outfit",
            }}
          >
            Add Student
          </HeaderText>
          <div>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Student Name"
              variant="outlined"
            />

           
            
            <InputLabel sx={{ marginTop: "20px" }} id="posting-type-label">
              Course Branch
            </InputLabel>
            <Select
              labelId="posting-type-label"
              value={age}
              displayEmpty
              placeholder="Posting Type"
              fullWidth
              onChange={(e) => setAge(e.target.value)}
            >
              <MenuItem disabled value={30}>
                Course Branch
              </MenuItem>
              {engineeringDivisions.map((item) => (
                <MenuItem value={10}>{item}</MenuItem>
              ))}
            </Select>
            <InputLabel sx={{ marginTop: "20px" }} id="posting-collage-label">
              Current Year
            </InputLabel>
            <Select
              value={age}
              fullWidth
              labelId="posting-collage-label"
              onChange={() => setAge()}
            >
              <MenuItem disabled value={30}>
                Select Current Year
              </MenuItem>
              <MenuItem value={10}>1st Year</MenuItem>
              <MenuItem value={20}>2nd Year</MenuItem>
              <MenuItem value={20}>3rd Year</MenuItem>
              <MenuItem value={20}>Final Year</MenuItem>
            </Select>
          </div>

          <Button
            fullWidth
            variant="contained"
            sx={{
              margin: "5px",
              marginLeft: "auto",
              marginTop: "10px",
              background: colors.primary,
              borderRadius: "100px",
              fontFamily: "Outfit",
              textTransform: "none",
            }}
          >
            Post
          </Button>
          <HeaderText
            style={{
              textAlign: "center",
              marginBottom: "20px",
              marginTop:"20px",
              fontFamily: "Outfit",
            }}
          >
            Add multiple Student
          </HeaderText>
          <InputLabel htmlFor="file-input" style={{ marginTop: 20 }}>
              Student Excel:
            </InputLabel>
            <TextField
              type="file"
              fullWidth
              id="file-input"
              variant="outlined"
            />  <Button
            fullWidth
            variant="contained"
            sx={{
              margin: "5px",
              marginLeft: "auto",
              marginTop: "10px",
              background: colors.primary,
              borderRadius: "100px",
              fontFamily: "Outfit",
              textTransform: "none",
            }}
          >
            Add
          </Button>
        </CardWrapper>
      </div>
    </Layout>
  );
};

export default AddStudent;
