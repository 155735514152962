import React, { useEffect, useState } from "react";
import StudentDashboard from "../../Layout/StudentDashboard";
import Layout from "../../components/Layout/Layout";
import GraphCard from "../../components/Card/GraphCard";
import SampleJobCard from "../../components/SampleJobCard";
import { useNavigate } from "react-router-dom";
import CardWrapper from "../../components/Card/CardWrapper";
import { Avatar, Button } from "@mui/material";
import HeaderText from "../../components/HeaderText";
import { colors } from "../../constants/colors";
import rawData from "../../components/RawData/jobs.json";
import JobCard from "../../components/Card/JobCard";
import { getJobLisiting } from "../../ApiCalls";

import WorkIcon from "@mui/icons-material/Work";
import BreadCumb from "../../components/BradCumb";
const InactiveListing = () => {
  const navigate = useNavigate();
  const [incativeLising,setInactiveListing]=useState([]);
  useEffect(() => {
    let result = getJobLisiting();
    setInactiveListing(result.inactive);
  }, []);  
  const navigationdata = [
    {
      icon: (
        <WorkIcon style={{ marginRight: 5, color: "0049FC", fontSize: 18 }} />
      ),
      name: "Jobs Lisiting",
      route: "/",
    },
    {
      name: "Incctive Listing",
      route: "/all_jobs",
    },
  ];
  return (
    <Layout>
      <div  style={{marginBottom:20}}>
      <BreadCumb data={navigationdata} style={{marginBottom:20}}/>
      </div>
      {incativeLising?.map((item) => (
        <div
          style={{
            padding: 3,
            border: "0.5px solid lightgrey",
            marginBottom: 10,
            borderRadius: "10px",
          }}
        >
          <JobCard item={item} onClick={() => {}} />
          <HeaderText style={{fontFamily:"Outfit",fontSize:"14px",textAlign:"center",margin:"5px"}}>Hired employee ({item?.students?.length||0})</HeaderText>
          {item?.students?.map((item) => (
            <CardWrapper
              style={{
                marginBottom: 10,
                padding: 10,
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Avatar src={item.image}>{item.name.split("")[0]}</Avatar>
              <div>
                <HeaderText
                  style={{
                    marginLeft: 20,
                    fontSize: "16px",
                    display: "flex",
                    fontFamily: "Outfit",
                  }}
                >
                  {item.name}
                </HeaderText>
                <HeaderText style={{ marginLeft: 20, fontSize: 10 }}>
                  45% score in Assement
                </HeaderText>
              </div>
              <Button
                onClick={() => navigate("/Resume")}
                variant="contained"
                sx={{
                  margin: "5px",
                  marginLeft: "auto",
                  marginTop: "10px",
                  background: colors.primary,
                  // fontWeight: "bold",
                  fontFamily: "Outfit",
                  borderRadius: "100px",
                  textTransform: "none",
                }}
              >
                Letter
              </Button>
            </CardWrapper>
          ))}
        </div>
      ))}
    </Layout>
  );
};

export default InactiveListing;
