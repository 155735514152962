import React from 'react'
import HeaderText from '../../HeaderText'
import CardWrapper from '../CardWrapper'
import { Divider } from '@mui/material'

const KeywordsCard = ({ keywords }) => {

    return (
        <CardWrapper>
            <HeaderText>Keywords</HeaderText>
            {
                keywords.map(item => <div><HeaderText style={{ fontSize: 14, color: "skyblue",cursor:"pointer", paddingBottom: 5,marginTop:5}}>{item}</HeaderText>
                    <Divider /></div>)
            }
        </CardWrapper>
    )
}

export default KeywordsCard