import React from 'react'
import Layout from '../components/Layout/Layout'
import SampleJobCard from '../components/SampleJobCard';
import { Grid } from '@mui/material';
import FilterCard from '../components/Card/FilterCard';
import KeywordsCard from '../components/Card/KeywordsCard';


const Explore = () => {
    

  const jobListing2= [
    {
      image: 'path/to/image1.jpg',
      jobTitle: 'Software Engineer',
      location: 'Bangalore, India',
      companyName: 'TechCo Inc.',
      ctc: '₹ 12 LPA to ₹ 15 LPA'
    },
    {
      image: 'path/to/image2.jpg',
      jobTitle: 'Data Scientist',
      location: 'Mumbai, India',
      companyName: 'Data Analytics Co.',
      ctc: '₹ 10 LPA to ₹ 14 LPA'
    },
    {
      image: 'path/to/image3.jpg',
      jobTitle: 'UX Designer',
      location: 'New Delhi, India',
      companyName: 'Design Innovators Ltd.',
      ctc: '₹ 8 LPA to ₹ 12 LPA'
    },
    {
      image: 'path/to/image4.jpg',
      jobTitle: 'Product Manager',
      location: 'Hyderabad, India',
      companyName: 'Tech Innovations Corp.',
      ctc: '₹ 14 LPA to ₹ 18 LPA'
    },
    {
      image: 'path/to/image5.jpg',
      jobTitle: 'Marketing Specialist',
      location: 'Chennai, India',
      companyName: 'Marketing Maven Inc.',
      ctc: '₹ 9 LPA to ₹ 11 LPA'
    },
    {
      image: 'path/to/image6.jpg',
      jobTitle: 'Financial Analyst',
      location: 'Kolkata, India',
      companyName: 'Financial Insights Ltd.',
      ctc: '₹ 11 LPA to ₹ 13 LPA'
    },
    {
      image: 'path/to/image7.jpg',
      jobTitle: 'Graphic Designer',
      location: 'Pune, India',
      companyName: 'Visual Creations Co.',
      ctc: '₹ 7 LPA to ₹ 10 LPA'
    },
    // Add more items here...
  ];
  const jobKeywords = [
    "JavaScript Developer",
    "Front-end Developer",
    "Full Stack Developer",
    "Web Developer",
    "UI Developer",
    "UX Developer",
    "Software Engineer",
    "Frontend Engineer",
    "Backend Engineer",
    "Node.js Developer",
    "React Developer",
    "Angular Developer",
    "Vue.js Developer",
    "Ember.js Developer",
    "TypeScript Developer",
    
    // Add more keywords related to your skills and preferences
  ];
  
    return (
        <Layout>
            <div style={{marginTop:20}}>
             <Grid container spacing={[3, 3]}>
        <Grid item xs={4}>
            <FilterCard/>
        </Grid>
    
        <Grid item xs={6}>
            <SampleJobCard jobListing={jobListing2} showAll title={"Jobs"} subtitle={"2265 work from home internships"}/>
      </Grid>    
       <Grid item xs={2}><KeywordsCard keywords={jobKeywords}/></Grid>   </Grid>
       </div>
        </Layout>
    )
}

export default Explore