import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderText from "../../components/HeaderText";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useEffect } from "react";
const SubMenu = ({ item, index, route }) => {
  const [active, setActive] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location.pathname.includes(item.route)) setActive(true);
  }, [location]);

  return (
    <div>
      <div
        onClick={() =>{
          if(item.onClick){
            item.onClick()
          }
          else
          item.subMenu ? setActive(!active) : navigate(route + item.route)}
        }
        key={index}
        style={{
          display: "flex",
          padding: 10,
          color: location.pathname.includes(item.route) ? "#0049FC" : "black",
          // marginBottom: 10,
          borderRadius: 10,
          height: 50,
          alignItems: "center",
          background: location.pathname == item.route ? "#F4F5FF" : "white",
          cursor: "pointer",
        }}
      >
        {item.icon}{" "}
        <HeaderText style={{ fontSize: 16, marginLeft: 10 }}>
          {item.name}
        </HeaderText>
        {item.subMenu && (
          <div style={{ marginLeft: "auto" }}>
            {active ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </div>
        )}
      </div>
      <div style={{ marginLeft: 20 }}>
        {active &&
          item.subMenu?.map((value, index) => (
            <SubMenu item={value} index={index} route={item.route} />
          ))}
      </div>
    </div>
  );
};

export default SubMenu;
